import usePostData from '../../../../../hooks/postHook';
import { parcelKeys } from '../../../../admin/features_public/cases/controllers/variables';

export const useGetParcelsImages = () => {
  const [{ data, isLoading, isError, error }, getParcelsImages] =
    usePostData('parcels/images');

  const doGetParcelsImage = ({ parcels }) => {
    const parcel_ids = parcels.map(parcel => parcel[parcelKeys.id]);
    const body = {
      parcel_ids,
      only_thumbs: true,
      limit: 1,
    };
    getParcelsImages(body);
  };

  return {
    data,
    isError,
    error,
    isLoading,
    getParcelsImages: doGetParcelsImage,
  };
};
