import { PropKeys } from '../../../../../constants';

export const parcelKeys = {
  activity: 'activity',
  authority: 'authority',
  authorityId: 'authorityId',
  authorityEmail: 'authorityEmail',
  authorityName: 'authorityName',
  authorityPhone: 'authorityPhone',
  authorityFax: 'authorityFax',
  actors: 'actors',
  actorType: 'actorType',
  actorName: 'actorName',
  actorCompany: 'actorCompany',
  actorAddress: 'actorAddress',
  actorPostcode: 'actorPostcode',
  actorCountry: 'actorCountry',
  actorPhone: 'actorPhone',
  alias: 'alias',
  createdDate: 'createdDate',
  city: 'city',
  expansionArrow: 'expansionArrow',
  id: 'id',
  image: 'image',
  itemQuantity: 'itemQuantity',
  lastActivity: 'lastActivity',
  leftPadding: 'leftPadding',
  optionsMenu: 'optionsMenu',
  ownQuantity: 'ownQuantity',
  photos: 'photos',
  productQuantity: 'productQuantity',
  products: 'products',
  sideloadDate: 'sideloadDate',
  sorter: 'sorter',
  sorterId: PropKeys.sorterId,
  statusShares: 'statusShares',
  totalQuantity: 'totalQuantity',
};
