import { Field_Keys, PropKeys } from '../../constants';
import { API } from '../../lib/api';

export const request2FAcode = async ({
  email,
  password,
  authType: auth_type,
  logged_in_token = null,
}) => {
  const body = {
    email,
    password,
    auth_type,
    is_logged_in: logged_in_token != null,
  };
  const { data, status, error } = await API.POST(
    'request_2fa',
    body,
    logged_in_token != null
      ? {
          headers: {
            Authorization: logged_in_token,
          },
        }
      : {}
  );

  if (error || status !== 200) return false;
  const {
    [Field_Keys.USER.ID]: user_id,
    [Field_Keys.USER.AUTH_CODE]: auth_code,
  } = data;

  return { [PropKeys.userId]: user_id, [PropKeys.authCode]: auth_code };
};
