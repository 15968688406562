import { isEmpty } from 'lodash';
import { Colors } from '../../../../../components/style';
import { Constants, Field_Keys } from '../../../../../constants';
import { magnitudeFormatter } from '../../../../../utils/stringUtils';
import { humanReadableDate } from '../../../../../utils/timeUtils';
import { getBargraphKeys, maskEntry } from '../../../../../utils/utils';
import { NavURIs, Pages } from '../../../routes/variables';
import {
  actorStatCountsKeys,
  actorStats,
  actorStatsDestination,
  actorTitles,
  actorTooltipContent,
  authenticationDestination,
  authenticationStats,
  authenticationTitles,
  authenticationTooltipContent,
  dashboardKeys,
  parcelStatuses,
  statusTooltipContent,
} from '../variables';
import { formatOverviewCard } from './formatOverview';

export const formatProducts =
  ({ hasPermission }) =>
  data => {
    data = maskEntry(data, mask);

    if (!hasPermission) {
      return noPermissionsData;
    }

    const parcelStatuses = getParcelStatuses(data);
    const productOverview = formatOverviewCard({
      type: dashboardKeys.productCount,
      hasPermission,
    })(data);
    const itemOverview = formatOverviewCard({
      type: dashboardKeys.itemCount,
      hasPermission,
    })(data);
    const authenticationStats = getAuthenticationStats(data);
    const actorsStats = formatActorsStats(data);
    const topProducts = formatTopProducts({ hasPermission })(data);
    const topItems = formatTopItems({ hasPermission })(data);
    const brandOverview = {
      ...formatOverviewCard({
        type: dashboardKeys.brandCount,
        hasPermission,
      })(data),

      ...formatBrandsExtraInfo(data),
    };
    return {
      parcelStatuses,
      productOverview,
      itemOverview,
      authenticationStats,
      actorsStats,
      topProducts,
      topItems,
      brandOverview,
    };
  };

export const getAuthenticationStats = data => {
  const counts = data[dashboardKeys.allAuthentications];

  const { from_date, to_date, comparison } =
    data?.avg_authentication_time_comparison ?? {};

  const delta = comparison?.avg_time?.percentage;

  const isMore = delta > 0;
  const dataType = 'authentications';

  const period = `${Math.abs(delta)}% ${
    isMore ? 'more' : 'fewer'
  } ${dataType} compared to ${humanReadableDate(
    from_date
  )} through \n${humanReadableDate(to_date)}`;

  const { hours, minutes } = data?.average_authentication_time ?? {};

  const averageAuthenticationTime = `${hours ? hours + 'h' : ''} ${
    minutes ? minutes + 'm' : ''
  }`;

  const deltaMesage = `Average authentication time: ${averageAuthenticationTime}`;

  return {
    status: [Constants.Status.Authentication],
    navigateParams: `${NavURIs[Pages.products]}?status=${[
      Constants.Status.Authentication,
      Constants.Status['Non-counterfeit'],
      Constants.Status.Counterfeit,
    ].join('+')}`,
    details: authenticationStats.map(stat => ({
      title: authenticationTitles[stat],
      tooltipContent: authenticationTooltipContent[stat],
      status: stat,
      count: magnitudeFormatter(counts?.[stat]) ?? Constants.fallback,
      navigateParams: authenticationDestination[stat],
    })),
    messageAndDelta: {
      message: deltaMesage,
      delta: {
        delta,
        period,
        positiveIsGood: false,
      },
    },
  };
};

const formatActorsStats = data => {
  const counts = data.actor_counts;

  const actorStats_ = {
    status: [Constants.Status.Stopped, Constants.Status.Seized],
    navigateParams: `${NavURIs[Pages.products]}?status=${
      Constants.Status.Stopped
    }+${Constants.Status.Seized}`,

    details: actorStats.map(stat => {
      const count = magnitudeFormatter(counts?.[stat]) ?? Constants.fallback;
      const _parcelCount = counts?.[actorStatCountsKeys.parcel[stat]];
      const _itemCount = counts?.[actorStatCountsKeys.item[stat]];
      const parcelCount = `${
        _parcelCount != null ? _parcelCount + ' parcels' : ''
      } `;
      const itemCount = `${_itemCount != null ? _itemCount + ' items' : ''} `;

      return {
        title: actorTitles[stat],
        tooltipContent: actorTooltipContent[stat],
        status: stat,
        count,
        navigateParams: actorStatsDestination[stat],
        itemSubdata: {
          parcelCount,
          itemCount,
        },
      };
    }),
  };

  return actorStats_;
};

export const getParcelStatuses = data => {
  return parcelStatuses.map(({ status, color }) => {
    const count =
      data[dashboardKeys.productCount]?.[status] ?? Constants.fallback;

    const itemCount__ = data[dashboardKeys.itemCount]?.[status];

    const itemCount = `${
      magnitudeFormatter(itemCount__) ?? Constants.fallback
    } item${itemCount__ === 1 ? '' : 's'} `;

    const parcelCount__ = data[dashboardKeys.parcelCount]?.[status];
    const parcelCount = `${
      magnitudeFormatter(parcelCount__) ?? Constants.fallback
    } parcel${parcelCount__ === 1 ? '' : 's'} `;

    const tooltipContent = statusTooltipContent[status];

    return {
      count,
      color,
      status,
      itemCount,
      parcelCount,
      tooltipContent,
    };
  });
};

const productBarchartColors = [
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
  Colors.Primary._300,
];

const itemBarcharColors = [
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
  Colors.Primary._600,
];

const disabledColors = [
  Colors.Neutral._900,
  Colors.Neutral._800,
  Colors.Neutral._700,
  Colors.Neutral._600,
  Colors.Neutral._500,
  Colors.Neutral._400,
  Colors.Neutral._300,
  Colors.Neutral._200,
  Colors.Neutral._100,
  Colors.Neutral._050,
];

export const formatTopProducts =
  ({ hasPermission }) =>
  ({ top_brands_by_product = [] }) => {
    try {
      const data = top_brands_by_product
        .reduce((arr, brand, index) => {
          const { products, items } = brand;

          const displayTitle = brand[Field_Keys.PRODUCT.BRAND_ALIAS];
          if (!displayTitle || products == null) return arr;
          return [
            ...arr,
            {
              name: displayTitle,
              customLabel: brand[Field_Keys.PRODUCT.BRAND_NAME],
              products,
              link: `${NavURIs[Pages.products]}?brand=${
                brand[Field_Keys.PRODUCT.BRAND_ID]
              }`,
            },
          ];
        }, [])
        .slice(0, 10);

      data.sort((a, b) => b.products - a.products);

      const colors = hasPermission ? productBarchartColors : disabledColors;

      data.forEach((item, index) => {
        item.tooltipColor = colors[index];
      });

      const title = `Top ${data.length} brands - Products`;
      const description =
        'This section shows top brands ranked by amount of products';

      const { keys, bars } = getBargraphKeys(data[0], [], ['link'], {
        barSize: 24,
      });

      return {
        data,
        bars,
        title,
        description,
        colors,
      };
    } catch (e) {
      return { data: [], keys: [], bars: [] };
    }
  };

export const formatTopItems =
  ({ hasPermission }) =>
  ({ top_brands_by_product = [] }) => {
    try {
      const data = top_brands_by_product
        .reduce((arr, brand, index) => {
          const { products, items: Items } = brand;

          const displayTitle = brand[Field_Keys.PRODUCT.BRAND_ALIAS];
          if (!displayTitle || Items == null) return arr;
          return [
            ...arr,
            {
              name: displayTitle,
              customLabel: brand[Field_Keys.PRODUCT.BRAND_NAME],
              Items,
              link: `${NavURIs[Pages.products]}?brand=${
                brand[Field_Keys.PRODUCT.BRAND_ID]
              }`,
            },
          ];
        }, [])
        .slice(0, 10);

      data.sort((a, b) => b.Items - a.Items);

      const colors = hasPermission ? itemBarcharColors : disabledColors;

      data.forEach((item, index) => {
        item.tooltipColor = colors[index];
      });

      const title = `Top ${data.length} brands - Items`;
      const description =
        'This section shows top brands ranked by amount of items';

      const { keys, bars } = getBargraphKeys(data[0], [], ['link'], {
        barSize: 24,
      });

      return {
        data,
        bars,
        title,
        description,
        colors,
      };
    } catch (e) {
      return { data: [], keys: [], bars: [] };
    }
  };

const formatBrandsExtraInfo = data => {
  if (data == null || isEmpty(data)) return { brandDetails: {} };
  const { new_brands, new_brands_count, new_brands_comparison } = data;

  const { from_date, to_date, comparison } = new_brands_comparison ?? {};
  const delta = comparison?.count?.percentage;

  const isMore = delta > 0;
  const dataType = 'new brands';

  const period = `${Math.abs(delta)}% ${
    isMore ? 'more' : 'fewer'
  } ${dataType} compared to ${humanReadableDate(
    from_date
  )} through \n${humanReadableDate(to_date)}`;

  const response = {
    newBrandCount: new_brands_count?.count,
    brandListString: new_brands,
    period,
    delta,
  };

  return { brandDetails: response };
};

// THIS should go in variables.js, but we get an initialization error for some reason
//
//
const parcelStausesFakeNumbers = {
  [dashboardKeys.productCount]: {},
  [dashboardKeys.itemCount]: {},
  [dashboardKeys.parcelCount]: {},
};
parcelStatuses.forEach(({ status }) => {
  parcelStausesFakeNumbers[dashboardKeys.productCount][status] =
    null && Math.floor(Math.random() * 100);
  parcelStausesFakeNumbers[dashboardKeys.itemCount][status] =
    null && Math.floor(Math.random() * 100);
  parcelStausesFakeNumbers[dashboardKeys.parcelCount][status] =
    null && Math.floor(Math.random() * 100);
});

const fakeTopProductsNumbers = Array(10)
  .fill(0)
  .map((_, i) => ({
    [Field_Keys.BRAND.NAME]: 'Brand ' + i,
    products: Math.floor(Math.random() * 100),
  }));
const fakeTopItemsNumbers = Array(10)
  .fill(0)
  .map((_, i) => ({
    [Field_Keys.BRAND.NAME]: 'Brand ' + i,
    items: Math.floor(Math.random() * 100),
  }));

const mockParcelStatuses = getParcelStatuses(parcelStausesFakeNumbers);

const mockProductOverview = formatOverviewCard({
  type: dashboardKeys.productCount,
  hasPermission: false,
})({});

const mockItemOverview = formatOverviewCard({
  type: dashboardKeys.itemCount,
  hasPermission: false,
})({});

const mockAuthenticationStats = getAuthenticationStats({
  [dashboardKeys.allAuthentications]: {
    Authentication_Completed: null && Math.floor(Math.random() * 100),
    Authentication: null && Math.floor(Math.random() * 100),
    Authentication: null && Math.floor(Math.random() * 100),
  },
});

const mockActorsStats = formatActorsStats({
  actor_counts: {
    complete_item_count: null && 10,
    complete_parcel_count: null && 34,
    noDestinationCount: null && 31,
    noOriginCount: null && 3,
    no_destination_item_count: null && 63,
    no_destination_parcel_count: null && 12,
    no_origin_item_count: null && 63,
    no_origin_parcel_count: null && 51,
    referenceDetailsCompleteCount: null && 72,
  },
});

const mockTopProducts = formatTopProducts({ hasPermission: false })({
  top_brands_by_product: fakeTopProductsNumbers,
});
const mockTopItems = formatTopItems({ hasPermission: false })({
  top_brands_by_product: fakeTopItemsNumbers,
});

const mockBrandOverview = {
  ...formatOverviewCard({
    type: dashboardKeys.brandCount,
    hasPermission: false,
  })({}),
  ...formatBrandsExtraInfo({}),
};

export const noPermissionsData = {
  parcelStatuses: mockParcelStatuses,
  productOverview: mockProductOverview,
  itemOverview: mockItemOverview,
  authenticationStats: mockAuthenticationStats,
  actorsStats: mockActorsStats,
  topProducts: mockTopProducts,
  topItems: mockTopItems,
  brandOverview: mockBrandOverview,
};
//
//
// THIS should go in variables.js, but we get an initialization error for some reason

const mask = {
  reference_count: dashboardKeys.referenceCount,
  products_parcels_count: dashboardKeys.parcelCount,
  product_count: dashboardKeys.productCount,
  item_quantity_count: dashboardKeys.itemCount,
  authentication_counts: dashboardKeys.allAuthentications,
  inspection: dashboardKeys.inspectionCount,
  authentication: dashboardKeys.authenticationCount,
  counterfeit: dashboardKeys.counterfeitCount,
  non_counterfeit: dashboardKeys.authenticCount,
  stopped: dashboardKeys.stoppedCount,
  customs: dashboardKeys.customsCount,
  seized: dashboardKeys.seizedCount,
  released: dashboardKeys.releasedCount,
  // there is no products, and this avoids clashes with overviewStats total keys
  // products: dashboardKeys.casesTotal,
  authentication_pending: dashboardKeys.pendingCount,
  authentication_complete: dashboardKeys.completeCount,
  complete: dashboardKeys.referenceDetailsCompleteCount,
  no_destination: dashboardKeys.noDestinationCount,
  no_origin: dashboardKeys.noOriginCount,
};
