import { formatStatusShares } from '../../../../../components/molecules/StatusShares/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { parcelKeys, referenceIndexKeys } from '../controllers/variables';

export const useGetParcelDetails = ({ id }) => {
  const [
    { data, isLoading = true, isError, error },
    refreshParcelDetails,
    getParcelDetails_,
  ] = useFetchData(`office_parcel/${id}`, {
    formatter,
    ignore: id == null,
  });

  const getParcelDetails = id => {
    const url = `office_parcel/${id}`;
    getParcelDetails_({ url });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    refreshParcelDetails: getParcelDetails_,
    getParcelDetails,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data[parcelKeys.statusShares] = formatStatusShares(
    data[parcelKeys.statusShares]
  );

  return data;
};

const mask = {
  [Field_Keys.REFERENCE.BRAND_NAME]: parcelKeys.reason,
  [Field_Keys.PARCEL.PRODUCT_COUNT]: parcelKeys.caseCount,
  [Field_Keys.PARCEL.ALIAS]: parcelKeys.alias,
  [Field_Keys.PARCEL.ID]: parcelKeys.id,
  [Field_Keys.PARCEL.RISK_TYPE]: parcelKeys.riskType,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: parcelKeys.sideloadDate,
  [Field_Keys.PARCEL.STATUS]: parcelKeys.status,
  [Field_Keys.PARCEL.VOLUME]: parcelKeys.parcelVolume,
  [Field_Keys.PARCEL.WEIGHT]: parcelKeys.parcelWeight,
  [Field_Keys.PARCEL.TRACKING_NUMBER]: parcelKeys.trackingNumber,
  [Field_Keys.PARCEL.BARCODES]: parcelKeys.barcodes,
  [Field_Keys.SORTER.COUNTRY]: parcelKeys.sorterCountry,
  [Field_Keys.SORTER.CITY]: parcelKeys.sorterCity,
  [Field_Keys.PARCEL.LAST_PRODUCT_ACTIVITY]: parcelKeys.lastActivity,
  [Field_Keys.SORTER.NAME]: parcelKeys.sorter,
  [Field_Keys.PARCEL.PRODUCT_STATUS_DISTRIBUTION]: parcelKeys.statusShares,
  //
  // Files
  //
  [Field_Keys.PARCEL.FILES]: parcelKeys.file.self,
  filename: parcelKeys.file.alias,
  url: parcelKeys.file.url,
  //
  // Reference Keys
  //
  [Field_Keys.REFERENCE.ACTOR_TYPE]: referenceIndexKeys.type,
  [Field_Keys.REFERENCE.ID]: referenceIndexKeys.id,
  [Field_Keys.REFERENCE.NAME]: referenceIndexKeys.name,
  [Field_Keys.REFERENCE.COMPANY]: referenceIndexKeys.company,
  [Field_Keys.REFERENCE.ADDRESS]: referenceIndexKeys.address,
  [Field_Keys.REFERENCE.PHONE]: referenceIndexKeys.phone,
  [Field_Keys.REFERENCE.POSTCODE]: referenceIndexKeys.zipcode,
  [Field_Keys.REFERENCE.CITY]: referenceIndexKeys.city,
  [Field_Keys.REFERENCE.STATE]: referenceIndexKeys.state,
  [Field_Keys.REFERENCE.COUNTRY]: referenceIndexKeys.country,
};
