import { Card } from '../../../../../components/atoms';
import InfoIcon from '../../../../../components/molecules/InfoIcon/InfoIcon';
import { Table } from '../../../../../components/organisms/Table/Table';
import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { devPortalTableComponents } from './molecules';

export const DevPortalLayout = ({
  headers,
  data,
  rowAction,
  grid,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  return (
    <Card
      title="Developer Portal"
      className="devportal"
      icon={
        !isLoading && !isError && data.length > 0 ? (
          <InfoIcon description={'Make sure to keep these codes private.'} />
        ) : (
          <></>
        )
      }
    >
      <TableStatus
        isLoading={isLoading}
        isError={isError}
        error={error}
        {...tableStatusProps}
        table={
          <Table
            headers={headers}
            data={data}
            rowAction={rowAction}
            components={devPortalTableComponents()}
            grid={grid}
          />
        }
      />
    </Card>
  );
};
