import * as Yup from 'yup';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { DISPATCH_CONSTANTS } from '../../../../../constants';
import isoCountryList from '../../../../../utils/isoCountryList';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { isValidField } from '../../../../../utils/utils';
import {
  referenceFieldsPlaceholders,
  referenceIndexKeys,
} from '../../../features_public/reference_index/controllers/variables';

export const whiteLabelKeys = {
  actions: 'actions',
  barcodes: 'barcodes',
  copiableBarcode: 'copiableBarcode',
  actionBarcodeGap: 'actionBarcodeGap',
  id: 'id',
  photos: 'photos',
  images: 'images',
  whiteLabelStatus: 'whiteLabelStatus',
  sideloadDate: 'sideloadDate',
  columnSpace: 'columnSpace',
  customerIdField: 'cc_customer_number',
  companyField: 'company',
  fullnameField: 'name',
  addressField: 'address',
  postcodeField: 'postcode',
  cityField: 'city',
  stateField: 'state',
  countryField: 'country',
  phoneField: 'phone',
  printData: 'printData',
  companyLabel: {
    title: 'title',
    receiver: 'receiver',
    sender: 'sender',
    originDepot: 'originDepot',
    deliveryDetails: 'deliveryDetails',
    parcelDetails: 'parcelDetails',
    additionalInformation: 'additionalInformation',
    ccParcelId: 'ccParcelId',
    aztec: 'aztec',
    routing: 'routing',
    barcode: 'barcode',
  },
};

export const headers = {
  [whiteLabelKeys.columnSpace]: '',
  [whiteLabelKeys.images]: 'Scanned Images',
  [whiteLabelKeys.sideloadDate]: 'Sideload Date',
  [whiteLabelKeys.barcodes]: 'Barcodes',
  [whiteLabelKeys.whiteLabelStatus]: 'Status',
  [whiteLabelKeys.actions]: 'Actions',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px
  // scanned images //
  308px
  // sideload date //
  minmax(80px, 0.6fr)
  // barcodes //
  minmax(120px, 1.1fr)
  //  status //
  minmax(40px, 0.75fr)
  //  actions //
  212px`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  ENTER_SEARCH: 'ENTER_SEARCH',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Scan or enter a barcode',
    param: 'search',
    icon: Icons.ScanLine,
    round: true,
    alwaysFullWidth: true,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
};

export const firstPageFiltersConstants = {
  [DC.SEARCH]: {
    ...filtersConstants[DC.SEARCH],
    size: '_L',
  },
};

export const allOptions = {};

export const multipleParcelsWarning =
  'There are multiple sorted parcels with this barcode number. Please make sure you handle the correct one.';

export const printStatusText = {
  true: 'Ready to print',
  false: 'Missing information. Edit the label before printing.',
};

const nameValidation = Yup.string()
  .min(2, 'Must be minimum 2 characters')
  .max(50, 'Must be maximum 50 characters');

const companyValidation = Yup.string()
  .min(2, 'Must be minimum 2 characters')
  .max(200, 'Must be maximum 200 characters');

const addressValidation = Yup.string()
  .min(2, 'Must be minimum 2 characters')
  .max(200, 'Must be maximum 200 characters');

const senderValidation = {
  [whiteLabelKeys.customerIdField]: Yup.string().required(
    'Please select a reference'
  ),
};

export const validationSchema = onlySenders =>
  onlySenders
    ? Yup.object().shape(senderValidation)
    : Yup.object().shape(
        {
          ...senderValidation,
          [whiteLabelKeys.fullnameField]: Yup.string().when(
            [whiteLabelKeys.companyField],
            {
              is: comp => {
                const companyIsValid = isValidField(comp, 2, 100);
                return companyIsValid;
              },
              then: nameValidation.notRequired(),
              otherwise: nameValidation.required(
                "Please enter the receiver's name or company"
              ),
            }
          ),
          [whiteLabelKeys.companyField]: Yup.string().when(
            [whiteLabelKeys.fullnameField],
            {
              is: name => {
                const nameIsValid = isValidField(name, 2, 50);
                return nameIsValid;
              },
              then: companyValidation.notRequired(),
              otherwise: companyValidation.required(
                "Please enter the receiver's name or company"
              ),
            }
          ),
          [whiteLabelKeys.addressField]: addressValidation.required(
            "Please enter the receiver's  address"
          ),
          [whiteLabelKeys.postcodeField]: Yup.string()
            .max(7, 'Must be maximum 7 characters')
            .required("Please enter the receiver's postcode"),
          [whiteLabelKeys.cityField]: Yup.string().max(
            50,
            'Must be maximum 50 characters'
          ),
          [whiteLabelKeys.stateField]: Yup.string().max(
            50,
            'Must be maximum 50 characters'
          ),
          [whiteLabelKeys.countryField]: Yup.string().required(
            'Please select a country'
          ),
          [whiteLabelKeys.phoneField]: Yup.string().max(
            50,
            'Must be maximum 50 characters'
          ),
        },
        [[whiteLabelKeys.fullnameField, whiteLabelKeys.companyField]]
      );

export const editFieldProps = {
  [whiteLabelKeys.countryField]: { type: 'select', options: isoCountryList },
  [whiteLabelKeys.customerIdField]: { type: 'select' },
};

export const editSenderKeys = [whiteLabelKeys.customerIdField];
export const editReceiverKeys = [
  whiteLabelKeys.fullnameField,
  whiteLabelKeys.companyField,
  whiteLabelKeys.addressField,
  whiteLabelKeys.phoneField,
  whiteLabelKeys.postcodeField,
  whiteLabelKeys.cityField,
  whiteLabelKeys.stateField,
  whiteLabelKeys.countryField,
];

export const editFieldsPlacholders = {
  [whiteLabelKeys.customerIdField]:
    referenceFieldsPlaceholders[referenceIndexKeys.name],
  [whiteLabelKeys.companyField]:
    referenceFieldsPlaceholders[referenceIndexKeys.company],
  [whiteLabelKeys.fullnameField]:
    referenceFieldsPlaceholders[referenceIndexKeys.name],
  [whiteLabelKeys.addressField]:
    referenceFieldsPlaceholders[referenceIndexKeys.address],
  [whiteLabelKeys.postcodeField]:
    referenceFieldsPlaceholders[referenceIndexKeys.zipcode],
  [whiteLabelKeys.cityField]:
    referenceFieldsPlaceholders[referenceIndexKeys.city],
  [whiteLabelKeys.stateField]:
    referenceFieldsPlaceholders[referenceIndexKeys.state],
  [whiteLabelKeys.countryField]:
    referenceFieldsPlaceholders[referenceIndexKeys.country],
  [whiteLabelKeys.phoneField]:
    referenceFieldsPlaceholders[referenceIndexKeys.phone],
};

export const getCancelButton = onClick => ({
  onClick,
  size: '_M',
  text: 'Cancel',
  variant: 'Tertiary',
});
export const getSaveButton = onClick => ({
  onClick,
  size: '_M',
  text: 'Save',
  variant: 'Secondary',
});
export const getPrintButton = onClick => ({
  onClick,
  size: '_M',
  text: 'Save and Print',
  variant: 'Primary',
});

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.WhiteLabel,
    title: 'No parcels',
    description: 'As soon as we catch a new parcel, you will find it here',
  }),
  noSearchResults: {
    icon: NoResults.WhiteLabel,
    title: 'No results found',
    description: 'Please try another barcode',
  },
};

export const barcodeHeaders = {
  [whiteLabelKeys.columnSpace]: '',
  [whiteLabelKeys.copiableBarcode]: 'Barcode',
  [whiteLabelKeys.actionBarcodeGap]: '',
  [whiteLabelKeys.actions]: '',
};

export const barcodeGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  8px
  // barcode //
  minmax(10px, 1fr)
  // actionBarcodeGap //
  32px
  //  actions //
  48px`),
  gridAutoRows: '48px 0px',
};
