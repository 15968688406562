import { Illustrations } from '../../../../../components/atoms/Icon/Icon.options';
import { Constants, PropKeys } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';

const { Status } = Constants;

export const productKeys = {
  actions: 'actions',
  activity: 'activity',
  attachments: 'attachments',
  attachmentId: 'attachmentId',
  attachmentName: 'attachmentName',
  attachmentURL: 'attachmentURL',
  authorityDetails: 'authorityDetails',
  authorityEmail: 'authorityEmail',
  authorityName: 'authorityName',
  authorityPhone: 'authorityPhone',
  brand: 'brand',
  brandId: 'brandId',
  brandName: 'brandName',
  brandImage: 'brandImage',
  hasSupportTickets: 'hasSupportTickets',
  productAlias: 'productAlias',
  productId: 'productId',
  productDetails: 'productDetails',
  productImage: 'productImage',
  productStatus: 'productStatus',
  category: 'category',
  createdAt: 'createdAt',
  confidence: 'confidence',
  expansionArrow: 'expansionArrow',
  isContracted: 'isContracted',
  isProductOwner: 'isProductOwner',
  isSorterCase: 'isSorterCase',
  itemQuantity: 'itemQuantity',
  itemQuantityOwn: 'itemQuantityOwn',
  lastActivity: 'lastActivity',
  leftPadding: 'leftPadding',
  notes: {
    self: 'notes.self',
    note: 'notes.note',
    userImage: 'notes.userImage',
    userName: 'notes.userName',
    dateCreated: 'notes.dateCreated',
  },
  parcelId: 'parcelId',
  parcelDetails: 'parcelDetails',
  photos: 'photos',
  productName: 'productName',
  productQuantity: 'productQuantity',
  reason: 'reason',
  riskType: 'riskType',
  shouldBlurActors: 'shouldBlurActors',
  statusIndicator: 'statusIndicator',
  steps: 'steps',
  sorter: 'sorter',
  subCategory: 'subCategory',
  tickets: 'tickets',
  // product details
  productSideloadDate: 'sideloadDate',
  parcelTrackingNumber: 'parcelTrackingNumber',
  parcelVolume: 'parcelVolume',
  parcelWeight: 'parcelWeight',
  sorterCity: 'sorterCity',
  sorterCountry: 'sorterCountry',
  sorterId: PropKeys.sorterId,
  sorterName: 'sorterName',
};

export const statusStepsIcons = {
  [Status.Inspection]: Illustrations.Inspection,
  [Status.Authentication]: Illustrations.AuthenticationRequired,
  [Status.Counterfeit]: Illustrations.AuthenticationCompleted,
  [Status['Non-counterfeit']]: Illustrations.AuthenticationCompleted,
  [Status.Stopped]: Illustrations.Stopped,
  [Status.Seized]: Illustrations.Seized,
  [Status.Released]: Illustrations.Released,
};

export const components = {
  productNotes: 'productNotes',
  productTickets: 'productTickets',
  parcelDetails: 'parcelDetails',
  productDetails: 'productDetails',
  riskAnalysis: 'riskAnalysis',
  authorityInfo: 'authorityInfo',
  recentActivity: 'recentActivity',
};

export const componentTitle = {
  [components.productNotes]: 'Notes',
  [components.productTickets]: 'Support tickets',
  [components.parcelDetails]: 'Parcel details',
  [components.productDetails]: 'Product details',
  [components.riskAnalysis]: 'Risk analysis',
  [components.authorityInfo]: 'Authority info',
  [components.recentActivity]: 'Recent activity',
};

export const infoDescription = {
  [components.parcelDetails]:
    'This section shows all information about the parcel containing the item(s) of this product. Sender and receiver details will be available when the item is handed over to the legal authority.',
  [components.riskAnalysis]:
    'This section shows the reason this parcel is selected for an inspection.',
  [components.authorityInfo]:
    'This section shows the details about the legal authority handling the parcel if it is seized.',
  [components.recentActivity]:
    'This section shows the changes in the processing status of this product.',
};

export const smallProductsListHeaders = {
  [productKeys.productImage]: '',
  [productKeys.productAlias]: '',
  [productKeys.productStatus]: '',
  [productKeys.actions]: '',
};

export const smallProductsListGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // product image //
  32px
  // product alias //
  minmax(110px, auto)
  // product status //
  124px
  // hover icon //
  minmax(0px, min-content)
  `),
  gridAutoRows: '48px 0px',
  gridTemplateRows: 'unset',
};
