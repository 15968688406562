import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { formatOverviewStat } from '../formatters/utils';
import { dashboardKeys, overviewReferenceStats } from '../variables';

export const useGetReferenceCount = ({
  searchParams,
  hasReferencePermission,
}) => {
  const [{ data = [], isLoading, isError, error }, _, __, refresh] =
    useFetchData('dashboard/reference_count', {
      formatter: formatter(hasReferencePermission),
      failureFormatter: failureFormatter(hasReferencePermission),
      params: formatParams(searchParams),
    });

  return { data, isLoading, isError, error, refresh };
};

const failureFormatter = hasPermission => () =>
  formatOverviewStat(overviewReferenceStats)({}, { hasPermission });

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};

const formatter = hasPermission => data => {
  const formatted = {
    [dashboardKeys.referenceCount]: data,
  };
  return formatOverviewStat(overviewReferenceStats)(formatted, {
    hasPermission,
  });
};
