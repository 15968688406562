import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data = {}, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('admin_brands/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { brand_groups, industries, contracts } = data;

  const newOps = {};

  newOps[DC.CONTRACTED] = prepareDataForDropdown(
    contracts,
    'label',
    Field_Keys.BRAND.IS_CONTRACTED
  );

  newOps[DC.BRAND_GROUP] = prepareDataForDropdown(
    brand_groups,
    Field_Keys.BRAND.GROUP_NAME,
    Field_Keys.BRAND.GROUP_NAME
  );

  newOps[DC.INDUSTRY] = prepareDataForDropdown(
    industries,
    Field_Keys.BRAND.INDUSTRY,
    Field_Keys.BRAND.INDUSTRY
  );

  return newOps;
};
