import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import { formatStatusShares } from '../../../../../components/molecules/StatusShares/utils';
import { Colors, Size } from '../../../../../components/style';
import { Constants, Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry, parseStringModifiers } from '../../../../../utils/utils';
import { parcelKeys } from '../../../features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import {
  getReasonTitle,
  getRiskTypes,
  getSideloadExplanation,
} from '../controllers/variables';
import {
  parcelDetailMockText,
  parcelDetailTitles,
  parcelDetailTypes,
} from '../variables';

const { fallback } = Constants;

export const useGetCaseDetails = ({ id, ignore, copyText }) => {
  // parcelId may be missing before manual fetch by getCaseDetails.
  // keep loading until manually fetched unless ignored
  const [
    { data = {}, isLoading = !ignore, isError, error },
    _,
    getCaseDetails_,
  ] = useFetchData('', {
    formatter: formatter({ copyText }),
    ignore: id == null || ignore,
  });

  const getCaseDetails = id => {
    const url = `office_parcel/${id}`;
    getCaseDetails_({ url });
  };

  return {
    data: ignore ? formatter({})({}) : data,
    isLoading,
    isError,
    error,
    getCaseDetails,
  };
};

const formatter =
  ({ copyText }) =>
  data => {
    data = maskEntry(data, mask);
    const shouldBlurActors = false;
    data[parcelKeys.statusShares] = formatStatusShares(
      data[parcelKeys.statusShares]
    );

    const details = formatSnapshotDetails(data[parcelKeys.actors], {
      barcodes: data[productKeys.parcel.barcodes],
      trackingNumber: data[productKeys.parcel.trackingNumber],
      shouldBlurActors,
      catchDate: joinStrings(
        formatDate(data[productKeys.productSideloadDate], DateTypes.ddmmyy),
        formatDate(data[productKeys.productSideloadDate], DateTypes.time12),
        ' - '
      ),
      totalItems: data[productKeys.parcel.totalItems],
      copyText,
    });

    const riskAnalysis = {
      title: getReasonTitle(data[parcelKeys.reason]),
      description: getSideloadExplanation(
        data[parcelKeys.reason],
        data[parcelKeys.riskType]
      ),
      riskPotential: {
        value: data[parcelKeys.confidence] ?? 0,
        display: `${
          data[parcelKeys.confidence] == null
            ? 'n/a'
            : `${data[parcelKeys.confidence]}%`
        }`,
        fill: Colors.Primary._600,
      },
    };

    return {
      ...data,
      [productKeys.parcelDetails]: details,
      [productKeys.shouldBlurActors]: shouldBlurActors,
      [productKeys.reason]: riskAnalysis,
      [productKeys.riskType]: getRiskTypes(data[productKeys.riskType]),
      [parcelKeys.authorityId]:
        data?.[parcelKeys.authority]?.[parcelKeys.authorityId],
      [parcelKeys.authority]: getAuthorityDetails(data),
      [parcelKeys.activity]: formatParcelActivity(data[parcelKeys.activity]),
    };
  };

const formatSnapshotDetails = (
  actors,
  {
    barcodes,
    trackingNumber,
    catchDate,
    totalItems,
    shouldBlurActors,
    copyText,
  }
) => {
  const copyableBarcodeElement = barcode => {
    return (
      <AnimatedIcon
        name={Icons.Copy}
        size={Size.Icon._XS}
        onClick={copyText(barcode)}
        theme={'light'}
      />
    );
  };

  const mock = shouldBlurActors;
  const details = [
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.catchDateType],
          info: catchDate ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.totalItemsType],
          info: totalItems ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.trackingType],
          info: trackingNumber ?? fallback,
        },
      ],
    },
    {
      className: 'barcodes',
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.barcodesType],
          info: barcodes?.[0] ?? fallback,
          element: barcodes?.[0] ? copyableBarcodeElement(barcodes[0]) : null,
        },
        ...(barcodes ?? []).slice(1).map(barcode => ({
          info: barcode,
          element: copyableBarcodeElement(barcode),
        })),
      ],
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.senderType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.senderType),
        },
      ],
      mock,
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.receiverType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.receiverType),
        },
      ],
      mock,
    },
  ];

  return details;
};

const getActorInfo = (actors, actorType) => {
  const actor =
    (actors ?? []).find(actor => actor[parcelKeys.actorType] === actorType) ??
    {};
  const formattedInfo =
    '**' +
    (joinStrings(
      actor?.[parcelKeys.actorName],
      actor?.[parcelKeys.actorCompany]
    ) || fallback) +
    '**' +
    '\n\n' +
    (actor?.[parcelKeys.actorAddress] || fallback) +
    '\n' +
    (actor?.[parcelKeys.actorPostcode] || fallback) +
    '\n' +
    (actor?.[parcelKeys.actorCountry] || fallback) +
    '\n' +
    (actor?.[parcelKeys.actorPhone] || fallback);

  return formattedInfo;
};

const getAuthorityDetails = data => {
  if (
    data?.[parcelKeys.authority] == null ||
    data?.[parcelKeys.authority][parcelKeys.authorityId] == null
  )
    return;
  const details = [
    {
      title: 'Name',
      info:
        data?.[parcelKeys.authority]?.[parcelKeys.authorityName] ?? fallback,
    },
    {
      title: 'Email',
      info:
        data?.[parcelKeys.authority]?.[parcelKeys.authorityEmail] ?? fallback,
    },
    {
      title: 'Phone',
      info:
        data?.[parcelKeys.authority]?.[parcelKeys.authorityPhone] ?? fallback,
    },
    {
      title: 'Fax',
      info: data?.[parcelKeys.authority]?.[parcelKeys.authorityFax] ?? fallback,
    },
  ];
  return details;
};

const activityEntryFormatter =
  ({ dateKey, contentKey }) =>
  entry => {
    const { [dateKey]: date, [contentKey]: text } = entry;

    return {
      date: `${formatDate(date, DateTypes.ddmmyy)}, ${formatDate(
        date,
        DateTypes.time12
      )}`,
      text: parseStringModifiers(text),
    };
  };

const formatParcelActivity = activity => {
  const format = activityEntryFormatter({
    dateKey: [Field_Keys.PARCEL.LOG_CREATED_AT],
    contentKey: [Field_Keys.PARCEL.LOG_CONTENT],
  });

  return activity.map(format);
};

const mask = {
  [Field_Keys.PARCEL.AUTHORITY]: parcelKeys.authority,
  [Field_Keys.AUTHORITY.ID]: parcelKeys.authorityId,
  [Field_Keys.AUTHORITY.EMAIL]: parcelKeys.authorityEmail,
  [Field_Keys.AUTHORITY.NAME]: parcelKeys.authorityName,
  [Field_Keys.AUTHORITY.PHONE]: parcelKeys.authorityPhone,
  [Field_Keys.AUTHORITY.FAX]: parcelKeys.authorityFax,

  [Field_Keys.PARCEL.ACTORS]: parcelKeys.actors,
  [Field_Keys.REFERENCE.ACTOR_TYPE]: parcelKeys.actorType,
  [Field_Keys.REFERENCE.NAME]: parcelKeys.actorName,
  [Field_Keys.REFERENCE.COMPANY]: parcelKeys.actorCompany,
  [Field_Keys.REFERENCE.ADDRESS]: parcelKeys.actorAddress,
  [Field_Keys.REFERENCE.POSTCODE]: parcelKeys.actorPostcode,
  [Field_Keys.REFERENCE.COUNTRY]: parcelKeys.actorCountry,
  [Field_Keys.REFERENCE.PHONE]: parcelKeys.actorPhone,

  [Field_Keys.PARCEL.ACTIVITY]: parcelKeys.activity,

  [Field_Keys.PARCEL.TRACKING_NUMBER]: productKeys.parcel.trackingNumber,
  [Field_Keys.PARCEL.BARCODES]: productKeys.parcel.barcodes,
  [Field_Keys.PARCEL.VOLUME]: productKeys.parcelVolume,
  [Field_Keys.PARCEL.WEIGHT]: productKeys.parcelWeight,
  // should_show_actors,
  [Field_Keys.SORTER.NAME]: parcelKeys.sorter,
  [Field_Keys.SORTER.COUNTRY]: productKeys.sorterCountry,
  [Field_Keys.SORTER.CITY]: productKeys.sorterCity,
  // own_quantity,
  // rest_quantity,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: productKeys.productSideloadDate,
  [Field_Keys.PARCEL.PRODUCT_COUNT]: parcelKeys.caseCount,
  [Field_Keys.PARCEL.ALIAS]: parcelKeys.alias,
  [Field_Keys.PARCEL.ID]: parcelKeys.id,
  [Field_Keys.PARCEL.RISK_TYPE]: parcelKeys.riskType,
  [Field_Keys.PARCEL.STATUS]: parcelKeys.status,
  [Field_Keys.PARCEL.DETECTION_CONFIDENCE]: parcelKeys.confidence,
  [Field_Keys.REFERENCE.BRAND_NAME]: parcelKeys.reason,
  // product keys
  [Field_Keys.PRODUCT.ITEM_QUANTITY]: parcelKeys.productQuantity,
  [Field_Keys.PARCEL.LAST_PRODUCT_ACTIVITY]: parcelKeys.lastActivity,
  [Field_Keys.PARCEL.OWN_ITEM_QUANTITY]: parcelKeys.ownQuantity,
  [Field_Keys.PARCEL.TOTAL_ITEM_QUANTITY]: productKeys.parcel.totalItems,
  [Field_Keys.PARCEL.PRODUCT_STATUS_DISTRIBUTION]: parcelKeys.statusShares,
};
