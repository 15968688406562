import {
  prepareDataForDropdown,
  withContractedLabel,
} from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { sortByLabel } from '../../../../../utils/arrayUtils';
import { maskEntry } from '../../../../../utils/utils';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const useGetBrands = () => {
  const [{ data = [], isLoading, isError, error }, _] = useFetchData('brands', {
    formatter,
    failureFormatter: _ => [],
  });

  return { data, isLoading, isError, error };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data = prepareDataForDropdown(
    data,
    productKeys.brandObj.name,
    productKeys.brandObj.id,
    {
      customLabel: item =>
        withContractedLabel(item, {
          contractedKey: productKeys.brandObj.isContracted,
          labelKey: productKeys.brandObj.name,
        }),
    }
  )[0];

  data.options.sort(sortByLabel);

  return data;
};

const mask = {
  [Field_Keys.PRODUCT.BRAND_NAME]: productKeys.brandObj.name,
  [Field_Keys.PRODUCT.BRAND_ID]: productKeys.brandObj.id,
  [Field_Keys.PRODUCT.BRAND_IMAGE]: productKeys.brandObj.image,
  [Field_Keys.PRODUCT.IS_BRAND_CONTRACTED]: productKeys.brandObj.isContracted,
  brands: 'unformattedBrands',
};
