import { Colors } from '../../../../../components/style';
import { Constants, Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { isArray } from '../../../../../lib/js';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import {
  getReasonTitle,
  getRiskTypes,
  getSideloadExplanation,
} from '../controllers/variables';
import {
  parcelDetailMockText,
  parcelDetailTitles,
  parcelDetailTypes,
} from '../variables';

const { fallback } = Constants;

export const useGetCaseDetails = ({ id, ignore }) => {
  // parcelId may be missing before manual fetch by getCaseDetails.
  // keep loading until manually fetched unless ignored
  const [
    { data = {}, isLoading = !ignore, isError, error },
    _,
    getCaseDetails_,
  ] = useFetchData(`parcel/${id}`, {
    formatter,
    ignore: id == null || ignore,
  });

  const getCaseDetails = id => {
    const url = `parcel/${id}`;
    getCaseDetails_({ url });
  };

  return {
    data: ignore ? formatter({}) : data,
    isLoading,
    isError,
    error,
    getCaseDetails,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  const shouldBlurActors =
    data[parcelKeys.actors] == null || data[parcelKeys.shouldBlurActors];

  data[caseKeys.statusShares] = formatStatusShares(data[caseKeys.statusShares]);

  const details = formatSnapshotDetails(data[parcelKeys.actors], {
    volume: data[productKeys.parcelVolume],
    weight: data[productKeys.parcelWeight],
    trackingNumber: data[productKeys.parcelTrackingNumber],
    shouldBlurActors,
    location: joinStrings(
      data[productKeys.sorterCountry],
      data[productKeys.sorterCity],
      ' - '
    ),
    catchDate: joinStrings(
      formatDate(data[productKeys.productSideloadDate], DateTypes.ddmmyy),
      formatDate(data[productKeys.productSideloadDate], DateTypes.time12),
      ' - '
    ),
    totalItems: `${data[caseKeys.ownQuantity] ?? Constants.fallback} / ${
      data[caseKeys.totalQuantity] ?? Constants.fallback
    }`,
  });

  const riskAnalysis = {
    title: getReasonTitle(data[parcelKeys.reason]),
    description: getSideloadExplanation(
      data[parcelKeys.reason],
      data[parcelKeys.riskType]
    ),
    riskPotential: {
      value: data[parcelKeys.confidence] ?? 0,
      display: `${
        data[parcelKeys.confidence] == null
          ? 'n/a'
          : `${data[parcelKeys.confidence]}%`
      }`,
      fill: Colors.Primary._600,
    },
  };

  return {
    ...data,
    [productKeys.parcelDetails]: details,
    [productKeys.shouldBlurActors]: shouldBlurActors,
    [productKeys.reason]: riskAnalysis,
    [productKeys.riskType]: getRiskTypes(data[productKeys.riskType]),
  };
};

const formatStatusShares = shares => {
  if (isArray(shares)) return shares;
  const status = [
    Constants.Status.Seized,
    Constants.Status.Released,
    Constants.Status.Stopped,
    Constants.Status['Non-counterfeit'],
    Constants.Status.Counterfeit,
    Constants.Status.Authentication,
    Constants.Status.Inspection,
  ];

  const inputShares = status.map(status => {
    return {
      status,
      percentage: Math.round((shares?.[status] ?? 0) * 100),
    };
  });

  return inputShares;
};

const formatSnapshotDetails = (
  actors,
  {
    volume,
    weight,
    trackingNumber,
    shouldBlurActors,
    location,
    catchDate,
    totalItems,
  }
) => {
  const mock = shouldBlurActors;
  const details = [
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.locationType],
          info: location ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.catchDateType],
          info: catchDate ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.totalItemsType],
          info: totalItems ?? fallback,
        },
      ],
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.volumeType],
          info: volume ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.weightType],
          info: weight ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.trackingType],
          info: trackingNumber ?? fallback,
        },
      ],
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.senderType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.senderType),
        },
      ],
      mock,
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.receiverType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.receiverType),
        },
      ],
      mock,
    },
  ];

  return details;
};

const getActorInfo = (actors, actorType) => {
  const actor =
    actors.find(actor => actor[parcelKeys.actorType] === actorType) ?? {};
  const formattedInfo =
    '**' +
    (joinStrings(
      actor?.[parcelKeys.actorName],
      actor?.[parcelKeys.actorCompany]
    ) || fallback) +
    '**' +
    '\n\n' +
    (actor?.[parcelKeys.actorAddress] || fallback) +
    '\n' +
    (actor?.[parcelKeys.actorPostcode] || fallback) +
    '\n' +
    (actor?.[parcelKeys.actorCountry] || fallback) +
    '\n' +
    (actor?.[parcelKeys.actorPhone] || fallback);

  return formattedInfo;
};

const mask = {
  [Field_Keys.PARCEL.AUTHORITY]: parcelKeys.authority,
  [Field_Keys.AUTHORITY.ID]: parcelKeys.authorityId,
  [Field_Keys.AUTHORITY.EMAIL]: parcelKeys.authorityEmail,
  [Field_Keys.AUTHORITY.NAME]: parcelKeys.authorityName,
  [Field_Keys.AUTHORITY.PHONE]: parcelKeys.authorityPhone,
  [Field_Keys.AUTHORITY.FAX]: parcelKeys.authorityFax,

  [Field_Keys.PARCEL.ACTORS]: parcelKeys.actors,
  [Field_Keys.REFERENCE.ACTOR_TYPE]: parcelKeys.actorType,
  [Field_Keys.REFERENCE.NAME]: parcelKeys.actorName,
  [Field_Keys.REFERENCE.COMPANY]: parcelKeys.actorCompany,
  [Field_Keys.REFERENCE.ADDRESS]: parcelKeys.actorAddress,
  [Field_Keys.REFERENCE.POSTCODE]: parcelKeys.actorPostcode,
  [Field_Keys.REFERENCE.COUNTRY]: parcelKeys.actorCountry,
  [Field_Keys.REFERENCE.PHONE]: parcelKeys.actorPhone,

  [Field_Keys.PARCEL.TRACKING_NUMBER]: productKeys.parcelTrackingNumber,
  [Field_Keys.PARCEL.VOLUME]: productKeys.parcelVolume,
  [Field_Keys.PARCEL.WEIGHT]: productKeys.parcelWeight,
  // should_show_actors,
  [Field_Keys.SORTER.COUNTRY]: productKeys.sorterCountry,
  [Field_Keys.SORTER.CITY]: productKeys.sorterCity,
  // own_quantity,
  // rest_quantity,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: productKeys.productSideloadDate,
  [Field_Keys.PARCEL.PRODUCT_COUNT]: parcelKeys.caseCount,
  [Field_Keys.PARCEL.SHOULD_SHOW_ACTORS]: !parcelKeys.shouldBlurActors,
  [Field_Keys.PARCEL.ALIAS]: parcelKeys.alias,
  [Field_Keys.PARCEL.ID]: parcelKeys.id,
  [Field_Keys.PARCEL.RISK_TYPE]: parcelKeys.riskType,
  [Field_Keys.PARCEL.STATUS]: parcelKeys.status,
  [Field_Keys.PARCEL.DETECTION_CONFIDENCE]: parcelKeys.confidence,
  [Field_Keys.REFERENCE.BRAND_NAME]: parcelKeys.reason,
  // product keys
  [Field_Keys.PRODUCT.ITEM_QUANTITY]: caseKeys.productQuantity,
  [Field_Keys.PARCEL.LAST_PRODUCT_ACTIVITY]: caseKeys.lastActivity,
  [Field_Keys.PARCEL.OWN_ITEM_QUANTITY]: caseKeys.ownQuantity,
  [Field_Keys.PARCEL.TOTAL_ITEM_QUANTITY]: caseKeys.totalQuantity,
  [Field_Keys.SORTER.NAME]: caseKeys.sorter,
  [Field_Keys.PARCEL.PRODUCT_STATUS_DISTRIBUTION]: caseKeys.statusShares,
};
