import { useEffect, useLayoutEffect, useState } from 'react';
import { StatusColors } from '../../../../../components/style';
import { Field_Keys } from '../../../../../constants';
import { useGetParcelsImages } from '../../../features/parcels/api/getParcelImages';
import { formatCaseDetails } from '../../cases/api/formatCase';
import { caseKeys } from '../../cases/controllers/variables';
import { parcelKeys } from '../../parcels/controllers/variables';
import { CaseSnapshotLayout } from '../components/CaseSnapshotLayout';

const CaseSnapshot = ({
  caseData,
  title,
  onClick,
  showGallery,
  caseImageRef,
  isLoading,
}) => {
  const [image, setImage] = useState(undefined);
  const [status] = caseData?.[caseKeys.status]?.split('_') ?? ['Unknown'];

  const color = StatusColors[status] ?? StatusColors.Gray;

  const {
    data: images,
    isLoading: imagesIsLoading,
    getParcelsImages: getCasesImages,
  } = useGetParcelsImages();

  useLayoutEffect(() => {
    if (caseData[caseKeys.id] == null && caseData[Field_Keys.PARCEL.ID] == null)
      return;
    const image =
      caseData?.[parcelKeys.photos]?.[0]?.[Field_Keys.PHOTO.THUMBNAIL];
    setImage(image);
    if (image != null) return;
    getCasesImages({ parcels: [caseData] });
  }, [caseData]);

  useEffect(() => {
    if (images == null) return;
    const image = images[0]?.photos[0]?.[Field_Keys.PHOTO.THUMBNAIL];
    setImage(image);
  }, [images]);

  const caseDetails = formatCaseDetails(caseData);
  return (
    <CaseSnapshotLayout
      color={color}
      status={status}
      image={image}
      shares={caseData[caseKeys.statusShares]}
      caseDetails={caseDetails}
      title={title}
      onClick={onClick}
      showGallery={showGallery}
      caseImageRef={caseImageRef}
      isLoading={isLoading}
      imagesIsLoading={imagesIsLoading}
    />
  );
};
export default CaseSnapshot;
