import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { uniqueBy } from '../../../../../lib/js';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data = {}, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('admin_categories/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { categories } = data;

  const newOps = {};

  newOps[DC.CATEGORY] = prepareDataForDropdown(
    uniqueBy(categories, Field_Keys.CATEGORY.ID),
    Field_Keys.CATEGORY.NAME,
    Field_Keys.CATEGORY.ID
  );

  return newOps;
};
