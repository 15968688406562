import { getTooltipKey } from '../../../../../components/molecules/Tooltip/utils';
import { Field_Keys } from '../../../../../constants';
import usePostData from '../../../../../hooks/postHook';
import { maskEntry } from '../../../../../utils/utils';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const useGetDots = () => {
  const [{ data, isLoading, isError, error }, getDots] = usePostData(
    'office_parcels/situations',
    {
      large: true,
    }
  );

  const doGetDots = ({ parcel_ids }) => {
    if (parcel_ids == null || parcel_ids.length === 0) return;
    const body = {
      parcel_ids,
    };
    getDots(body);
  };

  return {
    data: formatDots(data),
    isLoading,
    isError,
    error,
    getDots: doGetDots,
  };
};

const formatDots = data => {
  maskEntry(data, mask);
  return data;
};

export const formatterDots = row => {
  const hasOrigin = row[productKeys.parcel.origin];
  const hasDestination = row[productKeys.parcel.destination];
  const isChecked = row[parcelKeys.checked];

  return {
    [getTooltipKey(parcelKeys.checked)]: {
      content: isChecked ? 'Checked-in' : 'Not checked-in',
    },
    [getTooltipKey(productKeys.parcel.origin)]: {
      content: !hasOrigin ? 'Origin missing' : 'Origin entered',
    },
    [getTooltipKey(productKeys.parcel.destination)]: {
      content: !hasDestination ? 'Destination missing' : 'Destination entered',
    },
  };
};

export const formatCheckedInTooltipContent = item => {
  const isHoldConfirmed = item[parcelKeys.isHoldConfirmed];
  const isReportConfirmed = item[parcelKeys.isReportConfirmed];
  const isSeizeConfirmed = item[parcelKeys.isSeizeConfirmed];
  const isStopConfirmed = item[parcelKeys.isStopConfirmed];

  let text = `Next parcel status: ${item[parcelKeys.nextStep]}`;
  if (isHoldConfirmed) text += `\n Hold confirmed`;
  if (isStopConfirmed) text += `\n Stop confirmed`;
  if (isReportConfirmed) text += `\n Report confirmed`;
  if (isSeizeConfirmed) text += `\n Seize confirmed`;

  return text;
};

const mask = {
  [Field_Keys.PARCEL.SITUATION_STYLE]: parcelKeys.nextStep,
};
