import { Field_Keys } from '../../../../../constants';
import usePostData from '../../../../../hooks/postHook';
import { maskEntry } from '../../../../../utils/utils';
import { processingKeys } from '../controllers/variables';

export const useCheckInParcel = () => {
  const [{ data, body, isLoading, isError, error }, doCheckin] = usePostData(
    'office_parcels/checkin',
    { formatter }
  );

  return { data, body, isLoading, isError, error, doCheckin };
};

const formatter = data => {
  console.info({ data: JSON.stringify(data) });
  data = maskEntry(data, mask);
  console.info({ data1: data });
  data[processingKeys.image] =
    data[processingKeys.photos]?.[0]?.[Field_Keys.PHOTO.THUMBNAIL];

  try {
    data[processingKeys.situation.self][
      processingKeys.situation.headerButtons
    ] = JSON.parse(
      data[processingKeys.situation.self][
        processingKeys.situation.headerButtons
      ]
    );
  } catch {
    data[processingKeys.situation.self][
      processingKeys.situation.headerButtons
    ] = [];
  }
  try {
    data[processingKeys.situation.self][
      processingKeys.situation.descriptionButtons
    ] = JSON.parse(
      data[processingKeys.situation.self][processingKeys.situation.self][
        processingKeys.situation.descriptionButtons
      ]
    );
  } catch {
    data[processingKeys.situation.self][
      processingKeys.situation.descriptionButtons
    ] = [];
  }

  console.info({ data2: data });

  return { ...data, ...data[processingKeys.situation.self] };
};

const mask = {
  [Field_Keys.PARCEL.ID]: processingKeys.id,
  [Field_Keys.PARCEL.ALIAS]: processingKeys.alias,
  [Field_Keys.PARCEL.RISK_TYPE]: processingKeys.riskType,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: processingKeys.sideloadDate,
  [Field_Keys.PARCEL.PHOTOS]: processingKeys.photos,
  [Field_Keys.PARCEL.SITUATION]: processingKeys.situation.self,
  [Field_Keys.PARCEL.SITUATION_CODE]: processingKeys.situationCode,
  [Field_Keys.PARCEL.SITUATION_TITLE]: processingKeys.situation.title,
  [Field_Keys.PARCEL.SITUATION_INSTRUCTIONS]:
    processingKeys.situation.description,
  [Field_Keys.PARCEL.SITUATION_STYLE]: processingKeys.situation.styleStatus,
  [Field_Keys.PARCEL.SITUATION_BUTTONS]: processingKeys.situation.headerButtons,
  [Field_Keys.PARCEL.SITUATION_CONFIRM_BUTTONS]:
    processingKeys.situation.descriptionButtons,
  [Field_Keys.PARCEL.BARCODE]: processingKeys.barcode,
};
