import usePostData from '../../../../../hooks/postHook';

export const useGetProductsImage = () => {
  const [{ data, isLoading, isError, error }, getProductsImage] = usePostData(
    'office_products/images'
  );

  const doGetProductsImage = ({ product_ids, limit = 1 }) => {
    if (product_ids == null || product_ids.length === 0) return;
    const body = {
      product_ids,
      only_thumbs: true,
      limit,
    };
    getProductsImage(body);
  };

  return {
    data,
    isLoading,
    isError,
    error,
    getProductsImage: doGetProductsImage,
  };
};
