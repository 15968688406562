import * as Yup from 'yup';
import {
  Icons,
  InstructionTypes,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { StatusColors } from '../../../../../components/style';
import {
  Constants,
  DISPATCH_CONSTANTS,
  Utils,
  re,
} from '../../../../../constants';
import { cloneDeep } from '../../../../../lib/js';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { parseStringModifiers } from '../../../../../utils/utils';
import { dropdownValidation } from '../../../../../utils/validationUtils';

export const knowledgeBaseKeys = {
  addedBy: 'addedBy',
  addedByName: 'addedByName',
  addedByImage: 'addedByImage',
  attachments: 'attachments',
  attachmentId: 'attachmentId',
  attachmentName: 'attachmentName',
  attachmentURL: 'attachmentURL',
  authenticity: 'authenticity',
  authenticityValue: 'authenticityValue',
  brand: 'brand',
  brandImage: 'brandImage',
  brandName: 'brandName',
  categoryId: 'categoryId',
  characteristicType: 'characteristicType',
  characteristicItemId: 'characteristicItemId',
  characteristics: 'characteristics',
  charactersticId: 'charactersticId',
  characteristicDescription: 'characteristicDescription',
  imageUrl: 'imageUrl',
  characteristicId: 'characteristicId',
  characteristicsCount: 'characteristicsCount',
  date: 'date',
  description: 'description',
  leftPadding: 'leftPadding',
  links: 'links',
  link: 'link',
  linkId: 'linkId',
  id: 'id',
  name: 'name',
  productCategory: 'productCategory',
  productSubCategory: 'productSubCategory',
};

export const headers = {
  [knowledgeBaseKeys.leftPadding]: '',
  [knowledgeBaseKeys.name]: 'Name',
  [knowledgeBaseKeys.brand]: 'Brand',
  [knowledgeBaseKeys.productCategory]: 'Product category',
  [knowledgeBaseKeys.date]: 'Date',
  [knowledgeBaseKeys.characteristicsCount]: 'Characteristics',
  [knowledgeBaseKeys.addedBy]: 'Added by',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
    // leftPadding //
    16px 
    // name //
    minmax(120px , 1fr)
    // brand //
    minmax(100px , 0.75fr)
    // category //
    minmax(120px , 1fr)
    // date //
    minmax(120px , 1fr)
    // key characteristics //
    120px
    // added by  //
    minmax(120px , 1fr)
    `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
  ADDED_BY: 'ADDED_BY',
  BRANDS: 'BRANDS',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
  },
  [DC.BRANDS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Tag,
  },
  [DC.PRODUCT_CATEGORY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Tag,
  },
  [DC.ADDED_BY]: {
    type: FilterTypes.dropdown,
    icon: Icons.User,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const allOptions = {
  [DC.PRODUCT_CATEGORY]: { label: 'All', value: Constants.all },
  [DC.ADDED_BY]: { label: 'All', value: Constants.all },
  [DC.BRANDS]: { label: 'All brands', value: Constants.all },
};

export const initialFilterValues = {
  [DC.PRODUCT_CATEGORY]: '',
  [DC.ADDED_BY]: '',
  [DC.BRANDS]: '',
};

export const titles = {
  [DC.TIME_FRAME]: 'All time',
  [DC.PRODUCT_CATEGORY]: 'Category',
  [DC.ADDED_BY]: 'Added by',
  [DC.BRANDS]: 'Brand',
};

const errorText = {
  [Constants.FileUpload.extensionError]: parseStringModifiers(
    Constants.FileUpload.uploadTypeUtils.knowledge_base.extensionError
  ),
  [Constants.FileUpload.uploadError]: parseStringModifiers(
    `There was an error uploading this file.\nPlease try again.`
  ),
  [Constants.FileUpload.unexpectedError]: parseStringModifiers(
    `There was an error uploading this file.\nPlease try again.`
  ),
  [Constants.FileUpload.fileSizeError]: parseStringModifiers(
    `${Constants.FileUpload.uploadTypeUtils.knowledge_base.fileSizeError}\nPlease try again.`
  ),
};

export const uploadStatusContent = ({ fileState, resetFileUpload }) => {
  const { files, isUploading, completedUpload, uploadSuccess, errorType } =
    fileState;

  if (!files) {
    return {
      icon: Icons.FileInput,
      text: parseStringModifiers(`Drag and drop your file \n\nor`),
      button: {
        onClick: Utils.emptyFunction,
        text: 'Browse files',
      },
    };
  }

  if (isUploading)
    return {
      title: 'Uploading...',
    };

  if (!completedUpload) {
    const numOfFiles = files.length;
    let text = `Your file: **${files[0].name}** is ready to import`;
    if (numOfFiles > 1)
      text = `Your **${numOfFiles}** files are ready to import`;
    return {
      icon: Icons.Upload,
      title: 'Upload ready',
      text: parseStringModifiers(text),
    };
  }

  if (!uploadSuccess)
    return {
      icon: Icons.Warning,
      title: 'Upload failed',
      text: errorText[errorType],
      button: {
        onClick: resetFileUpload,
        text: 'Try again',
      },
    };

  if (uploadSuccess)
    return {
      icon: Icons.CheckCircle,
      title: 'Uploading success',
      text: 'Your file has been successfully imported',
    };
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.KnowledgeBase,
    title: 'No knowledge base',
    description:
      'To help us to catch your counterfeit goods add a new knowledge base.',
    button: {
      text: 'Add knowledge base',
      variant: 'Primary',
      size: '_M',
      onClick,
    },
  }),
  noSearchResults: {
    icon: NoResults.KnowledgeBase,
    title: 'No results found',
    description: 'Please try another search or view all knowledge base',
  },
};

export const manualInstructionNames = {
  productCategory: 'category_id',
  ruleName: 'title',
  cloudStorage: 'cloudStorage',
  description: 'content',
};

export const manualInstructionInitialValues = {
  [manualInstructionNames.productCategory]: '',
  [manualInstructionNames.ruleName]: '',
  [manualInstructionNames.cloudStorage]: '',
  [manualInstructionNames.description]: '',
};

export const manualInstructionFields = [
  {
    name: manualInstructionNames.productCategory,
    label: 'Product category',
    placeholder: 'Select a product category',
    type: 'select',
    hasError: true,
  },
  {
    name: manualInstructionNames.ruleName,
    label: 'Knowledge base name',
    placeholder: 'Enter a knowledge base name',
    hasError: true,
  },
  {
    name: manualInstructionNames.cloudStorage,
    label: 'Link',
    placeholder: 'Add a link',
    hasError: true,
  },
  {
    name: manualInstructionNames.description,
    label: 'Description',
    placeholder: 'Enter a description',
  },
];

export const wizardInstructionNames = {
  productCategory: 'category_id',
  ruleName: 'title',
  description: 'content',
};

export const wizardInstructionInitialValues = {
  [wizardInstructionNames.productCategory]: '',
  [wizardInstructionNames.ruleName]: '',
  [wizardInstructionNames.description]: '',
};

export const wizardInstructionFields = [
  {
    name: wizardInstructionNames.productCategory,
    label: 'Product category',
    placeholder: 'Select a product category',
    type: 'select',
    hasError: true,
  },
  {
    name: wizardInstructionNames.ruleName,
    label: 'Knowledge base name',
    placeholder: 'Enter a knowledge base name',
    hasError: true,
  },
  {
    name: wizardInstructionNames.description,
    label: 'Description',
    placeholder: 'Enter a short description',
    type: 'textarea',
  },
];

export const descriptionNames = {
  description: 'content',
  ruleName: 'title',
  productCategory: 'category_id',
};

export const descriptionFields = [
  {
    name: descriptionNames.productCategory,
    placeholder: 'Product category',
    type: 'select',
    size: '_M',
    hasError: true,
  },
  {
    name: descriptionNames.ruleName,
    placeholder: 'Name',
    size: '_M',
    hasError: true,
  },
  {
    name: descriptionNames.description,
    placeholder: 'Description',
    type: 'textarea',
    hasError: true,
  },
];

const productCategoryValidation = Yup.object().shape({
  label: Yup.string().notRequired(),
  value: Yup.string().required('Please select a product category'),
});

const linkValidation = Yup.string()
  .min(3, '3 chars minimum')
  .max(999, '999 chars maximum');

const ruleNameValidation = Yup.string()
  .min(3, '3 chars minimum')
  .max(75, '75 chars maximum')
  .required('Please enter a name');

const descriptionValidationObject = Yup.string()
  .min(3, '3 chars minimum')
  .max(999, '999 chars maximum')
  .notRequired();

export const manualInstructionValidation = Yup.object().shape({
  [manualInstructionNames.productCategory]: productCategoryValidation,
  [manualInstructionNames.ruleName]: ruleNameValidation,
  [manualInstructionNames.cloudStorage]: linkValidation,
  [manualInstructionNames.description]: descriptionValidationObject,
  brand: dropdownValidation,
});

export const wizardInstructionValidation = Yup.object().shape({
  [wizardInstructionNames.productCategory]: productCategoryValidation,
  [wizardInstructionNames.ruleName]: ruleNameValidation,
  [wizardInstructionNames.description]: descriptionValidationObject,
  brand: dropdownValidation,
});

export const descriptionValidation = Yup.object().shape({
  [descriptionNames.ruleName]: ruleNameValidation,
});

export const linkUploadNames = {
  urls: 'urls',
};

export const linkUploadValidation = Yup.object().shape({
  [linkUploadNames.urls]: linkValidation.required(),
});

export const linkUploadInitialValues = {
  [linkUploadNames.urls]: '',
};

export const linkUploadFields = [
  {
    name: linkUploadNames.urls,
    placeholder: 'https://...',
    size: '_M',
    hasError: true,
    preserveErrorSpace: false,
  },
];

export const formatUpdateValues = _values => {
  const values = cloneDeep(_values);
  const savedCategory = cloneDeep(values[descriptionNames.productCategory]);
  values[descriptionNames.productCategory] =
    values[descriptionNames.productCategory].value;
  values.savedCategory = savedCategory;
  return values;
};

export const formatInitialValues = _values => {
  const values = cloneDeep(_values);
  values[descriptionNames.productCategory] = values.savedCategory;
  return values;
};

export const attachmentUploadDescription =
  'Add more attachments to this knowledge base';

export const linkUploadDescription = 'Add more links to this knowledge base';

export const withURLBase = url => `//${url.replace(re.urlProtocol, '')}`;

export const getCharacteristicInitialValues = details => {
  const initialValues = {};
  details.forEach((detail, index) => (initialValues[index] = detail));
  return initialValues;
};

export const getCharacteristicValidationSchema = details => {
  const validation = {};
  details.forEach(
    (_, index) => (validation[index] = Yup.string().notRequired())
  );
  return Yup.object().shape(validation);
};

export const authenticityBadgeValues = {
  ['non-counterfeit']: 'Non-counterfeit',
  counterfeit: 'Counterfeit',
};

export const authenticityBadgeLabels = {
  [authenticityBadgeValues['non-counterfeit']]: 'Non-counterfeit',
  [authenticityBadgeValues.counterfeit]: 'Counterfeit',
};

export const authenticityBadgeIcons = {
  [authenticityBadgeValues['non-counterfeit']]: Icons.CheckCircle,
  [authenticityBadgeValues.counterfeit]: Icons.AlertCircle,
};

export const authenticityBadgeMenuOptions = [
  {
    label: authenticityBadgeLabels[authenticityBadgeValues['non-counterfeit']],
    value: authenticityBadgeValues['non-counterfeit'],
  },
  {
    label: authenticityBadgeLabels[authenticityBadgeValues.counterfeit],
    value: authenticityBadgeValues.counterfeit,
  },
];

export const authenticityBadgeColors = {
  [authenticityBadgeValues['non-counterfeit']]: StatusColors['Non-counterfeit'],
  [authenticityBadgeValues.counterfeit]: StatusColors.Counterfeit,
};

const instructionTypeValues = {
  packaging: 'packaging',
  logo: 'logo',
  labels: 'labels',
  signatureHardware: 'signatureHardware',
  material: 'material',
  stitching: 'stitching',
  patternsAndColor: 'patternsAndColor',
  serialNumbers: 'serialNumbers',
  other: 'other',
};

export const instructionTypeIcons = {
  [instructionTypeValues.packaging]: InstructionTypes.Packaging,
  [instructionTypeValues.logo]: InstructionTypes.Logo,
  [instructionTypeValues.labels]: InstructionTypes.Labels,
  [instructionTypeValues.signatureHardware]: InstructionTypes.SignatureHardware,
  [instructionTypeValues.material]: InstructionTypes.Material,
  [instructionTypeValues.stitching]: InstructionTypes.Stitching,
  [instructionTypeValues.patternsAndColor]: InstructionTypes.PatternsAndColor,
  [instructionTypeValues.serialNumbers]: InstructionTypes.SerialNumbers,
  [instructionTypeValues.other]: InstructionTypes.Other,
};

export const instructionTypeLabels = {
  [instructionTypeValues.packaging]: 'Packaging',
  [instructionTypeValues.logo]: 'Logo',
  [instructionTypeValues.labels]: 'Labels',
  [instructionTypeValues.signatureHardware]: 'Signature hardware',
  [instructionTypeValues.material]: 'Material',
  [instructionTypeValues.stitching]: 'Stitching',
  [instructionTypeValues.patternsAndColor]: 'Patterns and color',
  [instructionTypeValues.serialNumbers]: 'Serial numbers',
  [instructionTypeValues.other]: 'Other',
};

export const instructionTypeDescriptions = {
  [instructionTypeValues.packaging]:
    'Box, dustbag, case, cellophane wrap, etc.',
  [instructionTypeValues.logo]: 'Spelling, spacing, colors, font, symbol, etc.',
  [instructionTypeValues.labels]:
    'Brand tags, authenticity labels, composition, etc.',
  [instructionTypeValues.signatureHardware]:
    'Zippers, buttons, clasps, screws,  buckles, etc.',
  [instructionTypeValues.material]:
    'Type, quality, smell, colors, hand feel, etc.',
  [instructionTypeValues.stitching]:
    'Quality, colors, materials, stitching patterns, etc.',
  [instructionTypeValues.patternsAndColor]:
    'Pattern design, direction, rotation, colors, etc.',
  [instructionTypeValues.serialNumbers]: 'SKUs, serial numbers, barcodes, etc.',
  [instructionTypeValues.other]:
    'All the other distinctive elements of the product.',
};

export const instructionTypes = Object.values(instructionTypeValues).map(
  type => ({
    icon: instructionTypeIcons[type],
    label: instructionTypeLabels[type],
    description: instructionTypeDescriptions[type],
    value: type,
  })
);

export const characteristicInfoIfonDescription = {
  [instructionTypeValues.packaging]:
    'This section shows all your saved information about packaging',
  [instructionTypeValues.logo]:
    'This section shows all your saved information about logos',
  [instructionTypeValues.labels]:
    'This section shows all your saved information about labels',
  [instructionTypeValues.signatureHardware]:
    'This section shows all your saved information about signature hardware',
  [instructionTypeValues.material]:
    'This section shows all your saved information about product materials',
  [instructionTypeValues.stitching]:
    'This section shows all your saved information about stitching',
  [instructionTypeValues.patternsAndColor]:
    'This section shows all your saved information about patterns and color',
  [instructionTypeValues.serialNumbers]:
    'This section shows all your saved information about serial numbers',
  [instructionTypeValues.other]:
    'This section shows all your saved information about all the other distinctive elements of the product',
};

export const setupChoice = {
  wizard: {
    icon: Icons.Wand,
    title: 'Setup wizard',
    text: 'Choose this option to create the knowledge base from scratch with our step-by-step procedure',
    buttonText: 'Use wizard',
  },
  manual: {
    icon: Icons.FilePlus2,
    title: 'Manual setup',
    text: 'Choose this option if you have some pre-made decks, videos or links that you want to add',
    buttonText: 'Set up manually',
  },
};
