import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { request2FAcode } from '../../../../../auth/api/request2FAcode';
import { FormValues } from '../../../../../auth/controllers/variables';
import Modal from '../../../../../components/templates/Modal/Modal';
import { envVars } from '../../../../../constants';
import { useAuth } from '../../../../../providers/AuthProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { Pages } from '../../../../../routes/variables';
import { DevPortal2FALayout } from '../components/DevPortal2FALayout';
const { initialValues, validationSchema } = FormValues[Pages.verify2FA];

const { environment } = envVars;

const ignoredAuthCodes = ['local', 'dev'];

const DevPortal2FA = ({ onSuccess }) => {
  const { dismiss } = useModal();
  const [{ token }] = useAuth();

  const [authCode, setAuthCode] = useState(null);

  const onSubmit = values => () => {
    const code = ignoredAuthCodes.includes(environment)
      ? authCode
      : values.code;
    onSuccess(code);
  };

  useEffect(() => {
    request2FAcode({
      logged_in_token: token,
      authType: 'Email',
    }).then(res => {
      const authCode = res.authCode;
      if (authCode != null) {
        setAuthCode(authCode);
      }
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid, values }) => {
        return (
          <DevPortal2FALayout
            dismiss={dismiss}
            disabled={!isValid}
            onSubmit={onSubmit(values)}
          />
        );
      }}
    </Formik>
  );
};
export default Modal(DevPortal2FA);
