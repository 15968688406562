import usePostData from '../../../../../hooks/postHook';

export const useUpdateProductStatus = ({ id }) => {
  const [{ data, body, isLoading, isError, error }, doProductStatusUpdate] =
    usePostData('feed url from options');

  const statusUpdate = ({ status, ...rest }) => {
    const body = { status, product_ids: [id] };
    const url = 'office_products/status';
    doProductStatusUpdate({ ...body, ...rest }, url);
  };

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doProductStatusUpdate: statusUpdate,
  };
};
