import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import {
  formatCategories,
  inactiveCategories,
} from '../formatters/formatCategories';

export const useGetCategories = ({ searchParams, inactiveBrand, ignore }) => {
  const [{ data = [], isLoading, isError, error }, _, __, refresh] =
    useFetchData('dashboard/category_rank', {
      formatter: formatCategories,
      params: formatParams(searchParams),
      ignore,
    });

  return {
    data: inactiveBrand || ignore ? inactiveCategories() : data,
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
