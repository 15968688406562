import Button from '../../../../../components/molecules/Button/Button';
import { Label } from '../../../../../components/molecules/Label/Label';
import { Constants, Field_Keys, re } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { formatTickets } from '../../support/api/getTickets';
import {
  actionIcon,
  actionTitles,
  actions,
  getRiskTypes,
} from '../controllers/variables';
import { statusStepsHistory } from '../variables';

const { fallback } = Constants;

export const useGetProductDetails = ({
  productId,
  actionFunctions,
  hasTicketCreatePermission,
}) => {
  const [
    { data = { images: [] }, isLoading = !!productId, isError, error },
    _,
    getProductDetails,
    refetchData,
  ] = useFetchData(`product/${productId}`, {
    formatter: formatter(actionFunctions, hasTicketCreatePermission),
    ignore: !productId,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    getProductDetails: productId =>
      getProductDetails({ url: `product/${productId}` }),
    refetchData,
  };
};

export const formatter =
  (actionFunctions, hasTicketCreatePermission) => data => {
    const {
      [Field_Keys.PRODUCT.ACtorS]: actors,
      [Field_Keys.PRODUCT.ATTACHMENTS]: attachments,
      [Field_Keys.PRODUCT.ALIAS]: productAlias,
      [Field_Keys.PRODUCT.STATUS]: status,
      [Field_Keys.PRODUCT.NOTES]: productNotes,

      [Field_Keys.PARCEL.ID]: parcelId,
      [Field_Keys.PARCEL.ALIAS]: caseAlias,
      //   // pdMatchType: matchType,
      [Field_Keys.PARCEL.SHOULD_SHOW_ACTORS]: should_show_actors,
      // summary response
      [Field_Keys.PRODUCT.ACTIVITY]: activityResponse,
      [Field_Keys.PRODUCT.ID]: productId,
      [Field_Keys.PRODUCT.BRAND_ID]: brandId,
      [Field_Keys.SORTER.CITY]: city,
      [Field_Keys.SORTER.COUNTRY]: country,
      [Field_Keys.SORTER.NAME]: sorter,
      [Field_Keys.CATEGORY.NAME]: category,
      [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: subcategory,
      [Field_Keys.PRODUCT.ITEM_QUANTITY]: quantity,
      [Field_Keys.PRODUCT.IS_PRODUCT_OWNER]: isProductOwner,
      [Field_Keys.PARCEL.RISK_TYPE]: riskType_,
      // // pdMatchType: matchType,
      [Field_Keys.PRODUCT.PHOTOS]: photos,
      [Field_Keys.PRODUCT.BRAND_NAME]: brandName,
      [Field_Keys.SORTER.ID]: sorterId,
      [Field_Keys.PRODUCT.TICKETS]: tickets_,
    } = data;

    const notes = formatNotes(productNotes);

    const shouldBlurActors = actors == null || !should_show_actors;

    const activity = formatActivity(activityResponse);

    const productDetails = formatProductDetails({
      [productKeys.sorterCity]: city,
      [productKeys.sorterCountry]: country,
      [productKeys.category]: category,
      [productKeys.subCategory]: subcategory,
      [productKeys.sorterName]: sorter,
      [productKeys.productQuantity]: quantity,
      [productKeys.brandName]: brandName,
    });

    const tickets = formatTickets(tickets_);

    return {
      [productKeys.brandId]: brandId,
      [productKeys.actions]: actionsProps({
        actionStatus: status,
        actionFunctions,
        isProductOwner,
        hasTicketCreatePermission,
      }),
      [productKeys.activity]: activity,
      [productKeys.productId]: productId,
      [productKeys.productDetails]: productDetails,
      [productKeys.photos]: photos,
      [productKeys.isProductOwner]: isProductOwner,
      [productKeys.riskType]: getRiskTypes(riskType_),
      [productKeys.productStatus]: status,
      [productKeys.tickets]: tickets,
      [productKeys.sorterId]: sorterId,
      id: productId,
      [caseKeys.id]: parcelId,
      [caseKeys.alias]: caseAlias,
      [productKeys.attachments]: formatAttachments(attachments),
      [productKeys.productAlias]: productAlias,
      [productKeys.notes.self]: notes,
      [productKeys.shouldBlurActors]: shouldBlurActors,
      [productKeys.steps]: statusStepsHistory[status],
    };
  };

const formatNotes = notes => {
  maskEntry(notes, {
    [Field_Keys.PRODUCT.NOTE]: productKeys.notes.note,
    [Field_Keys.PRODUCT.NOTE_CREATING_USER_DISPLAY_NAME]:
      productKeys.notes.userName,
    [Field_Keys.PRODUCT.NOTE_CREATING_USER_IMAGE]: productKeys.notes.userImage,
    [Field_Keys.PRODUCT.NOTE_CREATED_AT]: productKeys.notes.dateCreated,
  });
  return notes;
};

export const actionsProps = ({
  actionStatus,
  actionFunctions,
  isProductOwner,
  hasTicketCreatePermission,
}) => {
  if (!actionFunctions) return;
  if (!isProductOwner) return [];

  const text = actionTitles[actionStatus];
  const onClick = actionFunctions[actionStatus];
  const icon = actionIcon[actionStatus];
  switch (actionStatus) {
    case Constants.Status.Inspection:
    case Constants.Status.Counterfeit:
    case Constants.Status['Non-counterfeit']:
    case Constants.Status.Stopped:
    case Constants.Status.Seized:
    case Constants.Status.Released:
      return [
        {
          Component: Label,
          props: {
            text: text,
            rightIcon: icon,
            size: '_S',
            hasBorder: true,
            key: 'label',
          },
        },
      ];
    case Constants.Status.Authentication:
      return [
        {
          Component: Button,
          props: {
            text: text[actions.authentication],
            onClick: onClick[actions.authentication],
            size: '_S',
            variant: 'Primary',
            key: 'Primary',
          },
        },
      ];

    default:
      throw Error();
  }
};

export const formatActivity = activityResponse => {
  return (activityResponse ?? []).map(entry => {
    const {
      [[Field_Keys.PRODUCT.LOG_CREATED_AT]]: dateResponse,
      [Field_Keys.PRODUCT.LOG_CONTENT]: text,
    } = entry;
    const date = `${formatDate(dateResponse, DateTypes.date)}, ${formatDate(
      dateResponse,
      DateTypes.time12
    )}`;
    return {
      text: text,
      date,
    };
  });
};

export const formatProductDetails = ({
  [productKeys.sorterCity]: city,
  [productKeys.sorterCountry]: country,
  [productKeys.category]: category,
  [productKeys.subCategory]: subcategory,
  [productKeys.sorterName]: sorter,
  [productKeys.productQuantity]: quantity,
  [productKeys.brandName]: brandName,
}) => {
  const location = joinStrings(city, country) ?? Constants.fallback;
  const productCategory =
    joinStrings(category, subcategory, Constants.fallback) ??
    Constants.fallback;
  return [
    {
      title: 'Location: ',
      text: location,
    },
    {
      title: 'Sortation center: ',
      text: sorter,
    },
    {
      title: 'Brand: ',
      text: brandName,
    },
    {
      title: 'Product category: ',
      text: productCategory,
    },
    {
      title: 'Item quantity: ',
      text: quantity,
    },
  ];
};

const formatAttachments = attachments => {
  maskEntry(attachments, attachmentsMask);

  return attachments.map(attachment => {
    const url = attachment[productKeys.attachmentURL];
    const it = {
      ...attachment,
      [productKeys.attachmentURL]: url,
      [productKeys.attachmentName]: extractNameFromStorageURL(url),
    };

    return it;
  });
};

const extractNameFromStorageURL = name => {
  try {
    const [uri, storageFileName] = name.split('case_files/');
    const [uuidName, storageSignature] =
      storageFileName.split('?X-Goog-Algorithm=');

    const fileName = uuidName.replaceAll(re.uuid, '').replace('_.', '.');

    return decodeURIComponent(fileName);
  } catch {
    return name;
  }
};

export const attachmentsMask = {
  // avoid collision with cases table
  [Field_Keys.ATTACHMENT.ID]: productKeys.attachmentId,
  [Field_Keys.ATTACHMENT.NAME]: productKeys.attachmentURL,
};
