import { Field_Keys } from '../../constants';
import { getAlias } from '../../utils/utils';

const formatter = ([{ photos }]) => {
  const images = photos.map(imageObject => imageObject[Field_Keys.PHOTO.IMAGE]);
  const thumbnails = photos.map(
    imageObject => imageObject[Field_Keys.PHOTO.THUMBNAIL]
  );

  return { images, thumbnails };
};

export const GalleryPages = {
  parcels: 'parcels',
  products: 'products',
  knowledge_base: 'knowledge_base',
};

export const GalleryProps = {
  [GalleryPages.parcels]: {
    url: 'office_parcels/images',
    idsKey: 'parcel_ids',
    galleryAliasExtractor: getAlias,
    formatter,
  },
  [GalleryPages.products]: {
    url: 'office_products/images',
    idsKey: 'product_ids',
    galleryAliasExtractor: getAlias,
    formatter,
  },
  [GalleryPages.knowledge_base]: ({ instructionId, characteristicId }) => ({
    url: `office_knowledge_base/${instructionId}/characteristic/${characteristicId}/images`,
    idsKey: 'unnecessary',
    galleryAliasExtractor: getAlias,
    formatter,
  }),
};
