import { matchImagesToRows } from '../../../../../components/organisms/Table/utils';
import { parcelKeys } from '../../../../admin/features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const matchImagesToProduct = matchImagesToRows(
  productKeys.productId,
  'product_id'
);

export const matchImagesToCases = matchImagesToRows(
  [parcelKeys.id],
  'parcel_id'
);
