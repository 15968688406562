import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const headers = {
  [caseKeys.statusIndicator]: '',
  [caseKeys.expansionArrow]: '',
  [caseKeys.image]: 'Parcel ID',
  [caseKeys.alias]: '',
  [caseKeys.sideloadDate]: 'Catch date',
  [caseKeys.sorter]: 'Location',
  [caseKeys.trackingNumber]: 'Tracking number',
  [caseKeys.riskType]: 'Risk type',
  [caseKeys.itemQuantity]: 'Items',
  [caseKeys.status]: 'Parcel status',
  [caseKeys.statusShares]: 'Product status',
  [caseKeys.productQuantity]: 'Products',
  [caseKeys.optionsMenu]: '',
};

export const expansionHeaders = {
  [productKeys.leftPadding]: '',
  [productKeys.productImage]: 'Product ID',
  [productKeys.productAlias]: '',
  [productKeys.brand]: 'Brand',
  [productKeys.productName]: 'Product category',
  [productKeys.productQuantity]: 'Items',
  [productKeys.productStatus]: 'Status',
  [productKeys.lastActivity]: 'Last activity',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
  // status indicator //
  16px
  // expansion arrow //
  16px
  // parcel image //
  48px
  // parcel alias //
  150px
  //  catch date //
  minmax(90px, 0.5fr)
  //  location //
  minmax(84px, 0.5fr)
  // tracking number //
  minmax(72px, 195px) 
  //  risk type //
  minmax(84px, 0.35fr)
  // item count //
  minmax(52px, 80px)
  // parcel status //
  144px
  // status shares //
  minmax(96px, 144px)
  // product count //
  40px
  // left padding //
  56px
  `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const expansionGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px 
  // image //
  60px 
  // product alias //
  minmax(124px , 0.8fr)
  // brand //
  minmax(124px , 0.7fr)
  // product category //
  minmax(136px , 1fr)
  //  quantity //
  minmax(52px , 0.5fr)
  // status //
  minmax(112px , 0.75fr)
  // last activity //
  minmax(72px , 0.5fr) `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
  headerHeight: '40px',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  BRAND: 'BRAND',
  LOCATION: 'LOCATION',
  PARCEL_STATUS: 'PARCEL_STATUS',
  RISK_TYPE: 'RISK_TYPE',
  OWN: 'OWN',
  OTHERS: 'OTHERS',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
  },
  [DC.BRAND]: {
    type: FilterTypes.dropdown,
    icon: Icons.Archive,
  },
  [DC.LOCATION]: {
    type: FilterTypes.dropdown,
    icon: Icons.MapPin,
  },
  [DC.RISK_TYPE]: {
    type: FilterTypes.dropdown,
    icon: Icons.AlertCircle,
  },
  [DC.PARCEL_STATUS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Clock,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const initialFilterValues = {
  [DC.LOCATION]: '',
  [DC.BRAND]: '',
  [DC.RISK_TYPE]: '',
  [DC.PARCEL_STATUS]: '',
};

export const titles = {
  [DC.TIME_FRAME]: 'Date',
  [DC.RISK_TYPE]: 'Risk type',
  [DC.PARCEL_STATUS]: 'Status',
  [DC.LOCATION]: 'Location',
  [DC.BRAND]: 'Brand',
};

export const allOptions = {
  [DC.BRAND]: { label: 'All brands', value: Constants.all },
  [DC.LOCATION]: { label: 'All locations', value: Constants.all },
  [DC.RISK_TYPE]: { label: 'All', value: Constants.all },
  [DC.PARCEL_STATUS]: { label: 'All', value: Constants.all },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No parcels',
    description: 'As soon as we catch a new parcel, you will find it here',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    title: 'No results found',
    description: 'Please try another search or view all parcels',
  },
};
