import { formatStatusShares } from '../../../../../components/molecules/StatusShares/utils';
import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { DISPATCH_CONSTANTS, Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { formatterDots } from './getDots';

export const useGetParcels = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { parcels = [], parcel_count: parcelCount = 0 } = {},
      isLoading = true,
      isError,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('office_parcels', {
    params,
    ignore,
    large: true,
  });

  return {
    data: { parcels: formatCases(parcels), parcelCount },
    isLoading,
    isError,
    getProducts,
    refreshTable,
  };
};

const formatCases = data => {
  maskEntry(data, mask);
  return data.map(row => {
    return {
      ...row,
      [parcelKeys.statusShares]: formatStatusShares(
        row[parcelKeys.statusShares]
      ),
      ...formatterDots(row),
    };
  });
};

export const extractSpecialFilters = filters => {
  const others = filters.filter(
    section => section.section === DISPATCH_CONSTANTS.OTHERS
  )[0];
  const options = others.options;
  const selected = options.filter(option => option.selected);
  return selected;
};

export const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    PARCEL_STATUS,
    CONTRACTED: is_contracted_brand,
    RISK_TYPE,
    PAGE,
    ITEMS_PER_PAGE,
    SPECIAL_FILTER,
    BRAND,
    LOCATION,
    ACTOR_DATA,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const status = extractSelectedValues(PARCEL_STATUS);
  const risk_type = extractSelectedValues(RISK_TYPE);
  const sorter_id = extractSelectedValues(LOCATION);
  const brand_id = extractSelectedValues(BRAND);

  const special_filter = extractSelectedValues(SPECIAL_FILTER);
  const actor_data = extractSelectedValues(ACTOR_DATA);

  const formattedParams = {
    search,
    status,
    risk_type,
    sorter_id,
    brand_id,
    from_date,
    to_date,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
    // for use in specialCaseIgnore in fetchHook.js
    // SPECIAL_FILTER,
    special_filter,
    actor_data,
  };

  if (formattedParams?.status?.includes('Stopped'))
    formattedParams?.status?.push('Customs');
  if (
    formattedParams?.status?.includes('Customs') &&
    !formattedParams?.status?.includes('Stopped')
  )
    formattedParams.status = formattedParams?.status?.filter(
      x => x !== 'Customs'
    );

  if (is_contracted_brand) formattedParams.is_contracted_brand = true;
  return formattedParams;
};

const mask = {
  [Field_Keys.PARCEL.ID]: parcelKeys.id,
  [Field_Keys.PARCEL.ALIAS]: parcelKeys.alias,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: parcelKeys.sideloadDate,
  [Field_Keys.PARCEL.STATUS]: parcelKeys.status,
  // "createdAt": "2023-05-04T10:45:01.510Z",
  [Field_Keys.PARCEL.PRODUCT_COUNT]: parcelKeys.product.count,
  [Field_Keys.PARCEL.PRODUCT_STATUS_DISTRIBUTION]: parcelKeys.statusShares,
  [Field_Keys.PARCEL.IS_CHECKED_IN]: parcelKeys.checked,
  [Field_Keys.PARCEL.HAS_RECEIVER_INPUT]: parcelKeys.destination,
  [Field_Keys.PARCEL.HAS_SENDER_INPUT]: parcelKeys.origin,
  [Field_Keys.PARCEL.IS_HOLD_CONFIRMED]: parcelKeys.isHoldConfirmed,
  [Field_Keys.PARCEL.IS_REPORT_CONFIRMED]: parcelKeys.isReportConfirmed,
  [Field_Keys.PARCEL.IS_SEIZE_CONFIRMED]: parcelKeys.isSeizeConfirmed,
  [Field_Keys.PARCEL.IS_STOP_CONFIRMED]: parcelKeys.isStopConfirmed,
  [Field_Keys.PARCEL.SITUATION_STYLE]: parcelKeys.nextStep,

  // "soName": "DPD-Oirschot",
  // "soCity": "Eindhoven",
  // "soID": "12714d0f-4a0e-4b21-8f4c-419129af6f29",
  // "own_quantity": 0,
  // "rest_quantity": 169,
  // "last_activity": "2023-05-04T10:45:02.588Z",
  [Field_Keys.PARCEL.RISK_TYPE]: parcelKeys.riskType,
  [Field_Keys.PARCEL.PRODUCTS]: parcelKeys.product.self,
  [Field_Keys.PARCEL.PRODUCT_IDS]: parcelKeys.product_ids,
  [Field_Keys.PRODUCT.ID]: parcelKeys.product.id,
  [Field_Keys.PRODUCT.ALIAS]: parcelKeys.product.alias,
  [Field_Keys.PRODUCT.STATUS]: parcelKeys.product.status,
  [Field_Keys.PRODUCT.BRAND_NAME]: parcelKeys.product.brand,
  [Field_Keys.PRODUCT.IS_BRAND_CONTRACTED]: parcelKeys.product.isContracted,
  [Field_Keys.CATEGORY.NAME]: parcelKeys.product.category,
  [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: parcelKeys.product.subCategory,
  [Field_Keys.PRODUCT.ITEM_QUANTITY]: parcelKeys.product.itemQuantity,
  [Field_Keys.PRODUCT.STATUS_UPDATED_AT]: parcelKeys.product.lastActivity,
};
