import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { CheckBox } from '../../../../../components/molecules';
import Button from '../../../../../components/molecules/Button/Button';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { getTooltipKey } from '../../../../../components/molecules/Tooltip/utils';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import { Colors } from '../../../../../components/style';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Constants, Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { cloneDeep, isEmpty } from '../../../../../lib/js';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { openNewTab, parseStringModifiers } from '../../../../../utils/utils';
import { GalleryPages, GalleryProps } from '../../../constants';
import ParcelSummary from '../../../features_public/parcels/controllers/ParcelSummary';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { useGetProductsImage } from '../../../features_public/products/api/getProductsImage';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { formatCheckedInTooltipContent, useGetDots } from '../api/getDots';
import { useGetFilters } from '../api/getFilters';
import { useGetParcels } from '../api/getParcels';
import { useGetParcelsImages } from '../api/getParcelsImages';
import { useUpdateParcelsStatusBulk } from '../api/updateParcelsStatusBulk';
import ParcelsLayout from '../components/pages/ParcelsLayout';
import { useParcelsReducer } from './Parcels.hook';
import {
  getParcelStatusActions,
  matchImagesToParcels,
  matchImagesToProduct,
  sendsEmail,
} from './utils';
import {
  DC,
  allOptions,
  expansionGrid,
  expansionHeaders,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  noResultsState,
  parcelActions as parcelActions_,
  statusActionPermission,
  titles,
} from './variables';

const {
  SELF: OFFICE_PARCELS,
  VIEW_PARCELS,
  EDIT_PARCEL_STATUS_INSPECTION,
  EDIT_PARCEL_STATUS_RELEASED,
  EDIT_PARCEL_STATUS_SEIZED,
  EDIT_PARCEL_STATUS_SIDELOAD,
  EDIT_PARCEL_STATUS_STOPPED,
} = PermissionKeys.Admin.OFFICE_PARCELS;

const { SELF: OFFICE_PRODUCTS, VIEW_PRODUCTS } =
  PermissionKeys.Admin.OFFICE_PRODUCTS;

const Parcels = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [checkedRows, setCheckedRows] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission } = usePermissions();

  const hasViewPermission = hasPermission(OFFICE_PARCELS, VIEW_PARCELS);
  const hasProductViewPermission = hasPermission(
    OFFICE_PRODUCTS,
    VIEW_PRODUCTS
  );
  const hasStatusEditPermission = hasPermission(OFFICE_PARCELS, [
    EDIT_PARCEL_STATUS_INSPECTION,
    EDIT_PARCEL_STATUS_RELEASED,
    EDIT_PARCEL_STATUS_SEIZED,
    EDIT_PARCEL_STATUS_SIDELOAD,
    EDIT_PARCEL_STATUS_STOPPED,
  ]);

  const searchParamsRef = useRef(null);
  const resetFormRef = useRef(null);

  const ownOptions = undefined;

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useParcelsReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  // mark all options with as selected
  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { parcels, parcelCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetParcels({
    searchParams,
    ignore: ignoreDataFetch,
  });

  const {
    data: images,
    isLoading: imagesIsLoading,
    isError: imagesIsError,
    error: imagesError,
    getParcelsImages,
  } = useGetParcelsImages();

  const {
    data: productImages,
    isLoading: productImagesIsLoading,
    isError: productImagesIsError,
    error: productImagesError,
    getProductsImage,
  } = useGetProductsImage();

  const {
    data: nextStepDot,
    isLoading: nextStepDotIsLoading,
    isError: nextStepDotIsError,
    getDots,
  } = useGetDots();

  const {
    body: updateParcelBody,
    isLoading: updateParcelIsLoading,
    isError: updateParcelIsError,
    error: updateParcelError,
    doUpdate: doUpdateParcelStatus,
  } = useUpdateParcelsStatusBulk();

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;

  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.parcels];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isLoading || isError) return;
    setData(parcels);
    if (parcels?.length) {
      getParcelsImages({ parcels });
      const parcel_ids = parcels.map(parcel => parcel[parcelKeys.id]);
      getDots({ parcel_ids });
    }
  }, [parcels, isLoading, isError]);

  useDeepCompareEffect(() => {
    if (imagesIsLoading) return;
    if (
      imagesIsError ||
      // !hasPermission(VIEW_PARCEL_PHOTOS)
      false
    ) {
      if (imagesIsError) showSnackbarError(imagesError);
      setData(matchImagesToParcels([]));
      return;
    }

    if (parcels?.length && images != null)
      setData(matchImagesToParcels(images));
  }, [images, parcels]);

  useDeepCompareEffect(() => {
    if (productImagesIsError) {
      if (productImagesIsError) showSnackbarError(productImagesError);

      const setPhotosToAvoidPulse = data => {
        if (data.length === 0 || selectedRow < 0) return data;
        const newData = cloneDeep(data);
        newData[selectedRow][parcelKeys.product.self] = matchImagesToProduct(
          []
        )(newData[selectedRow]?.[parcelKeys.product.self] ?? []);
        return newData;
      };

      setData(setPhotosToAvoidPulse);
      return;
    }

    const withMatchedImages = data => {
      const newData = cloneDeep(data);

      newData[selectedRow][parcelKeys.product.self] = matchImagesToProduct(
        productImages
      )(newData[selectedRow][parcelKeys.product.self]);
      return newData;
    };
    if (parcels?.length && productImages != null) setData(withMatchedImages);
  }, [productImages]);

  useEffect(() => {
    if (nextStepDot == null || isEmpty(nextStepDot)) return;
    const newData = data.map(row => {
      const id = row[parcelKeys.id];
      const rowDot = nextStepDot[id];
      return {
        ...row,
        [parcelKeys.nextStep]: rowDot?.[parcelKeys.nextStep],
        [getTooltipKey(parcelKeys.nextStep)]: {
          content: formatCheckedInTooltipContent({ ...row, ...rowDot }),
        },
      };
    });

    setData(newData);
  }, [nextStepDot]);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  useEffect(() => {
    if (updateParcelIsLoading || updateParcelBody == null) return;
    if (updateParcelIsError) {
      showSnackbarError(updateParcelError);
      showSnackbarSuccess(
        `Parcels ${Constants.StatusPastTense[
          updateParcelBody.status
        ].toLowerCase()} successfully`
      );
      return;
    }
    refreshTable();
  }, [updateParcelBody]);

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  // keep track, no need to call every time if it's already called
  const getItemImages = (item, clickedRow, _, willExpand) => {
    if (!willExpand) return;

    setSelectedRow(clickedRow);
    getProductsImage({
      product_ids: item[parcelKeys.product_ids],
      limit: Constants.noLimit,
    });
  };

  const showGallery =
    ({ item, initialSelected }) =>
    e => {
      e.stopPropagation();
      showModal(Gallery, {
        ...GalleryProps[GalleryPages.parcels],
        id: item[parcelKeys.id],
        initialSelected,
        title: `Parcel ${item[parcelKeys.alias] ?? ''}`,
      });
    };

  const showProductGallery =
    ({ item, initialSelected }) =>
    e => {
      e.stopPropagation();
      showModal(Gallery, {
        ...GalleryProps[GalleryPages.products],
        id: item[parcelKeys.product.id],
        initialSelected,
        title: `Product ${item[parcelKeys.product.alias] ?? ''}`,
      });
    };

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    options,
    ownOptions,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    allOptions,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.BRAND]: dropdown(DC.BRAND),
    [DC.LOCATION]: dropdown(DC.LOCATION),
    [DC.SPECIAL_FILTER]: dropdown(DC.SPECIAL_FILTER),
    [DC.RISK_TYPE]: dropdown(DC.RISK_TYPE),
    [DC.ACTOR_DATA]: dropdown(DC.ACTOR_DATA),
    [DC.PARCEL_STATUS]: dropdown(DC.PARCEL_STATUS),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const customStyles = ` 
        .${DC.PARCEL_STATUS} {
            .options-hoverable {
              min-width: 210px;
            }
        }
      `;

      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.parcels]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          customStyles={customStyles}
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  const setFooterPagination = () => {
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
      options: [25, 50, 100, 500],
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  };

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    setFooterPagination();
  }, [isLoading, searchParams]);

  const doParcelAction =
    (status, confirm = true) =>
    () => {
      const action = () => {
        const parcel_ids = checkedRows.map(i => data[i][parcelKeys.id]);
        const body = { status, parcel_ids };
        doUpdateParcelStatus(body);
        setCheckedRows([]);
        dismiss();
      };

      const title = `${parcelActions[status].text} parcels`;
      const description = parseStringModifiers(
        `Are you sure you want to **${parcelActions[
          status
        ].text.toLowerCase()} ${checkedRows.length} parcel${
          checkedRows.length > 1 ? 's' : ''
        }?**`
      );
      const alertText = sendsEmail[status]
        ? 'The customer(s) will receive an email after confirmation.'
        : null;
      const onConfirm = action;
      const confirmText = parcelActions[status].text;
      const onCancel = dismiss;

      if (confirm)
        showModal(ConfirmModal, {
          title,
          description,
          alertText,
          onConfirm,
          confirmText,
          onCancel,
        });

      if (!confirm) action();
    };

  const parcelActions = parcelActions_(doParcelAction);

  useEffect(() => {
    if (!hasViewPermission) return;
    if (!checkedRows.length) setFooterPagination();
    else {
      const statusActions = getParcelStatusActions(
        checkedRows.map(i => data[i][parcelKeys.status])
      ).filter(status =>
        hasPermission(OFFICE_PARCELS, statusActionPermission[status])
      );

      const footerChildren = (
        <Flex gap="_S">
          {statusActions.map((status, i) => (
            <Button
              key={i}
              {...parcelActions[status]}
              variant="Tertiary"
              size="_S"
            />
          ))}
        </Flex>
      );

      setFooterChildren(footerChildren);
    }
  }, [checkedRows]);

  const rowAction = item => {
    showModal(ParcelSummary, {
      parcel: item,
    });
  };

  const expansionRowAction = item => {
    const { [parcelKeys.product.id]: id } = item;
    const to = `${NavURIs[Pages.products]}/${id}`;

    openNewTab(to);
  };

  const navigateToProduct = item => () => {
    const { [parcelKeys.alias]: alias } = item;
    const to = NavURIs[Pages.products] + `/?search=${alias}`;
    openNewTab(to);
  };

  const openParcelSummary = initialTab => item => {
    showModal(ParcelSummary, {
      parcel: item,
      initialTab,
    });
  };

  const toggleRowChecked = (_, index) => () => e => {
    e.stopPropagation();
    setCheckedRows(checked => {
      if (checked.includes(index)) return checked.filter(i => i !== index);
      else return [...checked, index];
    });
  };

  const toggleAllRows = () => () => {
    setCheckedRows(checked => {
      if (checked.length === data.length) return [];
      else return [...Array(data.length).keys()];
    });
  };

  const withToggleAllRows = headers => {
    const selected = checkedRows.length === data.length;
    return {
      ...headers,
      [parcelKeys.selected]: hasStatusEditPermission ? (
        <div style={{ color: selected ? Colors.Neutral._900 : 'inherit' }}>
          <CheckBox selected={selected} setSelected={toggleAllRows} size="_S" />
        </div>
      ) : (
        <></>
      ),
    };
  };

  const noDataAction = undefined;

  const avoidSingleExpand = data?.[0]?.[parcelKeys.product.count] === 0;

  const expandCountKey = parcelKeys.product.self;

  return (
    <ParcelsLayout
      headers={withToggleAllRows(headers)}
      data={data}
      grid={grid}
      rowAction={rowAction}
      checkedRows={checkedRows}
      isError={isError}
      error={error}
      isLoading={isLoading}
      onRowExpand={getItemImages}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
      tableComponentsProps={{
        hasProductViewPermission,
        hasStatusEditPermission,
        navigateToProduct,
        showGallery,
        showProductGallery,
        toggleRowChecked,
        checkedRows,
        expansionHeaders,
        expansionGrid,
        expansionRowAction,
        openParcelSummary,
        expandCountKey,
      }}
      expandCountKey={expandCountKey}
      avoidSingleExpand={avoidSingleExpand}
    />
  );
};

export default Parcels;
