import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import { useModal } from '../../../../../providers/ModalProvider';
import { GalleryPages, GalleryProps } from '../../../../admin/constants';
import { CaseInfoLayout } from '../components/CaseInfoLayout';
import {
  parcelGrid,
  parcelHeaders,
  processingKeys,
  productGrid,
  productHeaders,
} from './variables';

const CaseInfo = ({ parcel, editProduct, goToAddProduct, disabled }) => {
  const { showModal } = useModal();
  const showGallery =
    ({ item, initialSelected }) =>
    () => {
      showModal(
        Gallery,
        {
          id: item[processingKeys.id],
          initialSelected,
          ...GalleryProps.parcels,
          title: `Parcel ${parcel[processingKeys.alias]}`,
        },
        { keepPrev: true }
      );
    };

  const showProductGallery =
    ({ item, initialSelected }) =>
    () => {
      showModal(
        Gallery,
        {
          id: item[processingKeys.product.id],
          initialSelected,
          ...GalleryProps[GalleryPages.products],
          title: `Product ${item[processingKeys.product.alias]}`,
        },
        { keepPrev: true }
      );
    };

  return (
    <CaseInfoLayout
      headers={parcelHeaders}
      data={[parcel]}
      grid={parcelGrid}
      showGallery={showGallery}
      showProductGallery={showProductGallery}
      subHeaders={productHeaders}
      subData={parcel[processingKeys.products] ?? []}
      subGrid={productGrid}
      editProduct={editProduct}
      goToAddProduct={goToAddProduct}
      productQuantity={parcel[processingKeys.productCount]}
      disabled={disabled}
    />
  );
};
export default CaseInfo;
