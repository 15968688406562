import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';
import { NavURIs, Pages } from '../../../routes/variables';
import { useGetReferenceDetails } from '../api/getReferenceDetails';
import { ReferenceDetailsLayout } from '../components/ReferenceDetailsLayout';

const ReferenceDetails = ({ isAdmin = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { referenceId } = useParams();
  const { showSnackbarError } = useSnackbar();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();

  const { data, isLoading, isError, error, refetchReference } =
    useGetReferenceDetails({
      [referenceIndexKeys.id]: referenceId,
      isAdmin,
    });

  useEffect(() => {
    const headerChildren = (
      <ViewTitle
        text={<CopyableText text={data[referenceIndexKeys.alias]} />}
      />
    );

    setHeaderChildren(headerChildren);
  }, [data[referenceIndexKeys.name]]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  useEffect(() => {
    if (!isError) return;
    showSnackbarError(error);
    goBackTo();
  }, [isLoading, isError]);

  const goBackTo = () => {
    const { searchParams, from } = location.state ?? {};
    const hasPreviousState = location.key !== 'default';

    const to = from
      ? from
      : hasPreviousState
      ? -1
      : NavURIs[Pages.reference_index];
    const state = { searchParams };
    navigate(to, { state });
  };

  const onEditSuccess = () => {
    refetchReference();
  };

  return (
    <ReferenceDetailsLayout
      isAdmin={isAdmin}
      onEditSuccess={onEditSuccess}
      goBackTo={goBackTo}
      header={data[referenceIndexKeys.alias]}
      metrics={data.metrics ?? []}
      date={data[referenceIndexKeys.lastSideload]}
      active={data[referenceIndexKeys.active]}
      referenceInfo={data}
      referenceId={referenceId}
      notes={data[referenceIndexKeys.notes.self]}
      onSuccessCreateNote={refetchReference}
      isLoading={isLoading}
    />
  );
};
export default ReferenceDetails;
