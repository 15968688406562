import { Colors } from '../../../../../components/style';
import { Constants, Field_Keys } from '../../../../../constants';
import { isArray } from '../../../../../lib/js';
import { magnitudeFormatter } from '../../../../../utils/stringUtils';
import { ignoreNaNs, maskEntry } from '../../../../../utils/utils';
import { dashboardKeys } from '../variables';

export const formatCategories = data => {
  if (data == null || !data.length) return inactiveCategories();
  try {
    maskEntry(data, mask);

    const domainMax = getDomainMax(data);

    data = data.map(category => {
      let { count, productCategory = Constants.fallback } = category;
      if (productCategory === 'null' || productCategory === 'undefined')
        productCategory = Constants.fallback;

      const percentage = calculatePercentageBarWidth(count, domainMax);

      return {
        value: magnitudeFormatter(count),
        percentage,
        label: productCategory,
        category,
        color: Colors.Primary._600,
      };
    });

    return data;
  } catch {
    return inactiveCategories();
  }
};

const mask = {
  [Field_Keys.SORTER.NAME]: dashboardKeys.sorter,
};

const calculatePercentageBarWidth = (count, domainMax) => {
  const minPrct = 8.5;
  const remainingPrct = 100 - minPrct;
  const percentage = Math.round((count / domainMax) * remainingPrct);

  return minPrct + percentage;
};

const getDomainMax = data => {
  data = isArray(data) ? data.slice(0, 5) : [];

  let max = 0;

  data.forEach(entry => {
    max = Math.max(max, ...ignoreNaNs(entry));
  });

  const domainMax = max * 1.2;

  return domainMax;
};

export const inactiveCategories = () => {
  const value = Constants.fallback;
  const labels = [
    'Various clothing',
    'Pullovers',
    'Jackets',
    'Shoes',
    'Leather goods',
    'Accessories',
  ];
  const data = labels.map((label, index) => ({
    value,
    percentage: 90 - 10 * index,
    label,
    color: Colors.Neutral._200,
    mock: true,
  }));

  return data;
};
