import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { adminSortersKeys as keys } from '../controllers/variables';

export const useGetAdminSorters = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { sorters = [], sorter_count: sorterCount = [] } = {},
      isLoading = true,
      isError,
    },
    _,
    __,
    refreshTable,
  ] = useFetchData(`admin_sorters`, {
    params,
    ignore,
  });

  return {
    data: { sorters: formatter(sorters), sorterCount },
    isLoading,
    isError,
    refreshTable,
  };
};

const formatter = data => {
  maskEntry(data, mask);
  return data;
};

export const formatParams = params => {
  const { SEARCH: search, PAGE, ITEMS_PER_PAGE } = params;

  const formattedParams = {
    search,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

const mask = {
  [Field_Keys.SORTER.ID]: keys.id,
  [Field_Keys.SORTER.NAME]: keys.name,
  [Field_Keys.SORTER.CITY]: keys.city,
  [Field_Keys.SORTER.COUNTRY]: keys.country,
  [Field_Keys.SORTER.BRAND_ID]: keys.brand.id,
  [Field_Keys.SORTER.BRAND_NAME]: keys.brand.name,
  [Field_Keys.SORTER.BRAND_IMAGE]: keys.brand.image,
  [Field_Keys.SORTER.IS_BRAND_CONTRACTED]: keys.brand.isContracted,
  [Field_Keys.BRAND.REP_NAME]: keys.responsible.name,
  [Field_Keys.BRAND.REP_IMAGE]: keys.responsible.image,
  [Field_Keys.BRAND.REP_USER_ID]: keys.responsible.id,
};
