import { Icon } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Actions from '../../../../../components/organisms/Actions/Actions';
import { Alert } from '../../../../../components/templates/Alert/Alert';
import { Popup } from '../../../../../components/templates/Popup';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { Form } from './organisms';
import { DevPortal2FAContainer } from './style';

export const DevPortal2FALayout = ({
  dismiss,
  disabled,
  onSubmit,
  codeName,
}) => {
  return (
    <DevPortal2FAContainer>
      <Popup
        size={'_S'}
        title={'Two Factor Authentication'}
        element={
          <div onClick={dismiss} style={{ cursor: 'pointer' }}>
            <Icon name={Icons.X} />
          </div>
        }
      >
        <Content>
          <Flex column gap="_S" w>
            <div />
            <Form />
            <Alert
              text={
                'You are trying to access sensitive data which requires two factor authentication. Enter the 2FA code that you received via email.'
              }
              iconSize="_L"
            />
            <Actions
              onCancel={dismiss}
              primaryText={'Verify'}
              onPrimary={onSubmit}
              disabled={disabled}
            />
          </Flex>
        </Content>
      </Popup>
    </DevPortal2FAContainer>
  );
};
