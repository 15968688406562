import usePostData from '../../../../../hooks/postHook';
import { referenceIndexKeys } from '../controllers/variables';

export const useCreateNote = isAdmin => {
  const endpointBase = `${isAdmin ? 'admin_' : ''}reference`;

  const useCreate = ({ [referenceIndexKeys.id]: referenceId }) => {
    const endpoint = `${endpointBase}/${referenceId}/notes`;

    const [{ data, body, isLoading, isError, error }, doCreateNote] =
      usePostData(endpoint);

    return {
      data,
      body,
      isLoading,
      isError,
      error,
      doCreateNote,
    };
  };

  return useCreate;
};
