import * as Yup from 'yup';
import InfoIcon from '../../../../../components/molecules/InfoIcon/InfoIcon';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { DISPATCH_CONSTANTS, PropKeys } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { referenceIndexKeys as referenceIndexKeys_ } from '../../../../brand/features_public/reference_index/controllers/variables';

export const parcelKeys = {
  leftPadding: 'leftPadding',
  action: 'action',
  alias: 'alias',
  authorityDetails: 'authorityDetails',
  authorityId: 'authorityId',
  barcode: 'barcode',
  barcodes: 'barcodes',
  barcodesAdding: 'barcodesAdding',
  barcodesRemoving: 'barcodesRemoving',
  caseCount: 'caseCount',
  checked: 'checked',
  confidence: 'confidence',
  destination: 'destination',
  expansionArrow: 'expansionArrow',
  id: 'id',
  indicator: 'indicator',
  isHoldConfirmed: 'isHoldConfirmed',
  isReportConfirmed: 'isReportConfirmed',
  isSeizeConfirmed: 'isSeizeConfirmed',
  isStopConfirmed: 'isStopConfirmed',
  nextStep: 'nextStep',
  optionsMenu: 'optionsMenu',
  origin: 'origin',
  status: 'status',
  photos: 'photos',
  reason: 'reason',
  riskType: 'riskType',
  riskAnalysis: 'riskAnalysis',
  sideloadDate: 'sideloadDate',
  selected: 'selected',
  sorterId: PropKeys.sorterId,
  statusIndicator: 'statusIndicator',
  statusShares: 'statusShares',
  trackingNumber: 'tracking',
  file: {
    self: 'file.self',
    alias: 'file.alias',
    url: 'file.url',
  },
  product_ids: 'product_ids',
  product: {
    self: 'product.self',
    alias: 'product.alias',
    brand: 'product.brand',
    category: 'product.category',
    count: 'product.count',
    id: 'product.id',
    isContracted: 'product.isContracted',
    itemQuantity: 'product.itemQuantity',
    lastActivity: 'product.lastActivity',
    navigate: 'product.navigate',
    photos: 'product.photos',
    subCategory: 'product.subCategory',
    status: 'product.status',
  },
};

export const referenceIndexKeys = {
  ...referenceIndexKeys_,
};

export const tabs = {
  overview: 'Overview',
  details: 'Details',
  origin: 'Origin',
  destination: 'Destination',
  files: 'Files',
};

export const barcodesHeaders = {
  [parcelKeys.barcode]: 'Barcode',
  [parcelKeys.action]: '',
};

export const barcodesGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  minmax(140px , 1fr)
  // invoice alias //
  48px 
  `),
  gridAutoRows: '48px 0px',
  rowHeight: '48px',
};

export const barcodeField = 'barcode';

export const addBarcodeFormProps = {
  title: 'Add barcode',
  element: (
    <InfoIcon description={'Enter a barcode for this parcel'} size="_M" />
  ),
  fields: [
    {
      name: barcodeField,
      placeholder: 'Barcode',
    },
  ],
};

export const filesHeaders = {
  [parcelKeys.file.alias]: 'Files',
  [parcelKeys.file.url]: '',
};

export const filesGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // alias //
  minmax(140px , 1fr)
  // download //
  48px
  `),
  gridAutoRows: '48px 0px',
  rowHeight: '48px',
};

export const searchHeaders = {
  [referenceIndexKeys.leftPadding]: '',
  [referenceIndexKeys.name]: '',
  [referenceIndexKeys.country]: '',
  [referenceIndexKeys.city]: '',
};

export const keyToFormFieldMap = {
  [referenceIndexKeys.name]: 'Name',
  [referenceIndexKeys.company]: 'Company',
  [referenceIndexKeys.address]: 'Address',
  [referenceIndexKeys.phone]: 'Phone',
  [referenceIndexKeys.zipcode]: 'Post code',
  [referenceIndexKeys.city]: 'City',
  [referenceIndexKeys.state]: 'Province/State',
  [referenceIndexKeys.country]: 'Country',
  [referenceIndexKeys.additionalDetails]: 'Additional information',
};

export const searchGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  40px
  // name //
  minmax(140px , 1fr)
  // country //
  minmax(140px , 1fr)
  // city //
  minmax(140px , 1fr)
  `),
  gridAutoRows: '36px 0px',
  rowHeight: '36px',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    alwaysFullWidth: true,
    size: '_M',
    param: 'search',
  },
};

export const detailsValidationSchema = Yup.object().shape({
  [parcelKeys.trackingNumber]: Yup.string().required('Required'),
});
