import React from 'react';
import ReactBarcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../../components/molecules/Button/Button';
import { ItemPreviewImage } from '../../../../../../components/molecules/ItemPreviewImage/ItemPreviewImage';
import { Label } from '../../../../../../components/molecules/Label/Label';
import StaticSpinner from '../../../../../../components/molecules/LoadingAnimation/StaticSpinner';
import Tooltip from '../../../../../../components/molecules/Tooltip/Tooltip';
import { Table } from '../../../../../../components/organisms/Table/Table';
import {
  RowAction,
  RowDateHorizontal,
  RowMultipleImages,
  RowSimpleText,
  Space,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors } from '../../../../../../components/style';
import {
  Constants,
  Field_Keys,
  Prose,
  Utils,
} from '../../../../../../constants';
import { DateTypes, formatDate } from '../../../../../../utils/timeUtils';
import {
  handleImageError,
  parseStringModifiers,
} from '../../../../../../utils/utils';
import { whiteLabelKeys } from '../../controllers/variables';
import {
  FirstBarcodeSearchSubtitle,
  FirstBarcodeSearchTitle,
  PropertyTitle,
  TableText,
} from '../atoms/atoms';
import {
  AdditionalInformationContainer,
  BarcodesTableCSS,
  CEPLogo,
  Center,
  CountercheckLogo,
  DeliveryDetailsContainer,
  DeliveryDetailsData,
  Logo,
  OriginDepotContainer,
  OriginDepotData,
  ParcelDetailsContainer,
  ParcelDetailsData,
  ReceiverContainer,
  ReceiverData,
  RoutingContainer,
  RoutingData,
  SenderContainer,
  SenderData,
  SingleImagesCSS,
  TitleContainer,
  TitleText,
} from '../style';
const { companyLabel } = whiteLabelKeys;

const Barcodes = item => {
  const { [whiteLabelKeys.barcodes]: barcodes } = item;

  const displayAmount = 1;

  return (
    <Flex column w>
      {barcodes.slice(0, displayAmount).map((barcode, index) => (
        <TableText text={barcode} ellipsis key={barcode + index} />
      ))}
      {barcodes.length > displayAmount && (
        <TableText text={`+${barcodes.length - displayAmount} `} />
      )}
    </Flex>
  );
};

const statusLabel = {
  false: {
    leftIcon: Icons.AlertTriangle,
    textColor: Colors.Error._500,
    text: 'Missing information',
  },
  true: {
    leftIcon: Icons.CheckCircle,
    textColor: Colors.Success._500,
    text: 'Ready to print',
  },
};

const Status = item => {
  const { printData } = item;
  if (printData == null) return <StaticSpinner size={30} dotSize={3} />;

  return <Label {...statusLabel[printData.readyToPrint]} size="_M" />;
};

const LabelActions =
  ({ edit, print, hasEditPermission, hasPrintPermission, isAllBrands }) =>
  item => {
    const { printData } = item;
    if (edit == null || print == null || printData == null)
      return (
        <Flex gap="_XS">
          {hasEditPermission && (
            <Button size="_S" text="Edit" variant="Secondary" disabled />
          )}
          {hasPrintPermission && (
            <Button size="_S" text="Print" variant="Primary" disabled />
          )}
        </Flex>
      );

    return (
      <Flex gap="_XS">
        {hasEditPermission && (
          <Tooltip content={isAllBrands ? Prose.allBrandsDisabled : ''}>
            <Button
              size="_S"
              text="Edit"
              onClick={edit(item)}
              variant="Secondary"
              disabled={isAllBrands}
            />
          </Tooltip>
        )}
        {hasPrintPermission && (
          <Button
            size="_S"
            text="Print"
            onClick={print(printData)}
            variant="Primary"
            disabled={!printData.readyToPrint}
          />
        )}
      </Flex>
    );
  };

export const tableComponents = ({
  showGallery,
  edit,
  print,
  hasEditPermission,
  hasPrintPermission,
  isAllBrands,
}) => ({
  [whiteLabelKeys.columnSpace]: { display: Space },
  [whiteLabelKeys.images]: {
    display: RowMultipleImages({
      photosKey: whiteLabelKeys.images,
      showGallery,
    }),
  },
  [whiteLabelKeys.sideloadDate]: {
    display: RowDateHorizontal(whiteLabelKeys.sideloadDate),
  },
  [whiteLabelKeys.barcodes]: { display: Barcodes },
  [whiteLabelKeys.whiteLabelStatus]: { display: Status },
  [whiteLabelKeys.actions]: {
    display: LabelActions({
      edit,
      print,
      hasEditPermission,
      hasPrintPermission,
      isAllBrands,
    }),
  },
});

export const FirstSearchHeader = () => {
  return (
    <Flex column align="center" gap="_S">
      <FirstBarcodeSearchTitle text={'Create a new White Label'} />
      <FirstBarcodeSearchSubtitle
        text={'Start scanning the barcode on the shipping label of the parcel.'}
      />
    </Flex>
  );
};

const SingleImages = ({ images, data, showGallery }) => {
  const values = images?.value ?? images;
  return (
    <SingleImagesCSS gap="_2XS">
      {(values ?? [...Array(1)]).map((photo, index) => {
        const value = photo?.[Field_Keys.PHOTO.THUMBNAIL] ?? '';
        return (
          <ItemPreviewImage
            src={value}
            onClick={showGallery({ item: data, initialSelected: index })}
            size="_L"
            key={index}
          />
        );
      })}
    </SingleImagesCSS>
  );
};

const SingleDate = ({ date }) => {
  const value = date?.value ?? date;

  const calendarDate = formatDate(date, DateTypes.ddmmyy) ?? Constants.fallback;

  const time = formatDate(value, DateTypes.time12);

  return (
    <Flex gap="_2XS">
      <TableText text={calendarDate} />
      <TableText text={time} />
    </Flex>
  );
};

export const WhiteLabelData = ({
  data,
  barcodeHeaders,
  barcodeGrid,
  barcodeRowAction,
  showGallery,
}) => {
  if (!data) return;

  return (
    <Flex column gap="_S">
      <Flex column gap="_XS">
        <PropertyTitle text={'SCANNED IMAGES'} />
        <SingleImages
          images={data[whiteLabelKeys.images]}
          data={data}
          showGallery={showGallery}
        />
      </Flex>
      <Flex column gap="_XS">
        <PropertyTitle text={'SIDELOAD DATE'} />
        <SingleDate date={data[whiteLabelKeys.sideloadDate]} />
      </Flex>

      <BarcodesTable
        headers={barcodeHeaders}
        data={data[whiteLabelKeys.barcodes] ?? []}
        grid={barcodeGrid}
        rowAction={barcodeRowAction}
      />
    </Flex>
  );
};

const barcodesTableComponents = {
  [whiteLabelKeys.columnSpace]: { display: Space },
  [whiteLabelKeys.copiableBarcode]: {
    display: RowSimpleText(null, 'bold'),
  },
  [whiteLabelKeys.actionBarcodeGap]: { display: Space },
  [whiteLabelKeys.actions]: { display: RowAction({ icon: Icons.Copy }) },
};

const BarcodesTable = ({ headers, data, grid, rowAction }) => {
  return (
    <BarcodesTableCSS>
      <Table
        headers={headers}
        data={data}
        grid={grid}
        rowAction={rowAction}
        components={barcodesTableComponents}
      />
    </BarcodesTableCSS>
  );
};

const singleStatusLabel = {
  false: {
    leftIcon: Icons.AlertTriangle,
    textColor: Colors.Error._500,
    text: 'Missing information. Edit the label before printing.',
  },
  true: {
    leftIcon: Icons.CheckCircle,
    textColor: Colors.Success._500,
    text: 'Ready to print',
  },
};

const SingleStatus = ({ readyToPrint }) => {
  return <Label {...singleStatusLabel[readyToPrint]} size="_M" />;
};

const SingleActions = ({
  entry,
  readyToPrint,
  edit,
  print,
  hasEditPermission,
  hasPrintPermission,
  isAllBrands,
}) => {
  return (
    <Flex gap="_S" align="end">
      {hasEditPermission && (
        <Tooltip content={isAllBrands ? Prose.allBrandsDisabled : ''}>
          <Button
            size="_M"
            text="Edit"
            onClick={edit(entry)}
            variant="Secondary"
            disabled={isAllBrands}
          />
        </Tooltip>
      )}
      {hasPrintPermission && (
        <Button
          size="_M"
          text="Print"
          onClick={print(entry.printData)}
          variant="Primary"
          disabled={!readyToPrint}
        />
      )}
    </Flex>
  );
};

export const Actions = ({
  data,
  edit,
  print,
  hasEditPermission,
  hasPrintPermission,
  isAllBrands,
}) => {
  return (
    <Flex align="flex-end" justify="between">
      {/* WEIRD. fix in handle single result */}
      {hasPrintPermission && (
        <SingleStatus readyToPrint={data.printData?.readyToPrint} />
      )}
      {(hasEditPermission || hasPrintPermission) && (
        <SingleActions
          entry={data}
          edit={edit}
          print={print}
          readyToPrint={data.printData?.readyToPrint}
          hasEditPermission={hasEditPermission}
          hasPrintPermission={hasPrintPermission}
          isAllBrands={isAllBrands}
        />
      )}
    </Flex>
  );
};

// ******************************
// ******************************
// *** WHITE LABEL COMPONENTS ***
// ******************************
const Title = ({ text, logo1: countercheck_, logo2: brand }) => {
  return (
    <TitleContainer>
      <TitleText>{text}</TitleText>
      <CountercheckLogo>
        <Logo
          src={countercheck_}
          onError={handleImageError(Utils.emptyFunction)}
        />
      </CountercheckLogo>
      <CEPLogo>
        <Logo src={brand} onError={handleImageError(Utils.emptyFunction)} />
      </CEPLogo>
    </TitleContainer>
  );
};

const Receiver = data => {
  return (
    <ReceiverContainer>
      <ReceiverData>{data?.header}</ReceiverData>
      {Object.values(data || {}).map(
        (field, index) =>
          !!index && <ReceiverData key={field + index}>{field}</ReceiverData>
      )}
    </ReceiverContainer>
  );
};

const Sender = data => {
  return (
    <SenderContainer>
      <SenderData>{data?.header}</SenderData>
      {Object.values(data || {}).map(
        (field, index) =>
          !!index && <SenderData key={field + index}>{field}</SenderData>
      )}
    </SenderContainer>
  );
};

const OriginDepot = data => {
  return (
    <OriginDepotContainer>
      {Object.values(data || {}).map(field => (
        <OriginDepotData key={field}>{field}</OriginDepotData>
      ))}
    </OriginDepotContainer>
  );
};

const DeliveryDetails = data => {
  return (
    <DeliveryDetailsContainer>
      {Object.entries(data || {}).map(([title, data]) => (
        <DeliveryDetailsData key={title}>
          {`${title}: `}
          <span>{data}</span>
        </DeliveryDetailsData>
      ))}
    </DeliveryDetailsContainer>
  );
};

const ParcelDetails = data => {
  return (
    <ParcelDetailsContainer>
      <Center>
        {Object.entries(data || {}).map(([title, data]) => (
          <React.Fragment key={title}>
            <ParcelDetailsData>{title}</ParcelDetailsData>
            <ParcelDetailsData>
              <span>{data}</span>
            </ParcelDetailsData>
          </React.Fragment>
        ))}
      </Center>
    </ParcelDetailsContainer>
  );
};

const AdditionalInformation = ({ data }) => {
  return (
    <AdditionalInformationContainer>{data}</AdditionalInformationContainer>
  );
};

const CcParcelId = ({ data }) => {
  if (!data) return;
  return <QRCode value={data} size={55} />;
};

const Aztec = Utils.emptyFunction;

const Routing = data => {
  const countercheckDate = parseStringModifiers(
    `Countercheck\n${formatDate(new Date(), DateTypes.format24)}`
  );

  return (
    <RoutingContainer>
      {Object.values(data || {}).map((field, index) => (
        <RoutingData className={`routing-grid-item-${index}`} key={field}>
          {field}
        </RoutingData>
      ))}
      <RoutingData className={`routing-grid-item-date`}>
        {countercheckDate}
      </RoutingData>
    </RoutingContainer>
  );
};
const Barcode = ({ data }) => {
  return (
    <ReactBarcode
      value={data ?? ' '}
      height={77}
      fontSize={20}
      textMargin={0}
    />
  );
};

export const companyLabelComponents = {
  [companyLabel.title]: Title,
  [companyLabel.receiver]: Receiver,
  [companyLabel.sender]: Sender,
  [companyLabel.originDepot]: OriginDepot,
  [companyLabel.deliveryDetails]: DeliveryDetails,
  [companyLabel.parcelDetails]: ParcelDetails,
  [companyLabel.additionalInformation]: AdditionalInformation,
  [companyLabel.additionalInformation]: AdditionalInformation,
  [companyLabel.ccParcelId]: CcParcelId,
  [companyLabel.aztec]: Aztec,
  [companyLabel.routing]: Routing,
  [companyLabel.barcode]: Barcode,
};

// ******************************
// *** WHITE LABEL COMPONENTS ***
// ******************************
// ******************************
