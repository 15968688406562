import { uniqueId } from 'lodash';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Constants, Field_Keys, Utils } from '../../../../../constants';
import { flow } from '../../../../../lib/js';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { downloadFile, parseStringModifiers } from '../../../../../utils/utils';
import { useGetAdminServices } from '../../../api/getServices';
import { DC } from '../../admin_services/controllers/variables';
import { useDeleteItem } from '../api/deleteItem';
import { useGetBrandSnapshot } from '../api/getBrandSnapshot';
import { SnapshotListLayout } from '../components/SnapshotListLayout';
import AddContract from './AddContract';
import AddInvoice from './AddInvoice';
import AddPlan from './AddPlan';
import AddService from './AddService';
import AddUser from './AddUser';
import {
  brandManagementKeys as keys,
  noResultsStateSnapshot,
  snapshotGrids,
  snapshotHeaders,
  tabActionText,
  tabs,
  userExpansionHeaders,
  usersExpansionGrid,
} from './variables';

const { SELF: ADMIN_SERVICES, VIEW_SERVICES } =
  PermissionKeys.Admin.ADMIN_SERVICES;
const SnapshotList = ({
  tab,
  id,
  brandData,
  listTitle,
  refreshBrandDetails,
}) => {
  const [data, setData] = useState([]);
  const [defaultRoles, setDefaultRoles] = useState([]);
  const [brandServices, setBrandServices] = useState([]);
  const [transitioning, setTransitioning] = useState(false);

  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { hasPermission } = usePermissions();

  const hasServicesPermission = hasPermission(ADMIN_SERVICES, VIEW_SERVICES);
  const useServices = [tabs.users, tabs.services].includes(tab);

  const {
    data: snapshotData,
    isLoading,
    isError,
    error,
    refresh: refreshSnapshotData,
  } = useGetBrandSnapshot({ id, tab });

  const {
    data: { services: allServices },
    isLoading: allServicesIsLoading,
    isError: allServicesIsError,
    error: allServicesError,
  } = useGetAdminServices({
    searchParams: {
      [DC.ITEMS_PER_PAGE]: Constants.noLimit,
      [DC.PAGE]: 1,
    },
    ignore: !useServices,
  });

  const {
    data: deleteData,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
    doDeleteItem,
  } = useDeleteItem({ id, type: tab });

  useLayoutEffect(() => {
    setTransitioning(true);
  }, [tab]);

  useEffect(() => {
    if (isLoading || snapshotData == null) return;
    setTransitioning(false);

    if (isError) {
      showSnackbarError(error);
    }
    if (tab === tabs.services) {
      const { services, default_roles } = snapshotData;
      setDefaultRoles(default_roles);
      const enabledServices = (services ?? []).filter(
        s => s[keys.service.isEnabled]
      );
      setBrandServices(enabledServices);
      setData(enabledServices);
      return;
    }
    setData(snapshotData);
  }, [snapshotData]);

  useDeepCompareEffect(() => {
    if (tab !== tabs.users) return;
    if (!snapshotData?.length || !brandServices?.length || isError || isLoading)
      return;

    const servicesMap = {};
    brandServices.forEach(service => {
      servicesMap[service.id] = service;
    });

    const services = user =>
      user[Field_Keys.USER.CLIENT_ROLES].map(role => {
        const service = servicesMap[role?.[keys.user.serviceId]] ?? {};
        return {
          ...role,
          [keys.service.name]: service.title,
          [keys.service.icon]: service.icon,
        };
      });

    const withMatchedServices = snapshotData.map(user => ({
      ...user,
      [keys.user.services]: services(user),
    }));
    // hide roles with no match to allServices
    // .filter(role => !!role[keys.service.name]);

    setData(withMatchedServices);
  }, [snapshotData, isLoading]);

  useEffect(() => {
    if (deleteIsLoading || deleteData == null) return;
    if (deleteIsError) {
      showSnackbarError(deleteError);
      return;
    }
    showSnackbarSuccess('Item deleted');
    refreshAndDismiss();
  }, [deleteData, deleteIsError, deleteIsLoading]);

  const refreshAndDismiss = () => {
    refreshSnapshotData();
    refreshBrandDetails();
    dismiss();
  };

  const onSnapshotSuccess = refreshAndDismiss;

  const showAddServiceModal = () => {
    const filterMissingServices = services =>
      services.filter(s => !data.some(d => d[keys.service.id] === s.id));

    const filterServices = flow(filterMissingServices);
    const servicesToAdd = filterServices(allServices);

    showModal(AddService, {
      services: servicesToAdd,
      onSuccess: onSnapshotSuccess,
    });
  };

  const showAddUserModal = () => {
    showModal(AddUser, {
      roles: defaultRoles,
      onSuccess: onSnapshotSuccess,
      enabledServices: brandServices,
      brand: brandData,
    });
  };

  const showEditUserModal = ({ item: user }) => {
    showModal(AddUser, {
      roles: defaultRoles,
      onSuccess: onSnapshotSuccess,
      enabledServices: brandServices,
      user,
      brand: brandData,
      mode: 'edit',
    });
  };

  const showAddPlanModal = () => {
    showModal(AddPlan, { onSuccess: onSnapshotSuccess });
  };

  const showEditPlanModal = ({ item: plan }) => {
    showModal(AddPlan, { onSuccess: onSnapshotSuccess, plan, mode: 'edit' });
  };

  const showAddContractModal = () => {
    showModal(AddContract, { onSuccess: onSnapshotSuccess });
  };

  const showEditContractModal = ({ item: contract }) => {
    showModal(AddContract, {
      onSuccess: onSnapshotSuccess,
      contract,
      mode: 'edit',
    });
  };

  const showAddInvoiceModal = () => {
    showModal(AddInvoice, { onSuccess: onSnapshotSuccess });
  };

  const showEditInvoiceModal = ({ item: invoice }) => {
    showModal(AddInvoice, {
      onSuccess: onSnapshotSuccess,
      invoice,
      mode: 'edit',
    });
  };

  const idKey = {
    [tabs.services]: keys.service.id,
    [tabs.users]: keys.brandGroup.id,
    [tabs.plans]: keys.plan.rowId,
    [tabs.contracts]: keys.contract.id,
    [tabs.invoices]: keys.invoice.id,
  };

  const showDeleteModal = ({ item, tab, option }) => {
    if (item == null || tab == null) return;
    const id = item[idKey[tab]];
    const onConfirm = _ => {
      doDeleteItem(id);
      dismiss();
    };
    const itemType = tab.toLowerCase();
    showModal(ConfirmModal, {
      title: 'Delete ' + itemType,
      description:
        parseStringModifiers(`Are you sure you want to delete this ${itemType}?
          **This cannot be undone.**`),
      confirm: 'Delete',
      onConfirm,
      onCancel: dismiss,
    });
  };

  const downloadContract = ({ item }) => {
    const url = item[keys.contract.file];
    downloadFile(url);
  };

  const downloadInvoice = ({ item }) => {
    const url = item[keys.invoice.file];
    downloadFile(url);
  };

  const deleteOption = {
    value: uniqueId(),
    label: 'Remove',
    id: 'delete',
    leftIcon: Icons.XCircle,
    onClick: showDeleteModal,
  };

  const editOption = onClick => ({
    value: uniqueId(),
    label: 'Edit',
    id: 'edit',
    leftIcon: Icons.Edit3,
    onClick,
  });

  const downloadOption = onClick => ({
    value: uniqueId(),
    label: 'Download',
    id: 'download',
    leftIcon: Icons.Download,
    onClick,
  });

  const editUserOption = editOption(showEditUserModal);

  const editPlanOption = editOption(showEditPlanModal);

  const editContractOption = editOption(showEditContractModal);

  const editInvoiceOption = editOption(showEditInvoiceModal);

  const downloadContractOption = downloadOption(downloadContract);

  const downloadInvoiceOption = downloadOption(downloadInvoice);

  const options = {
    [tabs.services]: [deleteOption],
    [tabs.users]: [editUserOption, deleteOption],
    [tabs.plans]: [editPlanOption, deleteOption],
    [tabs.contracts]: [
      downloadContractOption,
      editContractOption,
      deleteOption,
    ],
    [tabs.invoices]: [downloadInvoiceOption, editInvoiceOption, deleteOption],
  };

  const optionMenuOptionClick = tab => item => (option, e) => {
    options[tab]
      .find(o => o.id === option.id)
      ?.onClick({ item, tab, option, e });
  };

  const searchParams = {};
  const dispatch = () => {};
  const dataCount = data?.length;
  const noResultsState = noResultsStateSnapshot;
  const noDataAction = undefined;
  const hasViewPermission = true;

  const expansionGrid = {
    [tabs.users]: usersExpansionGrid,
  };
  const expansionHeaders = { [tabs.users]: userExpansionHeaders };

  const listAction = {
    [tabs.services]: hasServicesPermission
      ? {
          size: '_S',
          text: tabActionText[tabs.services],
          onClick: showAddServiceModal,
        }
      : null,
    [tabs.users]: {
      size: '_S',
      text: tabActionText[tabs.users],
      onClick: showAddUserModal,
    },
    [tabs.plans]: {
      size: '_S',
      text: tabActionText[tabs.plans],
      onClick: showAddPlanModal,
    },
    [tabs.contracts]: {
      size: '_S',
      text: tabActionText[tabs.contracts],
      onClick: showAddContractModal,
    },
    [tabs.invoices]: {
      size: '_S',
      text: tabActionText[tabs.invoices],
      onClick: showAddInvoiceModal,
    },
  };

  return (
    <SnapshotListLayout
      headerProps={{
        title: listTitle,
        button: listAction[tab],
      }}
      tableProps={{
        data,
        headers: snapshotHeaders[tab],
        grid: snapshotGrids[tab],
      }}
      tableComponentProps={{
        options: options[tab],
        optionMenuOptionClick:
          optionMenuOptionClick(tab) ?? Utils.emptyFunction,
        expansionGrid: expansionGrid[tab],
        expansionHeaders: expansionHeaders[tab],
      }}
      tab={tab}
      isLoading={isLoading || transitioning}
      isError={isError}
      error={error}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};
export default SnapshotList;
