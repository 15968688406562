import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { reportsKeys } from '../controllers/variables';

export const useGetReportsMonths = ({ year }) => {
  const [{ data, isLoading = true, isError, error }] = useFetchData(
    'reports/months',
    {
      params: formatParams(year),
      formatter,
    }
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const formatter = data => {
  const formattedData = data.map(month => ({
    startDate: month[Field_Keys.REPORT.START_DATE],
    endDate: month[Field_Keys.REPORT.END_DATE],
    [reportsKeys.name]: `${
      months[new Date(month[Field_Keys.REPORT.START_DATE]).getMonth()]
    } ${new Date(month[Field_Keys.REPORT.START_DATE]).getFullYear()}`,
    year: new Date(month[Field_Keys.REPORT.START_DATE]).getFullYear(),
  }));
  return formattedData;
};

const formatParams = year => year;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
