import { useDeepCompareEffect } from '@react-hookz/web';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import ContractedBrandName from '../../../../../components/molecules/ContractedBrandName/ContractedBrandName';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { Constants } from '../../../../../constants';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { parcelKeys } from '../../../features_public/cases/controllers/variables';
import ParcelSummary from '../../../features_public/parcels/controllers/ParcelSummary';
import { useGetProducts } from '../../../features_public/products/api/getProducts';
import { useGetProductsImage } from '../../../features_public/products/api/getProductsImage';
import { matchImagesToProduct } from '../../../features_public/products/controllers/utils';
import {
  activityToggleOptions,
  productKeys,
} from '../../../features_public/products/controllers/variables';
import { NavURIs, Pages, URIs } from '../../../routes/variables';
import { useGetCaseDetails } from '../api/getCaseDetails';
import { useGetProductDetails } from '../api/getProductDetails';
import { useUpdateProductStatus } from '../api/updateProductStatus';
import { ProductDetailsLayout } from '../components/pages/ProductDetailsLayout';
import { statusStepsText, statusStepsTextNonOwner } from '../variables';
import EditAuthority from './EditAuthority';
import EditProduct from './EditProduct';
import { DC, productFields } from './variables';

const { SELF: OFFICE_PARCELS, VIEW_PARCELS } =
  PermissionKeys.Admin.OFFICE_PARCELS;

const { SELF: OFFICE_PRODUCTS, VIEW_PRODUCTS } =
  PermissionKeys.Admin.OFFICE_PRODUCTS;

const { SELF: OFFICE_SUPPORT, VIEW_PRODUCT_SUPPORT_TICKETS } =
  PermissionKeys.Admin.OFFICE_SUPPORT;

const ProductDetails = () => {
  const [productData, setProductData] = useState([]);

  const { productId } = useParams();
  const navigate = useNavigate();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { dismiss, showModal } = useModal();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission } = usePermissions();

  const hasViewPermission = hasPermission(OFFICE_PRODUCTS, VIEW_PRODUCTS);
  const hasParcelViewPermission = hasPermission(OFFICE_PARCELS, VIEW_PARCELS);
  const canViewSomeTicket = [VIEW_PRODUCT_SUPPORT_TICKETS].some(permission =>
    hasPermission(OFFICE_SUPPORT, permission)
  );

  const ticketsRef = useRef([]);
  const dataRef = useRef({});

  const {
    body: statusUpdateBody,
    isLoading: statusUpdateIsLoading,
    isError: statusUpdateIsError,
    error: statusUpdateError,
    doProductStatusUpdate,
  } = useUpdateProductStatus({ id: productId });

  const copyText = text => () =>
    text &&
    navigator.clipboard.writeText(text).then(
      () => showSnackbarSuccess('Copied to clipboard'),
      () => showSnackbarError('Please try again')
    );

  const {
    data: caseData,
    isLoading: caseIsLoading,
    isError: caseIError,
    getCaseDetails,
  } = useGetCaseDetails({
    ignore: !hasParcelViewPermission,
    copyText,
  });

  const {
    data,
    isLoading,
    isError,
    getProductDetails,
    refetchData,
    //
  } = useGetProductDetails({
    productId,
    hasPermission: permission => hasPermission(OFFICE_PRODUCTS, permission),
    ignore: !hasViewPermission,
  });

  const searchParams = {
    [DC.SEARCH]: caseData[parcelKeys.alias],
    [DC.PAGE]: 1,
    [DC.ITEMS_PER_PAGE]: Constants.noLimit,
  };

  const {
    data: { products },
    isLoading: productsIsLoading,
    isError: productsIsError,
    error: productsError,
    refreshTable: refreshProductsTable,
  } = useGetProducts({
    searchParams,
    ignore: !caseData[parcelKeys.alias],
  });

  const {
    data: productImages,
    isLoading: productImagesIsLoading,
    isError: productImagesIsError,
    error: productImagesError,
    getProductsImage,
  } = useGetProductsImage();

  const reloadProductDetails = () => {
    refetchData();
    refreshProductsTable();
  };

  useEffect(() => {
    getProductDetails(productId);
  }, [productId]);

  useEffect(() => {
    if (!data[productKeys.productAlias]) return;
    const alias = data[productKeys.productAlias];
    const header = `Product ${alias}`;

    const headerChildren = (
      <Flex justify="between" align="center" w>
        <ViewTitle
          text={<CopyableText text={header} copyText={alias} />}
          backPage={NavURIs[Pages.products]}
        />
      </Flex>
    );

    setHeaderChildren(headerChildren);
  }, [data[productKeys.productAlias]]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  useEffect(() => {
    if (data == null || data[parcelKeys.id] == null || isLoading) return;
    if (isError) return;

    if (hasParcelViewPermission) getCaseDetails(data[parcelKeys.id]);
  }, [data]);

  useEffect(() => {
    if (statusUpdateBody == null || statusUpdateIsLoading) return;
    if (statusUpdateIsError) {
      showSnackbarError(statusUpdateError);
      return;
    }

    reloadProductDetails();
  }, [statusUpdateBody, statusUpdateIsLoading, statusUpdateIsError]);

  useEffect(() => {
    ticketsRef.current = data.tickets;
    dataRef.current = data;
  }, [data]);

  useDeepCompareEffect(() => {
    if (productsIsLoading) return;
    if (productsIsError) {
      showSnackbarError(productsError);
      return;
    }
    if (products.length) {
      const product_ids = products.map(
        product => product[productKeys.productId]
      );
      getProductsImage({ product_ids });
      setProductData(products);
    }
  }, [products, productsIsLoading, productsIsError]);

  useDeepCompareEffect(() => {
    if (
      productImagesIsError ||
      // !hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
      false
    ) {
      if (productImagesIsError) showSnackbarError(productImagesError);
      setProductData(matchImagesToProduct([]));
      return;
    }

    if (products?.length && productImages != null) {
      setProductData(matchImagesToProduct(productImages));
    }
  }, [productImages]);

  const handleTicketClick = ticketId => () => {
    if (ticketId == null) {
      showSnackbarError(
        'There is an error with this ticket. Please, try again'
      );
      return;
    }
    const to = `/${URIs[Pages.support]}/${ticketId}`;
    navigate(to);
  };

  const showEditParcel = () => {
    showModal(ParcelSummary, {
      parcel: caseData,
    });
  };

  const goToProductDetails = item => {
    const { [productKeys.productId]: id } = item;
    const to = `${NavURIs[Pages.products]}/${id}`;
    const state = {
      from: NavURIs[Pages.products],
    };
    navigate(to, { state });
  };

  const actionClick = action => () => {
    const { status } = action;

    doProductStatusUpdate({ status });
  };

  const showEditProduct = data => () => {
    const {
      [productKeys.brand]: brand,
      [productKeys.isContracted]: isContracted,
    } = data;

    const productDetails = {
      [productFields.brand]: {
        value: data[productKeys.brandId],
        label: (
          <ContractedBrandName brand={brand} isContracted={isContracted} />
        ),
      },
      [productFields.cateogry]: {
        value: data[productKeys.categoryId],
        label: data[productKeys.categoryName],
      },
      [productFields.quantity]: data[productKeys.productQuantity],
    };

    const onSuccess = reloadProductDetails;

    showModal(EditProduct, {
      productId: data[productKeys.productId],
      productAlias: data[productKeys.productAlias],
      photos: data[productKeys.photos],
      product: data,
      productDetails,
      onSuccess,
      dismiss,
    });
  };

  const showEditAuthority = _ => () => {
    const onSuccess = reloadProductDetails;

    showModal(EditAuthority, {
      parcelId: caseData[parcelKeys.id],
      productAlias: caseData[parcelKeys.alias],
      authorityId: caseData[parcelKeys.authorityId],
      onSuccess,
      dismiss,
    });
  };
  return (
    <ProductDetailsLayout
      data={data}
      products={productData}
      productId={productId}
      productAlias={data[productKeys.productAlias]}
      productDetails={data[productKeys.productDetails]}
      showEditProduct={showEditProduct}
      goToProductDetails={goToProductDetails}
      status={data[productKeys.productStatus]}
      images={data[productKeys.photos]}
      steps={data[productKeys.steps]}
      statusInstructions={
        data[productKeys.isProductOwner]
          ? statusStepsText[data[productKeys.productStatus]]
          : statusStepsTextNonOwner[data[productKeys.productStatus]]
      }
      actions={data[productKeys.actions]}
      actionClick={actionClick}
      riskType={caseData?.[productKeys.riskType]}
      reason={caseData?.[productKeys.reason]}
      activity={{
        [activityToggleOptions[0].value]: caseData?.[parcelKeys.activity],
        [activityToggleOptions[1].value]: data[productKeys.activity],
      }}
      componentSize="_L"
      showEditParcel={showEditParcel}
      parcelAlias={caseData?.[parcelKeys.alias]}
      parcelDetails={caseData?.[productKeys.parcelDetails]}
      parcelDetailsIsLoading={caseIsLoading}
      parcelDetailsIsError={caseIError}
      shouldBlurActors={caseData?.[productKeys.shouldBlurActors]}
      notes={data[productKeys.notes.self]}
      onSuccessCreateNote={refetchData}
      tickets={data[productKeys.tickets]}
      authorityDetails={caseData[parcelKeys.authority]}
      showEditAuthority={showEditAuthority}
      isLoading={isLoading}
      isError={isError}
      handleTicketClick={handleTicketClick}
      attachments={data[productKeys.attachments]}
      hasParcelViewPermission={hasParcelViewPermission}
      hasTicketViewPermission={canViewSomeTicket}
    />
  );
};

export default ProductDetails;
