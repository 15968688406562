import { Constants, Field_Keys } from '../../../../../constants';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { parseStringModifiers } from '../../../../../utils/utils';
import { GalleryPages, GalleryProps } from '../../../constants';
import {
  getReasonTitle,
  getSideloadExplanation,
} from '../../../features/products/controllers/variables';
import { OverviewLayout } from '../components/OverviewLayout';
import { parcelKeys } from './variables';

const Overview = ({ parcel, parcelDetails, isLoading }) => {
  const {
    [Field_Keys.PARCEL.PHOTOS]: photos,
    [parcelKeys.id]: id,
    [parcelKeys.alias]: alias,
    [parcelKeys.status]: status,
    riskType,
    reference_brand_name: reason,
    [Field_Keys.PARCEL.ACTIVITY]: activity,
    [parcelKeys.sideloadDate]: catchDate,
    [Field_Keys.PARCEL.TOTAL_ITEM_QUANTITY]: totalItems,
    [Field_Keys.PARCEL.SITUATION]: situation,
    [Field_Keys.PARCEL.DETECTION]: detection,
  } = parcelDetails ?? parcel ?? {};

  const {
    [Field_Keys.PARCEL.SITUATION_BUTTONS]: actions,
    [Field_Keys.PARCEL.SITUATION_CODE]: situationcode,
    [Field_Keys.PARCEL.SITUATION_CONFIRM_BUTTONS]: confirmationButtons,
    [Field_Keys.PARCEL.SITUATION_ID]: situationId,
    [Field_Keys.PARCEL.SITUATION_INSTRUCTIONS]: instructions,
    [Field_Keys.PARCEL.SITUATION_STYLE]: styleStatus,
    [Field_Keys.PARCEL.SITUATION_TITLE]: situationTitle,
    [Field_Keys.PARCEL.SITUATION_ALERTS]: situationAlerts,
  } = situation ?? {};

  const {
    [Field_Keys.PARCEL.DETECTION_CONFIDENCE]: confidence,
    [Field_Keys.PARCEL.DETECTION_MATCH_TEXT]: matchText,
    [Field_Keys.PARCEL.DETECTION_MATCH_TYPE]: matchType,
    [Field_Keys.PARCEL.DETECTION_OCR_OUTPUT]: cameraRead,
    [Field_Keys.PARCEL.DETECTION_REFERENCE_FULL]: databseMatch,
  } = detection ?? {};

  const details = [
    {
      title: 'Camera Read: ',
      text: ` ${cameraRead ?? Constants.fallback}`,
    },
    {
      title: 'Database Match: ',
      text: ` ${databseMatch ?? Constants.fallback}`,
    },
    {
      title: 'Match Type: ',
      text: ` ${matchType ?? Constants.fallback}`,
    },
    {
      title: 'Matched Text: ',
      text: ` ${matchText ?? Constants.fallback}`,
    },
    {
      title: 'Catch date: ',
      text: ` ${
        catchDate
          ? `${formatDate(catchDate, DateTypes.ddmmyy)} - ${formatDate(
              catchDate,
              DateTypes.time12
            )}`
          : Constants.fallback
      }`,
    },
    {
      title: 'Total items: ',
      text: ` ${totalItems ?? Constants.fallback}`,
    },
  ];

  const description = {
    title: parseStringModifiers(
      'FACILITATOR VIEW\n' + (situationTitle ?? Constants.fallback)
    ),
    text: parseStringModifiers(instructions ?? Constants.fallback),
    status: styleStatus,
    alerts: situationAlerts,
  };

  const riskAnalysisProps = {
    reason: {
      description:
        getSideloadExplanation(reason, riskType) ?? Constants.fallback,
      riskPotential: {
        value: confidence,
        display: `${confidence}%`,
        fill: '#0c57bf',
      },
      title: getReasonTitle(reason),
    },
    riskType,
    noShadow: true,
    size: '_M',
  };

  const recentActivityProps = {
    noShadow: true,
    activity: (activity ?? []).map(
      ({
        [Field_Keys.PARCEL.LOG_CREATED_AT]: createdAt,
        [Field_Keys.PARCEL.LOG_CONTENT]: plContent,
        [Field_Keys.PARCEL.LOG_TYPE]: plType,
        [Field_Keys.PARCEL.LOG_CREATING_USER_DISPLAY_NAME]: usDisplayName,
      }) => ({
        date: `${formatDate(createdAt, DateTypes.ddmmyyyy)}, ${formatDate(
          createdAt,
          DateTypes.time12
        )}`,
        text: parseStringModifiers(plContent ?? Constants.fallback),
      })
    ),
  };

  return (
    <OverviewLayout
      detailsProps={{ details, status }}
      previewGalleryProps={{
        galleryProps: GalleryProps[GalleryPages.parcels],
        images: photos?.map(photo => photo[Field_Keys.PHOTO.THUMBNAIL]),
        id,
        galleryTitle: `Parcel ${alias}`,
      }}
      descriptionProps={{ ...description }}
      riskAnalysisProps={riskAnalysisProps}
      recentActivityProps={recentActivityProps}
      isLoading={isLoading}
    />
  );
};

export default Overview;
