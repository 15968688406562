import { useEffect, useLayoutEffect, useState } from 'react';
import { StatusColors } from '../../../../../components/style';
import { Field_Keys } from '../../../../../constants';
import { formatProductDetails } from '../../../features/products/api/getProductDetails';
import { useGetProductsImage } from '../../products/api/getProductsImage';
import { productKeys } from '../../products/controllers/variables';
import { ProductSnapshotLayout } from '../components/ProductSnapshotLayout';

const ProductSnapshot = ({
  productData,
  title,
  onClick,
  showGallery,
  caseImageRef,
  isLoading,
}) => {
  const [image, setImage] = useState(undefined);
  const [status] = productData?.[productKeys.productStatus]?.split('_') ?? [
    'Unknown',
  ];

  const color = StatusColors[status] ?? StatusColors.Gray;

  const {
    data: images,
    isLoading: imagesIsLoading,
    getProductsImage,
  } = useGetProductsImage();

  useLayoutEffect(() => {
    if (productData[productKeys.productId] == null) return;
    const image =
      productData?.[productKeys.photos]?.[0]?.[Field_Keys.PHOTO.THUMBNAIL];
    setImage(image);
    if (image != null) return;
    getProductsImage({ products: [productData] });
  }, [productData]);

  useEffect(() => {
    if (images == null) return;
    const image = images[0]?.photos[0]?.[Field_Keys.PHOTO.THUMBNAIL];
    setImage(image);
  }, [images]);

  const productDetails =
    productData?.[productKeys.productDetails] ??
    formatProductDetails(productData);

  return (
    <ProductSnapshotLayout
      color={color}
      image={image}
      productDetails={productDetails}
      status={status}
      title={title}
      onClick={onClick}
      showGallery={showGallery}
      caseImageRef={caseImageRef}
      isLoading={isLoading}
      imagesIsLoading={imagesIsLoading}
    />
  );
};

export default ProductSnapshot;
