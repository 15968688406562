export const Pages = {
  analytics: 'analytics',
  authentication: 'authentication',
  brand_settings: 'brand_settings',
  parcels: 'parcels',
  dashboard: 'dashboard',
  knowledge_base: 'knowledge_base',
  products: 'products',
  reference_index: 'references',
  reports: 'reports',
  support: 'support',
  white_label: 'white_label',
  no_services: 'no_services',
};

const {
  analytics,
  authentication,
  brand_settings,
  parcels,
  dashboard,
  knowledge_base,
  products,
  reference_index,
  reports,
  support,
  white_label,
  no_services,
} = Pages;

export const Labels = {
  [analytics]: 'Analytics',
  [authentication]: 'Authentication',
  [brand_settings]: 'Administration',
  [parcels]: 'Parcels',
  [dashboard]: 'Dashboard',
  [knowledge_base]: 'Knowledge Base',
  [products]: 'Products',
  [reference_index]: 'References',
  [reports]: 'Reports',
  [support]: 'Support',
  [white_label]: 'White Label',
  [no_services]: 'No Services',
};

export const URIs = {
  [analytics]: analytics,
  [authentication]: authentication,
  [brand_settings]: brand_settings,
  [parcels]: parcels,
  [dashboard]: dashboard,
  [knowledge_base]: knowledge_base,
  [products]: products,
  [reference_index]: reference_index,
  [reports]: reports,
  [support]: support,
  [white_label]: white_label,
  [no_services]: no_services,
};

export const NavURIs = {
  [analytics]: `/${URIs[analytics]}`,
  [authentication]: `/${URIs[authentication]}`,
  [brand_settings]: `/${URIs[brand_settings]}`,
  [parcels]: `/${URIs[parcels]}`,
  [dashboard]: `/${URIs[dashboard]}`,
  [knowledge_base]: `/${URIs[knowledge_base]}`,
  [products]: `/${URIs[products]}`,
  [reference_index]: `/${URIs[reference_index]}`,
  [reports]: `/${URIs[reports]}`,
  [support]: `/${URIs[support]}`,
  [white_label]: `/${URIs[white_label]}`,
  [no_services]: `/${URIs[no_services]}`,
};
