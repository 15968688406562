import * as Yup from 'yup';
import { Icon } from '../../../../../components/atoms';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Tooltip from '../../../../../components/molecules/Tooltip/Tooltip';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Colors, Size } from '../../../../../components/style';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { dropdownValidation } from '../../../../../utils/validationUtils';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';

const { Status } = Constants;
const {
  Authentication,
  Counterfeit,
  Sideload,
  Inspection,
  Seized,
  Released,
  Stopped,
  OfficeStop,
  OfficeRelease,
} = Constants.Status;

const {
  EDIT_PRODUCT_STATUS_INSPECTION,
  EDIT_PRODUCT_STATUS_AUTHENTICATION,
  EDIT_PRODUCT_STATUS_STOPPED,
  EDIT_PRODUCT_STATUS_SEIZED,
  EDIT_PRODUCT_STATUS_RELEASED,
  EDIT_PRODUCT_STATUS_OFFICE_RELEASE,
  EDIT_PRODUCT_STATUS_OFFICE_STOP,
} = PermissionKeys.Admin.OFFICE_PRODUCTS;

const headerToolipText = {
  [parcelKeys.nextStep]: 'Next parcel status',
  [productKeys.parcel.origin]: 'Parcel origin',
  [productKeys.parcel.destination]: 'Parcel destination',
};

const tooltipChildren = {
  [parcelKeys.nextStep]: (
    <Icon
      name={Icons.Forward}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
  [productKeys.parcel.origin]: (
    <Icon
      name={Icons.PackageOrigin}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
  [productKeys.parcel.destination]: (
    <Icon
      name={Icons.PackageDestination}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
};

export const headers = {
  [productKeys.statusIndicator]: '',
  [productKeys.selected]: '',
  [productKeys.photos]: 'Product images',
  [productKeys.productAlias]: 'ID',
  [productKeys.brandObj.name]: 'Customer',
  [productKeys.productSideloadDate]: 'Catch date',
  [productKeys.inspectionDate]: 'Inspection date',
  [productKeys.category]: 'Product category',
  [productKeys.productStatus]: 'Status',
  [parcelKeys.nextStep]: (
    <Tooltip
      content={headerToolipText[parcelKeys.nextStep]}
      children={tooltipChildren[parcelKeys.nextStep]}
      asChild={false}
    ></Tooltip>
  ),
  [productKeys.parcel.origin]: (
    <Tooltip
      content={headerToolipText[productKeys.parcel.origin]}
      children={tooltipChildren[productKeys.parcel.origin]}
      asChild={false}
    />
  ),
  [productKeys.parcel.destination]: (
    <Tooltip
      content={headerToolipText[productKeys.parcel.destination]}
      children={tooltipChildren[productKeys.parcel.destination]}
      asChild={false}
    />
  ),
  [productKeys.productQuantity]: 'Items',
  [productKeys.parcel.summary]: '',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
  //  indicator //
  16px
  // selected //
  32px 
  // photos //
  minmax(144px, 0.5fr)
  // product alias //
  minmax(166px, 0.5fr)
  // customer //
  minmax(86px, 0.5fr)
  // catch date //
  minmax(66px, 0.3fr)
  // inspection date //
  minmax(66px, 0.4fr)
  // product category //
  minmax(66px, 0.5fr)
  // product status //
  144px
  // next status //
  40px
  // origin //
  40px
  // destination //
  40px
  // count //
  40px
  // parcel summary //
  52px
  `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  ACTOR_DATA: 'ACTOR_DATA',
  PRODUCT_STATUS: 'PRODUCT_STATUS',
  PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
  CUSTOMER: 'CUSTOMER',
  LOCATION: 'LOCATION',
  OWN: 'OWN',
  OTHERS: 'OTHERS',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
  },
  [DC.CUSTOMER]: {
    type: FilterTypes.dropdown,
    icon: Icons.Building,
    param: 'brand',
  },
  [DC.LOCATION]: {
    type: FilterTypes.dropdown,
    icon: Icons.MapPin,
  },
  [DC.PRODUCT_CATEGORY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Tag,
  },
  [DC.ACTOR_DATA]: {
    type: FilterTypes.dropdown,
    icon: Icons.PackageDestination,
    param: 'actorData',
  },
  [DC.PRODUCT_STATUS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Clock,
    param: 'status',
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const initialFilterValues = {
  [DC.LOCATION]: '',
  [DC.CUSTOMER]: '',
  [DC.PRODUCT_CATEGORY]: '',
  [DC.PRODUCT_STATUS]: '',
  [DC.ACTOR_DATA]: '',
};

export const titles = {
  [DC.TIME_FRAME]: 'All time',
  [DC.LOCATION]: 'Location',
  [DC.CUSTOMER]: 'Customer',
  [DC.PRODUCT_CATEGORY]: 'Category',
  [DC.PRODUCT_STATUS]: 'Status',
  [DC.ACTOR_DATA]: 'Actor data',
};

export const productBrandIsContracted = 'productBrandIsContracted';
export const productBrandIsNotContracted = 'productBrandIsNotContracted';

export const allOptions = {
  [DC.CUSTOMER]: [
    { label: 'All customers', value: Constants.all },
    {
      label: 'All non-contracted customers',
      value: productBrandIsNotContracted,
    },
    { label: 'All contracted customers', value: productBrandIsContracted },
  ],
  [DC.LOCATION]: { label: 'All locations', value: Constants.all },
  [DC.PRODUCT_CATEGORY]: { label: 'All categories', value: Constants.all },
  [DC.ACTOR_DATA]: { label: 'All status', value: Constants.all },
  [DC.PRODUCT_STATUS]: { label: 'All status', value: Constants.all },
};

const ownBrand = Constants.OwnBrandKeyword;

const counterfeitParcel = 'Counterfeit Parcel';
const counterfeit = 'Counterfeit';
const nonDPDParcel = 'Non-DPD Parcel';
const adrParcel = 'ADR Parcel';
const illicitGoods = 'Illicit Parcel';

export const getReasonTitle = riskType => {
  switch (riskType) {
    case ownBrand:
      return 'Your Reference Database';
    case null:
    case undefined:
      return 'Countercheck AI';
    default:
      return 'Countercheck Reference Database';
  }
};

export const getSideloadExplanation = (reason, riskType) => {
  return `According to ${who(reason)}, this parcel may contain ${
    what[riskType]
  } goods`;
};

const who = referenceName => {
  switch (referenceName) {
    case ownBrand:
      return 'a reference you provided';
    case undefined:
    case null:
      return 'our detection technology';
    default:
      return "a partner brand's reference";
  }
};

const what = {
  [counterfeitParcel]: 'counterfeit',
  [counterfeit]: 'counterfeit',
  [adrParcel]: 'adr',
  [illicitGoods]: 'illicit',
  [nonDPDParcel]: '3rd party',
  null: 'suspicious',
  undefined: 'suspicious',
};

export const getRiskTypes = riskType => {
  switch (riskType) {
    case adrParcel:
      return 'Potential ADR';
    case illicitGoods:
      return 'Potential Illicit';
    case nonDPDParcel:
      return 'Potential 3rd party';
    case counterfeitParcel:
    case counterfeit:
    case undefined:
    case null:
    default:
      return 'Potential Counterfeit';
  }
};

export const authorityFields = {
  name: 'authorityName',
  email: 'email',
  phone: 'phone',
};

export const authorityTitles = {
  [authorityFields.name]: 'NAME',
  [authorityFields.email]: 'EMAIL',
  [authorityFields.phone]: 'PHONE',
};

export const authorityInitialValues = {
  [authorityFields.name]: '',
  [authorityFields.email]: '',
  [authorityFields.phone]: '',
};

export const authorityValidationSchema = Yup.object().shape({
  value: Yup.string().required(),
  // [authorityFields.name]: Yup.string().notRequired(),
  // [authorityFields.email]: Yup.string().email('Invalid email').notRequired(),
  // [authorityFields.phone]: Yup.string().notRequired(),
});

// FOR LIST VIEW
export const productActions = doProductAction => ({
  [Inspection]: {
    text: 'Inspect',
    onClick: doProductAction(Inspection, false),
    status: Inspection,
    leftIcon: Icons.Search,
  },
  [Authentication]: {
    text: 'Authenticate',
    onClick: doProductAction(Authentication),
    status: Authentication,
    leftIcon: Icons.CheckCircle,
  },
  [Authentication]: {
    text: 'Authenticate',
    onClick: doProductAction(Authentication),
    status: Authentication,
    leftIcon: Icons.CheckCircle,
  },
  [Stopped]: {
    text: 'Stop',
    onClick: doProductAction(Stopped),
    status: Stopped,
    leftIcon: Icons.PauseCircle,
  },
  [Seized]: {
    text: 'Seize',
    onClick: doProductAction(Seized),
    status: Seized,
    leftIcon: Icons.Lock,
  },
  [Released]: {
    text: 'Release',
    onClick: doProductAction(Released),
    status: Released,
    leftIcon: Icons.ArrowRight,
  },
  [OfficeStop]: {
    text: 'Office Stop',
    onClick: doProductAction(OfficeStop),
    status: OfficeStop,
    leftIcon: Icons.PauseCircle,
  },
  [OfficeRelease]: {
    text: 'Office Release',
    onClick: doProductAction(OfficeRelease),
    status: OfficeRelease,
    leftIcon: Icons.ArrowRight,
  },
});

export const statusActionPermission = {
  [Inspection]: EDIT_PRODUCT_STATUS_INSPECTION,
  [Authentication]: EDIT_PRODUCT_STATUS_AUTHENTICATION,
  [Counterfeit]: EDIT_PRODUCT_STATUS_AUTHENTICATION,
  [Stopped]: EDIT_PRODUCT_STATUS_STOPPED,
  [Seized]: EDIT_PRODUCT_STATUS_SEIZED,
  [Released]: EDIT_PRODUCT_STATUS_RELEASED,
  [OfficeStop]: EDIT_PRODUCT_STATUS_OFFICE_STOP,
  [OfficeRelease]: EDIT_PRODUCT_STATUS_OFFICE_RELEASE,
};

// FOR DETAILS VIEW
export const statusActionsProps = {
  [Inspection]: {
    text: 'Inspect',
    status: Inspection,
    permission: EDIT_PRODUCT_STATUS_AUTHENTICATION,
  },
  [Authentication]: {
    text: 'Request Authentication',
    status: Authentication,
    permission: EDIT_PRODUCT_STATUS_AUTHENTICATION,
  },
  Authentic: {
    text: 'Office Release',
    status: OfficeRelease,
    permission: EDIT_PRODUCT_STATUS_AUTHENTICATION,
  },
  [OfficeStop]: {
    text: 'Office Stop',
    status: OfficeStop,
    permission: EDIT_PRODUCT_STATUS_OFFICE_STOP,
  },
  [OfficeRelease]: {
    text: 'Office Release',
    status: OfficeRelease,
    permission: EDIT_PRODUCT_STATUS_OFFICE_RELEASE,
  },
  [Stopped]: {
    text: 'Stop',
    status: Stopped,
    permission: EDIT_PRODUCT_STATUS_STOPPED,
  },
  [Seized]: {
    text: 'Seize',
    status: Seized,
    permission: EDIT_PRODUCT_STATUS_SEIZED,
  },
  [Released]: {
    text: 'Release',
    status: Released,
    permission: EDIT_PRODUCT_STATUS_RELEASED,
  },
};

export const productFields = {
  brand: 'brand',
  cateogry: 'cateogry',
  quantity: 'quantity',
};

export const productProps = {
  [productFields.brand]: { type: 'select' },
  [productFields.cateogry]: { type: 'select' },
  [productFields.quantity]: {},
};

export const productTitles = {
  [productFields.brand]: 'BRAND',
  [productFields.cateogry]: 'PRODUCT CATEGORY',
  [productFields.quantity]: 'ITEMS QUANTITY',
};

export const productInitialValues = {
  [productFields.brand]: '',
  [productFields.cateogry]: '',
  [productFields.quantity]: '',
};

export const productValidationSchema = Yup.object().shape({
  [productFields.brand]: dropdownValidation,
  [productFields.cateogry]: dropdownValidation,
  [productFields.quantity]: Yup.number().required('Required'),
});
