import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';

export const useGetReferences = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data = { references: [], referenceCount: 0 },
      isLoading = true,
      isError,
      error,
    },
    _,
    refreshTable,
  ] = useFetchData('references', {
    params,
    ignore,
    formatter,
    large: true,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refreshTable,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);

  const { references } = data;

  references.forEach(reference => {
    // reference.total_product_count =
    //   reference[referenceIndexKeys.caseStatistics]?.total_product_count;
    // reference.active_product_count =
    //   reference[referenceIndexKeys.caseStatistics]?.active_product_count;
    reference.seized_product_count =
      reference[referenceIndexKeys.caseStatistics]?.seized_product_count;
  });

  return {
    references: data?.references ?? [],
    referenceCount: data?.reference_count ?? 0,
  };
};

const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    COUNTRY,
    ADDED_BY,
    ONLY_ACTIVE: active_references,
    PAGE,
    BRANDS,
    ITEMS_PER_PAGE,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const brand_id = extractSelectedValues(BRANDS);
  const country = extractSelectedValues(COUNTRY);
  const adding_client_id = extractSelectedValues(ADDED_BY);

  const formattedParams = {
    search,
    brand_id,
    country,
    adding_client_id,
    from_date,
    to_date,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };
  if (active_references) formattedParams.active_references = true;
  return formattedParams;
};

const mask = {
  [Field_Keys.REFERENCE.NOTES]: referenceIndexKeys.notes,
  [Field_Keys.REFERENCE.ID]: referenceIndexKeys.id,
  [Field_Keys.REFERENCE.ALIAS]: referenceIndexKeys.alias,
  [Field_Keys.REFERENCE.NAME]: referenceIndexKeys.name,
  [Field_Keys.REFERENCE.COMPANY]: referenceIndexKeys.company,
  [Field_Keys.REFERENCE.ADDRESS]: referenceIndexKeys.address,
  [Field_Keys.REFERENCE.PHONE]: referenceIndexKeys.phone,
  [Field_Keys.REFERENCE.CITY]: referenceIndexKeys.city,
  [Field_Keys.REFERENCE.COUNTRY]: referenceIndexKeys.country,
  [Field_Keys.REFERENCE.PRODUCT_STATS]: referenceIndexKeys.caseStatistics,
  [Field_Keys.REFERENCE.CREATED_AT]: referenceIndexKeys.dateCreated,
  [Field_Keys.REFERENCE.BRAND_NAME]: referenceIndexKeys.brand.name,
  [Field_Keys.REFERENCE.BRAND_IMAGE]: referenceIndexKeys.brand.image,
  [Field_Keys.REFERENCE.CREATING_USER_DISPLAY_NAME]: referenceIndexKeys.addedBy,
  [Field_Keys.REFERENCE.CREATING_USER_IMAGE]: referenceIndexKeys.addedByImage,
};
