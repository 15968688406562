import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { brandManagementKeys } from '../controllers/variables';

export const useGetOfficeBrands = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { brands = [], brand_count: brandCount = 0 } = {},
      isLoading = true,
      isError,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('admin_brands', {
    params,
    ignore,
    large: true,
  });

  return {
    data: {
      brands: formatter(brands, mask),
      brandCount,
    },
    isLoading,
    isError,
    getProducts,
    refreshTable,
  };
};

export const formatParams = params => {
  const {
    SEARCH: search,
    BRAND_GROUP,
    INDUSTRY,
    CONTRACTED,
    PAGE,
    ITEMS_PER_PAGE,
  } = params;

  const brand_group = extractSelectedValues(BRAND_GROUP);
  const industry = extractSelectedValues(INDUSTRY);
  const contracted = extractSelectedValues(CONTRACTED);

  const formattedParams = {
    search,
    brand_group,
    industry,
    contracted,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

export const formatter = (data, mask) => {
  maskEntry(data, mask);
  return data;
};

export const mask = {
  [Field_Keys.BRAND.GROUP_NAME]: brandManagementKeys.brandGroup.name,
  [Field_Keys.BRAND.ALIAS]: brandManagementKeys.brand.alias,
  [Field_Keys.BRAND.GROUP_ID]: brandManagementKeys.brandGroup.id,
  [Field_Keys.BRAND.ID]: brandManagementKeys.brand.id,
  [Field_Keys.BRAND.IMAGE]: brandManagementKeys.brand.image,
  [Field_Keys.BRAND.INDUSTRY]: brandManagementKeys.industry,
  [Field_Keys.BRAND.NAME]: brandManagementKeys.brand.name,
  [Field_Keys.BRAND.IS_CONTRACTED]: brandManagementKeys.brand.isContracted,
  [Field_Keys.BRAND.CLIENT_COUNT]: brandManagementKeys.user.count,
  [Field_Keys.BRAND.PLAN_END_DATE]: brandManagementKeys.contract.endDate,
  [Field_Keys.BRAND.PLAN_NAME]: brandManagementKeys.plan.name,
  [Field_Keys.BRAND.SERVICE_COUNT]: brandManagementKeys.service.count,
};
