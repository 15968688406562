// ~~~ CC Icons ~~~
// Brand
import { ReactComponent as AddReferenceData } from '../../../assets/svg/sidebar/brand/add_reference.svg';
import { ReactComponent as Analytics } from '../../../assets/svg/sidebar/brand/analytics.svg';
import { ReactComponent as WhiteLabel } from '../../../assets/svg/sidebar/brand/white_label.svg';
// Facilitator
import { ReactComponent as Processing } from '../../../assets/svg/sidebar/facilitator/processing.svg';

import { ReactComponent as CC_AlertCircle } from '../../../assets/svg/snackbar/alert_circle.svg';
import { ReactComponent as CC_CheckCircle } from '../../../assets/svg/snackbar/check_circle.svg';

//  Admin
import { ReactComponent as AdminIndicator } from '../../../assets/svg/sidebar/admin/admin_indicator.svg';

// ~~~ CC Icons END~~~
//
//
// ~~~ Illustrations ~~~
// Shared ~~~~
// Status steps
import { ReactComponent as AuthenticationCompleted } from '../../../assets/svg/status/authentication_completed.svg';
import { ReactComponent as AuthenticationRequired } from '../../../assets/svg/status/authentication_pending.svg';
import { ReactComponent as Inspection } from '../../../assets/svg/status/inspection.svg';
import { ReactComponent as Released } from '../../../assets/svg/status/released.svg';
import { ReactComponent as Seized } from '../../../assets/svg/status/seized.svg';
import { ReactComponent as Stopped } from '../../../assets/svg/status/stopped.svg';

// First Load
import { ReactComponent as NetworkError } from '../../../assets/svg/no_data/error.svg';

// Brand ~~~~
// First Load
import { ReactComponent as FirstLoad_Welcome } from '../../../assets/svg/first_load/brand/welcome.svg';
import { ReactComponent as FirstLoad_Whitelabel } from '../../../assets/svg/first_load/brand/whitelabel.svg';

// No data
import { ReactComponent as NoData_Attachments } from '../../../assets/svg/no_data/brand/attachments.svg';
import { ReactComponent as NoData_Authentication } from '../../../assets/svg/no_data/brand/authentication.svg';
import { ReactComponent as NoData_Cases } from '../../../assets/svg/no_data/brand/cases.svg';
import { ReactComponent as NoData_Contracts } from '../../../assets/svg/no_data/brand/contracts.svg';
import { ReactComponent as NoData_Default } from '../../../assets/svg/no_data/brand/default.svg';
import { ReactComponent as NoData_Invoices } from '../../../assets/svg/no_data/brand/invoices.svg';
import { ReactComponent as NoData_KnowledgeBase } from '../../../assets/svg/no_data/brand/knowledge_base.svg';
import { ReactComponent as NoData_Parcels } from '../../../assets/svg/no_data/brand/parcels.svg';
import { ReactComponent as NoData_Plan } from '../../../assets/svg/no_data/brand/plan.svg';
import { ReactComponent as NoData_ReferenceCases } from '../../../assets/svg/no_data/brand/reference_cases.svg';
import { ReactComponent as NoData_ReferenceIndex } from '../../../assets/svg/no_data/brand/reference_index.svg';
import { ReactComponent as NoData_Services } from '../../../assets/svg/no_data/brand/services.svg';
import { ReactComponent as NoData_Support } from '../../../assets/svg/no_data/brand/support.svg';
import { ReactComponent as NoData_Users } from '../../../assets/svg/no_data/brand/users.svg';

// No results
import { ReactComponent as NoResults_Activity } from '../../../assets/svg/no_results/brand/activity.svg';
import { ReactComponent as NoResults_Authentication } from '../../../assets/svg/no_results/brand/authentication.svg';
import { ReactComponent as NoSearchResults_Cases } from '../../../assets/svg/no_results/brand/cases.svg';
import { ReactComponent as NoSearchResults_Default } from '../../../assets/svg/no_results/brand/default.svg';
import { ReactComponent as NoSearchResults_KnowledgeBase } from '../../../assets/svg/no_results/brand/knowledge_base.svg';
import { ReactComponent as NoSearchResults_Parcels } from '../../../assets/svg/no_results/brand/parcels.svg';
import { ReactComponent as NoSearchResults_ReferenceIndex } from '../../../assets/svg/no_results/brand/reference_index.svg';
import { ReactComponent as NoSearchResults_Support } from '../../../assets/svg/no_results/brand/support.svg';
import { ReactComponent as NoSearchResults_Users } from '../../../assets/svg/no_results/brand/users.svg';
import { ReactComponent as NoSearchResults_WhiteLabel } from '../../../assets/svg/no_results/brand/white_label.svg';

// Loading
import { ReactComponent as Loading_Cases } from '../../../assets/svg/loading/brand/cases.svg';
import { ReactComponent as Loading_Default } from '../../../assets/svg/loading/brand/default.svg';
import { ReactComponent as Loading_Parcels } from '../../../assets/svg/loading/brand/parcels.svg';
import { ReactComponent as Loading_ReferenceIndex } from '../../../assets/svg/loading/brand/reference_index.svg';
import { ReactComponent as Loading_Support } from '../../../assets/svg/loading/brand/support.svg';
import { ReactComponent as Loading_WhiteLabel } from '../../../assets/svg/loading/brand/white_label.svg';

// Knowledge Base
import { ReactComponent as InstructionWizard } from '../../../assets/svg/features/brand/knowledge_base/instruction_wizard.svg';
import { ReactComponent as Labels } from '../../../assets/svg/features/brand/knowledge_base/labels.svg';
import { ReactComponent as Logo } from '../../../assets/svg/features/brand/knowledge_base/logo.svg';
import { ReactComponent as Material } from '../../../assets/svg/features/brand/knowledge_base/material.svg';
import { ReactComponent as Other } from '../../../assets/svg/features/brand/knowledge_base/other.svg';
import { ReactComponent as Packaging } from '../../../assets/svg/features/brand/knowledge_base/packaging.svg';
import { ReactComponent as PatternsAndColor } from '../../../assets/svg/features/brand/knowledge_base/patterns_and_color.svg';
import { ReactComponent as SerialNumbers } from '../../../assets/svg/features/brand/knowledge_base/serial_numbers.svg';
import { ReactComponent as SignatureHardware } from '../../../assets/svg/features/brand/knowledge_base/signature_hardware.svg';
import { ReactComponent as Stitching } from '../../../assets/svg/features/brand/knowledge_base/stitching.svg';

// Facilitator ~~~~
// Processing
import { ReactComponent as Processing_Authentication } from '../../../assets/svg/features/facilitator/processing/authentication.svg';
import { ReactComponent as Processing_Illustration } from '../../../assets/svg/features/facilitator/processing/illustration.svg';
import { ReactComponent as Processing_Inspection } from '../../../assets/svg/features/facilitator/processing/inspection.svg';
import { ReactComponent as Processing_Release } from '../../../assets/svg/features/facilitator/processing/release.svg';
import { ReactComponent as Processing_Seize } from '../../../assets/svg/features/facilitator/processing/seizure.svg';
import { ReactComponent as Processing_Stop } from '../../../assets/svg/features/facilitator/processing/stop.svg';
import { ReactComponent as Processing_Unknown } from '../../../assets/svg/features/facilitator/processing/unknown.svg';
// ~~~ Illustrations END ~~~
//
//
// ~~~ Custom Lucide Icons ~~~
import { ReactComponent as ADR } from '../../../assets/svg/custom/adr.svg';
import { ReactComponent as DeleteThin } from '../../../assets/svg/custom/delete_thin.svg';
import { ReactComponent as LQ } from '../../../assets/svg/custom/lq.svg';
import { ReactComponent as PackageDestination } from '../../../assets/svg/custom/package_destination.svg';
import { ReactComponent as PackageLink } from '../../../assets/svg/custom/package_link.svg';
import { ReactComponent as PackageOrigin } from '../../../assets/svg/custom/package_origin.svg';
import { ReactComponent as SearchCheck } from '../../../assets/svg/custom/search_check.svg';
import { ReactComponent as TagCheck } from '../../../assets/svg/custom/tag_check.svg';
// ~~~ Custom Lucide Icons END~~~
//
//
// ~~~ Authentication Icons ~~~
import { ReactComponent as AchievementBronze } from '../../../assets/svg/authentication/bronze.svg';
import { ReactComponent as AchievementGold } from '../../../assets/svg/authentication/gold.svg';
import { ReactComponent as AchievementSilver } from '../../../assets/svg/authentication/silver.svg';
// ~~~ Authentication Icons END~~~
//
//
// ~~~ Other Icons ~~~
import { ReactComponent as Expand } from '../../../assets/svg/others/expand.svg';
// ~~~ Other Icons END~~~

export const ccIcons = {
  Analytics,
  AdminIndicator,
  // Cases,
  // Dashboard,
  // KnowledgeBase,
  // Parcels,
  // Products,
  // ReferenceIndex,
  // Support,
  WhiteLabel,
  AddReferenceData,
  CC_CheckCircle,
  CC_AlertCircle,
  TagCheck,
  Processing,
  DeleteThin,
  ADR,
  LQ,
  PackageDestination,
  PackageLink,
  PackageOrigin,
  SearchCheck,
  // Others
  Expand,
};

export const Icons = {
  AdminIndicator: 'AdminIndicator',
  AddReference: 'AddReferenceData',
  ADR: 'ADR',
  AlarmClock: 'AlarmClock',
  AlertCircle: 'AlertCircle',
  Archive: 'Archive',
  Analytics: 'Analytics',
  ArrowRight: 'ArrowRight',
  ArrowRightCircle: 'ArrowRightCircle',
  AlertTriangle: 'AlertTriangle',
  ArrowUpCircle: 'ArrowUpCircle',
  ArrowUpDown: 'ArrowUpDown',
  Bell: 'Bell',
  Bookmark: 'Bookmark',
  Building: 'Building',
  Calendar: 'Calendar',
  Camera: 'Camera',
  Cases: 'Cases',
  Check: 'Check',
  CheckCircle: 'CheckCircle',
  CheckSquare: 'CheckSquare',
  ChevronDown: 'ChevronDown',
  ChevronLeft: 'ChevronLeft',
  ChevronRight: 'ChevronRight',
  ChevronUp: 'ChevronUp',
  ChevronsUpDown: 'ChevronsUpDown',
  Clock: 'Clock',
  Clock3: 'Clock3',
  Cpu: 'Cpu',
  Copy: 'Copy',
  Component: 'Component',
  Dashboard: 'LayoutDashboard',
  Data: 'TrendingUp',
  Delete: 'Delete',
  DeleteThin: 'DeleteThin',
  Download: 'Download',
  Edit3: 'Edit3',
  ExternalLink: 'ExternalLink',
  Eye: 'Eye',
  EyeOff: 'EyeOff',
  Expand: 'Expand',
  Factory: 'Factory',
  FileInput: 'FileInput',
  File: 'File',
  FileCheck: 'FileCheck',
  FileCheck2: 'FileCheck2',
  FileClock: 'FileClock',
  FileCode: 'FileCode',
  FileEdit: 'FileEdit',
  FilePlus2: 'FilePlus2',
  Files: 'Files',
  FileText: 'FileText',
  FileWarning: 'FileWarning',
  Filter: 'Filter',
  FolderPlus: 'FolderPlus',
  FolderTree: 'FolderTree',
  Forward: 'Forward',
  HelpCircle: 'HelpCircle',
  Image: 'Image',
  ImagePlus: 'ImagePlus',
  Inbox: 'Inbox',
  Info: 'Info',
  FolderCheck: 'FolderCheck',
  KnowledgeBase: 'FolderCheck',
  Link: 'Link',
  List: 'List',
  ListChecks: 'ListChecks',
  ListPlus: 'ListPlus',
  Lock: 'Lock',
  LogOut: 'LogOut',
  LQ: 'LQ',
  Mail: 'Mail',
  MailCheck: 'MailCheck',
  MailOpen: 'MailOpen',
  MapPin: 'MapPin',
  Maximize2: 'Maximize2',
  MessageCircle: 'MessageCircle',
  Minus: 'Minus',
  MoreVertical: 'MoreVertical',
  Package: 'Package',
  PackageCheck: 'PackageCheck',
  PackageDestination: 'PackageDestination',
  PackageLink: 'PackageLink',
  PackageOrigin: 'PackageOrigin',
  PackageSearch: 'PackageSearch',
  PackageX: 'PackageX',
  Parcels: 'PackageCheck',
  PauseCircle: 'PauseCircle',
  Play: 'Play',
  Plus: 'Plus',
  PlusCircle: 'PlusCircle',
  Processing: 'Processing',
  Products: 'TagCheck',
  ReferenceIndex: 'ListChecks',
  RefreshCw: 'RefreshCw',
  Reports: 'FileCheck',
  RotateCcw: 'RotateCcw',
  RotateCw: 'RotateCw',
  ScanLine: 'ScanLine',
  Search: 'Search',
  SearchCheck: 'SearchCheck',
  Settings: 'Settings',
  Shield: 'Shield',
  ShieldAlert: 'ShieldAlert',
  ShieldCheck: 'ShieldCheck',
  Square: 'Square',
  Star: 'Star',
  Support: 'MailCheck',
  Tag: 'Tag',
  Tags: 'Tags',
  TagCheck: 'TagCheck',
  Trash: 'Trash',
  Trash2: 'Trash2',
  TrendingDown: 'TrendingDown',
  TrendingUp: 'TrendingUp',
  Timer: 'Timer',
  Upload: 'Upload',
  User: 'User',
  Users: 'Users',
  UserCog: 'UserCog',
  UserPlus: 'UserPlus',
  X: 'X',
  XCircle: 'XCircle',
  Wand: 'Wand',
  Warning: 'FileWarning',
  WhiteLabel: 'WhiteLabel',
  ZoomIn: 'ZoomIn',
  ZoomOut: 'ZoomOut',
  // CC Icons
  CC_AlertCircle: 'CC_AlertCircle',
  CC_CheckCircle: 'CC_CheckCircle',
};

export const IllustrationsIcons = {
  AuthenticationCompleted,
  AuthenticationRequired,
  AchievementGold,
  AchievementSilver,
  AchievementBronze,
  InstructionWizard,
  Inspection,
  Released,
  Seized,
  Stopped,
  FirstLoad_Welcome,
  FirstLoad_Whitelabel,
  NoData_Attachments,
  NoData_Authentication,
  NoData_Cases,
  NoData_Default,
  NoData_Contracts,
  NoData_Invoices,
  NoData_KnowledgeBase,
  NoData_Parcels,
  NoData_Plan,
  NoData_ReferenceCases,
  NoData_ReferenceIndex,
  NoData_Services,
  NoData_Support,
  NoData_Users,
  NoResults_Activity,
  NoResults_Authentication,
  NoSearchResults_Cases,
  NoSearchResults_Default,
  NoSearchResults_KnowledgeBase,
  NoSearchResults_Parcels,
  NoSearchResults_ReferenceIndex,
  NoSearchResults_Support,
  NoSearchResults_Users,
  NoSearchResults_WhiteLabel,
  Loading_Cases,
  Loading_Parcels,
  Loading_ReferenceIndex,
  Loading_Support,
  Loading_WhiteLabel,
  NetworkError,
  Loading_Default,
  // Instruction Type
  Packaging,
  Logo,
  Labels,
  SignatureHardware,
  Material,
  Stitching,
  PatternsAndColor,
  SerialNumbers,
  Other,
  // Facilitator
  Processing_Illustration,
  Processing_Inspection,
  Processing_Authentication,
  Processing_Stop,
  Processing_Seize,
  Processing_Release,
  Processing_Unknown,
};

export const Illustrations = {
  AuthenticationCompleted: 'AuthenticationCompleted',
  AuthenticationRequired: 'AuthenticationRequired',
  Inspection: 'Inspection',
  Released: 'Released',
  Seized: 'Seized',
  Stopped: 'Stopped',
  InstructionWizard: 'InstructionWizard',
  // Facilitator
  Processing_Illustration: 'Processing_Illustration',
  Processing_Inspection: 'Processing_Inspection',
  Processing_Authentication: 'Processing_Authentication',
  Processing_Stop: 'Processing_Stop',
  Processing_Seize: 'Processing_Seize',
  Processing_Release: 'Processing_Release',
  Processing_Unknown: 'Processing_Unknown',
  // AuthenticationScreens
  AchievementGold: 'AchievementGold',
  AchievementSilver: 'AchievementSilver',
  AchievementBronze: 'AchievementBronze',
};

export const FirstLoad = {
  Welcome: 'FirstLoad_Welcome',
  WhiteLabel: 'FirstLoad_Whitelabel',
};

export const NoData = {
  Attachments: 'NoData_Attachments',
  Authentication: 'NoData_Authentication',
  Cases: 'NoData_Cases',
  Contracts: 'NoData_Contracts',
  KnowledgeBase: 'NoData_KnowledgeBase',
  Invoices: 'NoData_Invoices',
  Parcels: 'NoData_Parcels',
  Plan: 'NoData_Plan',
  ReferenceIndex: 'NoData_ReferenceIndex',
  ReferenceCases: 'NoData_ReferenceCases',
  Services: 'NoData_Services',
  Support: 'NoData_Support',
  Users: 'NoData_Users',
  WhiteLabel: 'NoData_WhiteLabel',
  NetworkError: 'NetworkError',
  Default: 'NoData_Default',
  DevPortal: 'NoData_DevPortal',
};

export const NoResults = {
  Cases: 'NoSearchResults_Cases',
  KnowledgeBase: 'NoSearchResults_KnowledgeBase',
  Parcels: 'NoSearchResults_Parcels',
  ReferenceIndex: 'NoSearchResults_ReferenceIndex',
  Support: 'NoSearchResults_Support',
  Users: 'NoSearchResults_Users',
  WhiteLabel: 'NoSearchResults_WhiteLabel',
  Activity: 'NoResults_Activity',
  Authentication: 'NoResults_Authentication',
  Default: 'NoSearchResults_Default',
};

export const Loading = {
  Cases: 'Loading_Cases',
  Invoices: 'Loading_Invoices',
  Parcels: 'Loading_Parcels',
  ReferenceIndex: 'Loading_ReferenceIndex',
  Support: 'Loading_Support',
  WhiteLabel: 'Loading_WhiteLabel',
  Default: 'Loading_Default',
};

export const InstructionTypes = {
  Packaging: 'Packaging',
  Logo: 'Logo',
  Labels: 'Labels',
  SignatureHardware: 'SignatureHardware',
  Material: 'Material',
  Stitching: 'Stitching',
  PatternsAndColor: 'PatternsAndColor',
  SerialNumbers: 'SerialNumbers',
  Other: 'Other',
};
