import { PropKeys } from '../../../../../constants';
import { caseKeys } from '../../cases/controllers/variables';

export const parcelKeys = {
  authority: 'authority',
  authorityId: 'authorityId',
  authorityEmail: 'authorityEmail',
  authorityName: 'authorityName',
  authorityPhone: 'authorityPhone',
  authorityFax: 'authorityFax',
  actors: 'actors',
  actorType: 'actorType',
  actorName: 'actorName',
  actorCompany: 'actorCompany',
  actorAddress: 'actorAddress',
  actorPostcode: 'actorPostcode',
  actorCountry: 'actorCountry',
  actorPhone: 'actorPhone',
  leftPadding: 'leftPadding',
  caseCount: 'caseCount',
  confidence: 'confidence',
  shouldBlurActors: 'shouldBlurActors',
  id: 'id',
  optionsMenu: 'optionsMenu',
  alias: 'alias',
  parcelId: 'parcelId',
  parcelImages: 'parcelImages',
  parcelVolume: 'parcelVolume',
  parcelWeight: 'parcelWeight',
  products: 'products',
  status: 'status',
  photos: 'photos',
  reason: 'reason',
  riskType: 'riskType',
  riskAnalysis: 'riskAnalysis',
  sideloadDate: 'sideloadDate',
  sorterId: PropKeys.sorterId,
  sorterCountry: 'sorterCountry',
  sorterCity: 'sorterCity',
  sorterName: 'sorterName',
  statusIndicator: 'statusIndicator',
  trackingNumber: 'trackingNumber',
  ownQuantity: caseKeys.ownQuantity,
  totalQuantity: caseKeys.totalQuantity,
};
