import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { authenticationKeys } from '../../../features/authentication/variables';
import { attachmentsMask } from '../../../features/products/api/getProductDetails';
import { productKeys } from '../controllers/variables';

export const useGetProducts = ({
  searchParams,
  ignore,
  is_authentication_page,
}) => {
  const params = formatParams(searchParams, is_authentication_page);

  const [
    {
      data: { products = [], product_count: productCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('products', {
    params,
    ignore,
    large: true,
  });

  return {
    data: { products: maskData(products, mask), productCount },
    isLoading,
    isError,
    error,
    getProducts,
    refreshTable,
  };
};

export const formatParams = (params, is_authentication_page) => {
  const {
    TIME_FRAME,
    SEARCH: search,
    PRODUCT_STATUS,
    LOCATION,
    BRANDS,
    PRODUCT_CATEGORY,
    PAGE,
    ITEMS_PER_PAGE,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const status = extractSelectedValues(PRODUCT_STATUS);
  const sorter_id = extractSelectedValues(LOCATION);
  const brand_id = extractSelectedValues(BRANDS);
  const category_id = extractSelectedValues(PRODUCT_CATEGORY);

  const formattedParams = {
    search,
    status,
    sorter_id,
    brand_id,
    from_date,
    to_date,
    category_id,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  if (is_authentication_page) formattedParams.is_authentication_page = true;

  if (formattedParams?.status?.includes('Stopped'))
    formattedParams?.status?.push('Customs');
  if (
    formattedParams?.status?.includes('Customs') &&
    !formattedParams?.status?.includes('Stopped')
  )
    formattedParams.status = formattedParams?.status?.filter(
      x => x !== 'Customs'
    );

  return formattedParams;
};

const maskData = (data, mask) => {
  maskEntry(data, mask);
  return data.map(entry => ({ ...entry, id: entry[productKeys.productId] }));
};

const mask = {
  ...attachmentsMask,
  [Field_Keys.PRODUCT.BRAND_NAME]: productKeys.brandName,
  [Field_Keys.PRODUCT.BRAND_IMAGE]: productKeys.brandImage,
  [Field_Keys.PRODUCT.IS_BRAND_CONTRACTED]: productKeys.isContracted,
  [Field_Keys.PRODUCT.BRAND_ID]: productKeys.brand,
  [Field_Keys.PRODUCT.ID]: productKeys.productId,
  [Field_Keys.PRODUCT.ALIAS]: productKeys.productAlias,
  [Field_Keys.PRODUCT.STATUS]: productKeys.productStatus,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: productKeys.productSideloadDate,
  [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: productKeys.subCategory,
  [Field_Keys.PARCEL.DETECTION_CONFIDENCE]: productKeys.confidence,
  [Field_Keys.PRODUCT.CREATED_AT]: productKeys.createdAt,
  [Field_Keys.PRODUCT.IS_PRODUCT_OWNER]: productKeys.isProductOwner,
  [Field_Keys.PRODUCT.STATUS_UPDATED_AT]: productKeys.lastActivity,
  [Field_Keys.PARCEL.ID]: productKeys.parcelId,
  [Field_Keys.SORTER.NAME]: productKeys.sorterName,
  [Field_Keys.SORTER.ID]: productKeys.sorterId,
  [Field_Keys.SORTER.CITY]: productKeys.sorterCity,
  [Field_Keys.SORTER.COUNTRY]: productKeys.sorterCountry,
  [Field_Keys.CATEGORY.NAME]: productKeys.category,
  [Field_Keys.PRODUCT.ITEM_QUANTITY]: productKeys.productQuantity,
  [Field_Keys.PRODUCT.REMAINING_AUTHENTICATION_TIME]:
    authenticationKeys.remainingAuthTime,
  [Field_Keys.PRODUCT.HAS_SUPPORT_TICKETS]: productKeys.hasSupportTickets,
};
