import { Loading, NoData, NoResults } from '../../atoms/Icon/Icon.options';

export const defaultNoPermissionDisplay = {
  icon: NoData.Services,
  title: "You don't have permission to view this page",
  description:
    'Contact your administrator to enable your Countercheck experience',
};

export const defaultErrorDisplay = {
  icon: NoData.NetworkError,
  title: 'Error',
  description: 'Something went wrong',
};

export const defaultLoadingDisplay = {
  icon: Loading.Default,
  title: 'Loading...',
  description: 'Your data will appear here soon',
};

export const defaultNoResults = {
  icon: NoResults.Default,
  title: 'No results found',
  description: 'Please try another search or clear all filters',
};

export const defaultNoPermission = {
  icon: NoResults.Default,
  title: 'Not allowed',
  description: `You do not have permission to view this content. \nPlease contact your administrator`,
};

export const defaultNoActivity = {
  icon: NoResults.Default,
  title: 'No activity found',
  description: 'You will see your activity here soon',
};

export const defaultOnlySingleBrand = {
  icon: NoResults.Default,
  title: 'Not allowed',
  description: `You cannot view this content in all brands view. Switch to a single brand view to view this content`,
};
