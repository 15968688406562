import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { usersKeys } from '../controllers/variables';

export const useGetUsers = () => {
  const [{ data = [], isLoading = true, isError, error }, _, refetchUsers] =
    useFetchData('clients', {
      formatter,
    });

  return { data, isLoading, isError, error, refetchUsers };
};

const formatter = data => {
  data = maskEntry(data, mask);

  return data;
};

const mask = {
  [Field_Keys.USER.CLIENT_ID]: [usersKeys.id],
  [Field_Keys.USER.IS_ACTIVE]: [usersKeys.active],
  [Field_Keys.USER.DISPLAY_NAME]: [usersKeys.name],
  [Field_Keys.USER.FIRST_NAME]: [usersKeys.firstName],
  [Field_Keys.USER.LAST_NAME]: [usersKeys.lastName],
  [Field_Keys.USER.EMAIL]: [usersKeys.email],
  [Field_Keys.USER.ID]: [usersKeys.userId],
  [Field_Keys.USER.IMAGE]: [usersKeys.image],
  name: [usersKeys.service.role],
  added_by_image: [usersKeys.service.addedBy.image],
  added_by_name: [usersKeys.service.addedBy.name],
  added_date: [usersKeys.service.dateAdded],
};
