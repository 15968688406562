import {
  createStatusBadgeOptions,
  prepareDataForDropdown,
  withContractedLabel,
} from '../../../../../components/organisms/Filters/utils';
import { Constants, Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { mergeDeep } from '../../../../../lib/js';
import {
  productBrandIsContracted,
  productBrandIsNotContracted,
} from '../../products/controllers/variables';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('office_parcels/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const actor_data = [
  {
    value: 'has_both',
    label: 'Has both',
  },
  {
    value: 'has_none',
    label: 'Has none',
  },
  {
    value: 'has_only_origin',
    label: 'Only origin',
  },
  {
    value: 'has_only_destination',
    label: 'Only destination',
  },
];

export const formatter = data => {
  const { risk_types, brands, sorters, special_filters } = data;
  const newOps = {};

  newOps[DC.LOCATION] = prepareDataForDropdown(
    sorters,
    Field_Keys.SORTER.NAME,
    Field_Keys.SORTER.ID
  );

  newOps[DC.BRAND] = prepareDataForDropdown(
    brands,
    Field_Keys.PRODUCT.BRAND_NAME,
    Field_Keys.PRODUCT.BRAND_ID,
    {
      sectionTitle: 'Brands',
      customLabel: item =>
        withContractedLabel(item, {
          contractedKey: Field_Keys.PRODUCT.IS_BRAND_CONTRACTED,
          labelKey: Field_Keys.PRODUCT.BRAND_NAME,
          size: '_S',
        }),
      bulkSelectableTags: [
        {
          column: Field_Keys.PRODUCT.IS_BRAND_CONTRACTED,
          columnValue: [1, true],
          selectableByValue: productBrandIsContracted,
        },
        {
          column: Field_Keys.PRODUCT.IS_BRAND_CONTRACTED,
          columnValue: [0, false, null, undefined],
          selectableByValue: productBrandIsNotContracted,
        },
      ],
    }
  );

  newOps[DC.RISK_TYPE] = prepareDataForDropdown(
    risk_types,
    Field_Keys.PARCEL.RISK_TYPE,
    Field_Keys.PARCEL.RISK_TYPE
  );

  newOps[DC.SPECIAL_FILTER] = prepareDataForDropdown(
    special_filters,
    'name',
    'id'
  );

  newOps[DC.PARCEL_STATUS] = [
    { section: DC.OTHERS, options: filterStatusOptions() },
  ];

  newOps[DC.ACTOR_DATA] = prepareDataForDropdown(actor_data, 'label', 'value');

  return newOps;
};

const filterStatusOptions = () => {
  const parcelStatus = mergeDeep({}, Constants.DropdownStatus);
  delete parcelStatus[Constants.Status.Counterfeit];
  delete parcelStatus[Constants.Status['Non-counterfeit']];

  const singleStatus = Object.values(parcelStatus).filter(
    status => status.split('_').length < 2
  );

  return createStatusBadgeOptions(singleStatus);
};
