import usePostData from '../../../../../hooks/postHook';

export const useCreateNote = ({ productId }) => {
  const endpoint = `office_product/${productId}/notes`;

  const [{ data, body, isLoading, isError, error }, doCreateNote] =
    usePostData(endpoint);

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doCreateNote,
  };
};
