import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { Constants, Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import {
  maskEntry,
  parseColors,
  reverseKeyValue,
} from '../../../../../utils/utils';

export const useGetParcelsToEvaluate = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { reviews = [], review_count: reviewCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('office_reviews', {
    params,
    ignore,
  });

  return {
    data: {
      reviews: formatter(reviews, mask),
      reviewCount,
    },
    isLoading,
    isError,
    error,
    getProducts,
    refreshTable,
  };
};

export const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    REVIEW_STATUS,
    RISK_TYPE,
    LOCATION,
    BRANDS,
    PRODUCT_CATEGORY,
    PAGE,
    ITEMS_PER_PAGE,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const status = extractSelectedValues(REVIEW_STATUS);
  const risk_type = extractSelectedValues(RISK_TYPE);
  const brand_id = extractSelectedValues(BRANDS);
  const category_id = extractSelectedValues(PRODUCT_CATEGORY);

  const formattedParams = {
    search,
    status,
    risk_type,
    brand_id,
    from_date,
    to_date,
    category_id,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: Constants.noLimit,
  };

  return formattedParams;
};

export const formatter = (data, mask) => {
  maskEntry(data, mask);
  if (Array.isArray(data)) {
    data.forEach(item => {
      item?.body
        .filter(i => i.type === 'text')
        .forEach(text_data => {
          text_data.body.forEach(text => {
            const { color, statusColor } = text;
            text.customColor =
              parseColors({ statusColor }) ?? parseColors(color);
          });
        });
    });
  }
  return data;
};

export const mask = {
  [Field_Keys.PARCEL.ID]: 'id',
};

export const reverseMask = reverseKeyValue(mask);
