import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { CheckBox } from '../../../../../components/molecules';
import Button from '../../../../../components/molecules/Button/Button';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { getTooltipKey } from '../../../../../components/molecules/Tooltip/utils';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  hasExcludeFilter,
  mapToLightweightState,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import { Colors } from '../../../../../components/style';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Constants, PropKeys, Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { intersection, isEmpty, unique } from '../../../../../lib/js';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { parseStringModifiers } from '../../../../../utils/utils';
import { GalleryProps } from '../../../constants';
import ParcelSummary from '../../../features_public/parcels/controllers/ParcelSummary';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { useGetProducts } from '../../../features_public/products/api/getProducts';
import { useGetProductsImage } from '../../../features_public/products/api/getProductsImage';
import { matchImagesToProduct } from '../../../features_public/products/controllers/utils';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import {
  formatCheckedInTooltipContent,
  useGetDots,
} from '../../parcels/api/getDots';
import { sendsEmail } from '../../parcels/controllers/utils';
import { useGetFilters } from '../api/getFilters';
import { useUpdateProductStatusBulk } from '../api/updateProductStatusBulk';
import ProductsLayout from '../components/pages/ProductsLayout';
import { noResultsState } from '../variables';
import { useProductsReducer } from './Products.hook';
import {
  DC,
  allOptions,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  productActions as productActions_,
  productBrandIsContracted,
  statusActionPermission,
  titles,
} from './variables';

const {
  SELF,
  VIEW_PRODUCTS,
  VIEW_PRODUCTS_PHOTOS,
  EDIT_PRODUCT_STATUS_AUTHENTICATION,
  EDIT_PRODUCT_STATUS_INSPECTION,
  EDIT_PRODUCT_STATUS_OFFICE_RELEASE,
  EDIT_PRODUCT_STATUS_OFFICE_STOP,
  EDIT_PRODUCT_STATUS_RELEASED,
  EDIT_PRODUCT_STATUS_STOPPED,
  EDIT_PRODUCT_STATUS_SEIZED,
} = PermissionKeys.Admin.OFFICE_PRODUCTS;

const { SELF: OFFICE_PARCELS, VIEW_PARCELS } =
  PermissionKeys.Admin.OFFICE_PARCELS;

const Products = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { dismiss, showModal } = useModal();
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar();
  const { store } = useStore();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_PRODUCTS);
  const hasParcelViewPermission = _(OFFICE_PARCELS, VIEW_PARCELS);
  const hasStatusEditPermission = hasPermission([
    EDIT_PRODUCT_STATUS_AUTHENTICATION,
    EDIT_PRODUCT_STATUS_INSPECTION,
    EDIT_PRODUCT_STATUS_OFFICE_RELEASE,
    EDIT_PRODUCT_STATUS_OFFICE_STOP,
    EDIT_PRODUCT_STATUS_RELEASED,
    EDIT_PRODUCT_STATUS_STOPPED,
    EDIT_PRODUCT_STATUS_SEIZED,
  ]);

  const searchParamsRef = useRef(null);
  const resetFormRef = useRef(null);

  const ownOptions = {};

  useEffect(() => {
    if (store[PropKeys.brandId])
      ownOptions[DC.CUSTOMER] = {
        sectionTitle: 'Sorter',
        options: [
          {
            label: store[PropKeys.brandName],
            value: store[PropKeys.brandId],
            selectableBy: [productBrandIsContracted],
          },
        ],
      };
  }, [store[PropKeys.brandId]]);

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useProductsReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const urlHasExclude = urlHasSearch && hasExcludeFilter(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    (urlHasExclude && filtersIsLoading) ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  // mark all options with as selected
  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { products, productCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetProducts({
    searchParams,
    ignore: ignoreDataFetch,
  });

  const {
    data: images,
    isLoading: imagesIsLoading,
    isError: imagesIsError,
    error: imagesError,
    getProductsImage,
  } = useGetProductsImage();

  const {
    body: updateProductBody,
    isLoading: updateProductIsLoading,
    isError: updateProductIsError,
    error: updateProductError,
    doUpdate: doUpdateProductStatusBulk,
  } = useUpdateProductStatusBulk();

  const {
    data: nextStepDot,
    isLoading: nextStepDotIsLoading,
    isError: nextStepDotIsError,
    getDots,
  } = useGetDots();

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.products];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isLoading || isError) return;
    setData(products);

    if (products?.length && hasViewPermission) {
      const product_ids = unique(
        products.map(product => product[productKeys.productId])
      );
      getProductsImage({ product_ids, limit: Constants.noLimit });
    }

    if (hasParcelViewPermission) {
      const parcel_ids = unique(
        products.map(product => product[productKeys.parcelId])
      );
      getDots({ parcel_ids });
    }
  }, [products, isLoading, isError]);

  useDeepCompareEffect(() => {
    if (
      imagesIsError ||
      !hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
    ) {
      if (imagesIsError) showSnackbarError(imagesError);
      setData(matchImagesToProduct([]));
      return;
    }

    if (products?.length && images != null)
      setData(matchImagesToProduct(images));
  }, [images]);

  useEffect(() => {
    if (nextStepDot == null || isEmpty(nextStepDot)) return;
    const newData = data.map(row => {
      const id = row[productKeys.parcelId];
      const rowDot = nextStepDot[id];
      return {
        ...row,
        [parcelKeys.nextStep]: rowDot?.[parcelKeys.nextStep] ?? '',
        [getTooltipKey(parcelKeys.nextStep)]: {
          content: formatCheckedInTooltipContent({ ...row, ...rowDot }),
        },
      };
    });

    setData(newData);
  }, [nextStepDot]);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  useEffect(() => {
    if (updateProductIsLoading || updateProductBody == null) return;
    if (updateProductIsError) {
      showSnackbarError(updateProductError);
      return;
    }
    showSnackbarSuccess(
      `Products ${
        Constants.StatusPastTense[updateProductBody.status]?.toLowerCase() ??
        'updated'
      } successfully`
    );
    refreshTable();
  }, [updateProductBody]);

  const showGallery =
    ({ item, initialSelected }) =>
    () =>
      showModal(Gallery, {
        id: item[productKeys.productId],
        title: `Product ${item[productKeys.productAlias]}`,
        initialSelected,
        ...GalleryProps.products,
      });

  const goToProductDetails = item => {
    const { [productKeys.productId]: id } = item;
    const to = `${NavURIs[Pages.products]}/${id}`;

    const state = {
      searchParams: mapToLightweightState(searchParamsRef.current),
      from: location.pathname,
    };
    navigate(to, { state });
  };

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.CUSTOMER]: dropdown(DC.CUSTOMER),
    [DC.LOCATION]: dropdown(DC.LOCATION),
    [DC.PRODUCT_CATEGORY]: dropdown(DC.PRODUCT_CATEGORY),
    [DC.ACTOR_DATA]: dropdown(DC.ACTOR_DATA),
    [DC.PRODUCT_STATUS]: dropdown(DC.PRODUCT_STATUS),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const customStyles = ` 
        .${DC.PRODUCT_STATUS} {
            .options-hoverable {
              min-width: 220px;
            }
        }
      `;
      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.products]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          customStyles={customStyles}
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  const setFooterPagination = () => {
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
      options: [25, 50, 100, 500],
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  };

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    setFooterPagination();
  }, [isLoading, searchParams]);

  const doProductAction =
    (status, confirm = true) =>
    () => {
      const action = () => {
        const products = checkedRows.map(i => data[i][productKeys.productId]);
        const body = { status, product_ids: products };
        doUpdateProductStatusBulk(body);
        setCheckedRows([]);
        dismiss();
      };

      const title = `${productActions[status].text} products`;
      const description = parseStringModifiers(
        `Are you sure you want to **${productActions[
          status
        ].text.toLowerCase()} ${checkedRows.length} products?**`
      );
      const alertText = sendsEmail[status]
        ? 'The customer(s) will receive an email after confirmation.'
        : null;
      const onConfirm = action;
      const confirmText = productActions[status].text;
      const onCancel = dismiss;

      if (confirm)
        showModal(ConfirmModal, {
          title,
          description,
          alertText,
          onConfirm,
          confirmText,
          onCancel,
        });

      if (!confirm) action();
    };

  const productActions = productActions_(doProductAction);

  useEffect(() => {
    if (!hasViewPermission) return;
    if (!checkedRows.length) setFooterPagination();
    else {
      const allNextStatus = checkedRows.map(
        i => data?.[i]?.[productKeys.productActions] ?? []
      );
      const commonNextStatus = intersection(...allNextStatus);
      const uniqueNextStatus = unique(commonNextStatus);
      const permittedNextStatus = uniqueNextStatus.filter(status =>
        hasPermission(statusActionPermission[status])
      );

      const statusActions = permittedNextStatus;

      const footerChildren = (
        <Flex gap="_S">
          {statusActions.map((status, i) => (
            <Button
              key={i}
              {...productActions[status]}
              variant="Tertiary"
              size="_S"
            />
          ))}
        </Flex>
      );

      setFooterChildren(footerChildren);
    }
  }, [checkedRows]);

  const exportData = () => console.info('export data');

  const rowAction = goToProductDetails;

  const toggleRowChecked = (_, index) => () => e => {
    e.stopPropagation();
    setCheckedRows(checked => {
      if (checked.includes(index)) return checked.filter(i => i !== index);
      else return [...checked, index];
    });
  };

  const toggleAllRows = () => () => {
    setCheckedRows(checked => {
      if (checked.length === data.length) return [];
      else return [...Array(data.length).keys()];
    });
  };

  const withToggleAllRows = headers => {
    const selected = checkedRows.length === data.length;
    return {
      ...headers,
      [productKeys.selected]: hasStatusEditPermission ? (
        <div style={{ color: selected ? Colors.Neutral._900 : 'inherit' }}>
          <CheckBox selected={selected} setSelected={toggleAllRows} size="_S" />
        </div>
      ) : (
        ''
      ),
    };
  };

  const openParcelSummary = initialTab => item => {
    const parcel = {
      [parcelKeys.id]: item[productKeys.parcelId],
      [parcelKeys.alias]: item[productKeys.parcel.alias],
      [parcelKeys.status]: item[productKeys.parcel.status],
    };

    showModal(ParcelSummary, {
      parcel,
      initialTab,
    });
  };

  const noDataAction = undefined;

  return (
    <ProductsLayout
      headers={withToggleAllRows(headers)}
      data={data}
      grid={grid}
      rowAction={rowAction}
      checkedRows={checkedRows}
      isError={isError}
      error={error}
      isLoading={isLoading}
      toggleRowChecked={toggleRowChecked}
      showGallery={hasViewPermission && showGallery}
      hasParcelViewPermission={hasParcelViewPermission}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
      tableComponentsProps={{
        showGallery,
        toggleRowChecked,
        checkedRows,
        hasParcelViewPermission,
        hasStatusEditPermission,
        openParcelSummary,
        rowAction,
      }}
    />
  );
};

export default Products;
