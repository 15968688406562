import { Field_Keys } from '../../../../../constants';
import usePostData from '../../../../../hooks/postHook';
import { maskEntry } from '../../../../../utils/utils';
import { devPortalKeys } from '../controllers/variables';

export const useFetchTokens = () => {
  const [{ data, isLoading = true, isError, error }, fetchTokens] = usePostData(
    'fetch_tokens',
    { formatter: data => maskData(data, mask) }
  );

  const doFetchTokens = (key, brandId) => {
    if (!brandId || typeof brandId === 'object') return;
    const body = {
      key,
      brand_id: brandId,
    };
    fetchTokens(body);
  };

  return {
    data,
    isLoading,
    isError,
    error,
    doFetchTokens,
  };
};

const maskData = (data, mask) => {
  data = Object.entries(data);
  maskEntry(data, mask);
  return data.map(entry => ({
    [devPortalKeys.name]:
      entry[0] === Field_Keys.USER.TOKEN ? 'Token' : 'Refresh Token',
    [devPortalKeys.value]: entry[1],
    id: entry[0],
  }));
};

const mask = {
  name: devPortalKeys.name,
  value: devPortalKeys.value,
};
