import {
  RowDateHorizontal,
  RowSimpleText,
  Space,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { referenceIndexKeys } from '../../../../features_public/reference_index/controllers/variables';
import {
  InActiveLabel,
  LastSideload,
  ReferenceCaseSummaryData,
  ReferenceDetailsText,
} from '../atoms/atoms';
import { MetricCSS, MetricsCSS, ReferenceActivityCSS } from '../style';

export const tableComponents = {
  [referenceIndexKeys.leftPadding]: { display: Space },
  [referenceIndexKeys.name]: {
    display: RowSimpleText(referenceIndexKeys.name, 'bold', true),
  },
  [referenceIndexKeys.alias]: {
    display: RowSimpleText(referenceIndexKeys.alias, 'bold', false, {
      copyable: true,
    }),
  },
  [referenceIndexKeys.brand.self]: {
    display: User(
      referenceIndexKeys.brand.name,
      referenceIndexKeys.brand.image
    ),
  },
  [referenceIndexKeys.company]: {
    display: RowSimpleText(referenceIndexKeys.company),
  },
  [referenceIndexKeys.country]: {
    display: RowSimpleText(referenceIndexKeys.country),
  },
  [referenceIndexKeys.casesSeized]: {
    display: RowSimpleText('seized_product_count', 'bold'),
  },
  [referenceIndexKeys.dateCreated]: {
    display: RowDateHorizontal(referenceIndexKeys.dateCreated, {
      time: false,
      boldDate: false,
    }),
  },
  [referenceIndexKeys.addedBy]: {
    display: User(referenceIndexKeys.addedBy, referenceIndexKeys.addedByImage, {
      bold: true,
      border: false,
    }),
  },
};

export const Metrics = ({ metrics }) => {
  return (
    <MetricsCSS w>
      {metrics.map((metric, index) => (
        <Metric
          metric={metric}
          key={metric.label}
          last={index == metrics.length - 1}
        />
      ))}
    </MetricsCSS>
  );
};

export const Metric = ({ metric, last }) => {
  const { label, value } = metric;

  return (
    <MetricCSS column gap="_XS" w0 last={last}>
      <ReferenceDetailsText text={label} />
      <ReferenceCaseSummaryData text={value} />
    </MetricCSS>
  );
};

export const ReferenceActivity = ({ date, active }) => {
  return (
    <ReferenceActivityCSS>
      <LastSideload date={date} />
      <InActiveLabel active={active} />
    </ReferenceActivityCSS>
  );
};
