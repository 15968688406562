import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { extractNameFromStorageURL } from '../controllers/utils';
import {
  authenticityBadgeLabels,
  descriptionNames,
  knowledgeBaseKeys,
} from '../controllers/variables';

export const useGetInstruction = ({ instructionId, ignore }) => {
  const [{ data = [], isLoading = true, isError, error }, _, getInstruction] =
    useFetchData(`knowledge_base/${instructionId}`, {
      formatter: formatGetInstruction,
      ignore,
    });

  return { data, isLoading, isError, error, getInstruction };
};

export const formatGetInstruction = data => {
  maskEntry(data, mask);
  data.initialValues = {
    [descriptionNames.ruleName]: data[knowledgeBaseKeys.name],
    [descriptionNames.productCategory]: {
      value: data[knowledgeBaseKeys.categoryId],
      label: data[knowledgeBaseKeys.productCategory],
    },
    [descriptionNames.description]: data[knowledgeBaseKeys.description],
  };

  data[knowledgeBaseKeys.attachments] = data[knowledgeBaseKeys.attachments].map(
    attachment => {
      const url = attachment[knowledgeBaseKeys.attachmentURL];
      const it = {
        ...attachment,
        [knowledgeBaseKeys.attachmentURL]: url,
        [knowledgeBaseKeys.attachmentName]: extractNameFromStorageURL(url),
      };

      return it;
    }
  );

  data[knowledgeBaseKeys.characteristics].forEach(
    characteristic =>
      (characteristic.items = characteristic.items.map(item => {
        return {
          ...item,
          authenticity: {
            value: item[knowledgeBaseKeys.authenticityValue],
            label:
              authenticityBadgeLabels[
                item[knowledgeBaseKeys.authenticityValue]
              ],
          },
        };
      }))
  );

  return data;
};

const mask = {
  [Field_Keys.INSTRUCTION.ATTACHMENTS]: knowledgeBaseKeys.attachments,
  iaID: knowledgeBaseKeys.attachmentId,
  iaName: knowledgeBaseKeys.attachmentURL,
  [Field_Keys.INSTRUCTION.CHARACTERISTICS]: knowledgeBaseKeys.characteristics,
  iciDetail: knowledgeBaseKeys.characteristicDescription,
  icType: knowledgeBaseKeys.characteristicType,
  iciImage: knowledgeBaseKeys.imageUrl,
  icID: knowledgeBaseKeys.characteristicId,
  iciID: knowledgeBaseKeys.characteristicItemId,
  iciType: knowledgeBaseKeys.authenticityValue,
  [Field_Keys.INSTRUCTION.CREATED_AT]: knowledgeBaseKeys.date,
  [Field_Keys.INSTRUCTION.CONTENT]: knowledgeBaseKeys.description,
  [Field_Keys.CATEGORY.SUBCATEGORY_ID]: knowledgeBaseKeys.categoryId,
  [Field_Keys.INSTRUCTION.TITLE]: knowledgeBaseKeys.name,
  [Field_Keys.INSTRUCTION.LINKS]: knowledgeBaseKeys.links,
  ilUrl: knowledgeBaseKeys.link,
  ilID: knowledgeBaseKeys.linkId,
  [Field_Keys.CATEGORY.FULL]: knowledgeBaseKeys.productCategory,
  [Field_Keys.INSTRUCTION.CREATING_USER_DISPLAY_NAME]:
    knowledgeBaseKeys.addedByName,
  [Field_Keys.INSTRUCTION.CREATING_USER_IMAGE]: knowledgeBaseKeys.addedByImage,
  [Field_Keys.INSTRUCTION.BRAND_IMAGE]: knowledgeBaseKeys.brandImage,
  [Field_Keys.INSTRUCTION.BRAND_NAME]: knowledgeBaseKeys.brandName,
};
