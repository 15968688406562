import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../../components/molecules/Button/Button';
import { toggleProps } from '../../../../../components/molecules/ToggleFilter.js/utils';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  hasExcludeFilter,
  mapToLightweightState,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Constants, PropKeys, Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import { lightweightState } from '../../../../../utils/utils';
import AddReference from '../../../features_public/reference_index/controllers/AddReference';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';
import { newBrandStatus } from '../../../features_public/reference_index/utils';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetFilters } from '../api/getFilters';
import { useGetReferences } from '../api/getReferences';
import ReferenceIndexLayout from '../components/ReferenceIndexLayout';
import { useReferencesReducer } from './ReferenceIndex.hook';
import {
  DC,
  allOptions,
  filtersConstants,
  getAddReferenceButton,
  grid,
  headers,
  initialFilterValues,
  noResultsState,
  titles,
} from './variables';

const from = NavURIs[Pages.reference_index];

const { SELF, VIEW_REFERENCES, VIEW_REFERENCE_DETAILS, CREATE_REFERENCES } =
  PermissionKeys.Brand.REFERENCES;

const ReferenceIndex = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { store, updateStore } = useStore();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const resetFormRef = useRef(null);
  const setFieldValueRef = useRef(null);

  const hasViewPermission = hasPermission(VIEW_REFERENCES);
  const hasCreatePermission = hasPermission(CREATE_REFERENCES);

  const ownOptions = {
    [DC.ADDED_BY]: {
      sectionTitle: 'You',
      options: [
        {
          value: Constants.isSelf,
          label: store[PropKeys.username],
        },
      ],
    },
  };

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useReferencesReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const urlHasExclude = urlHasSearch && hasExcludeFilter(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    (urlHasExclude && filtersIsLoading) ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  // mark all options with as selected
  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { references, referenceCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetReferences({
    searchParams,
    ignore: ignoreDataFetch,
  });

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.reference_index];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  const addReferenceToggle = store[PropKeys.addReferenceToggle];
  useEffect(() => {
    if (addReferenceToggle == null) return;
    showAddReference();
    return () => updateStore({ [PropKeys.addReferenceToggle]: null });
  }, [addReferenceToggle, updateStore]);

  const goToReferenceDetails = referenceInfo => {
    const { [referenceIndexKeys.id]: referenceId } = referenceInfo;

    const state = {
      searchParams: mapToLightweightState(searchParams),
      referenceInfo: lightweightState(referenceInfo),
      from,
    };

    const to = `${NavURIs[Pages.reference_index]}/${referenceId}`;

    navigate(to, { state });
  };

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.BRANDS]: dropdown(DC.BRANDS),
    [DC.COUNTRY]: dropdown(DC.COUNTRY),
    [DC.ADDED_BY]: dropdown(DC.ADDED_BY),
    [DC.ONLY_ACTIVE]: toggleProps({
      searchParams,
      dispatch: _dispatch,
      type: DC.ONLY_ACTIVE,
    }),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  const showAddReference = () =>
    showModal(AddReference, { onUploadSuccess, onCreateSuccess });

  const addReferenceButton = getAddReferenceButton(showAddReference);

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.reference_index]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={setFieldValueRef}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          endSlot={
            hasCreatePermission ? <Button {...addReferenceButton} /> : null
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  }, [isLoading, searchParams]);

  const onCreateSuccess = () => {
    const brandStatus = newBrandStatus(
      store[PropKeys.brandStatus],
      Constants.BrandStatus.Active
    );
    updateStore({ [PropKeys.brandStatus]: brandStatus });
    refreshView();
  };

  const onUploadSuccess = () => {
    const brandStatus = newBrandStatus(
      store[PropKeys.brandStatus],
      Constants.BrandStatus.Processing
    );
    updateStore({ [PropKeys.brandStatus]: brandStatus });
  };

  const noDataAction = hasCreatePermission ? showAddReference : undefined;

  return (
    <ReferenceIndexLayout
      headers={headers}
      data={references}
      grid={grid}
      rowAction={
        (hasViewPermission || hasPermission(VIEW_REFERENCE_DETAILS)) &&
        goToReferenceDetails
      }
      isError={isError}
      error={error}
      isLoading={isLoading}
      addReferenceButton={addReferenceButton}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default ReferenceIndex;
