import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';

export const useGetUsersInolved = ({ ticketId }) => {
  const [{ data = [], error }] = useFetchData(
    `support_ticket/${ticketId}/people`,
    {
      formatter: formatUsersInvolved,
      failureFormatter: () => [],
    }
  );

  return { data, error };
};

const formatUsersInvolved = users => {
  return users.map(user => {
    const {
      [Field_Keys.SUPPORT_TICKET.MESSAGE_CREATING_USER_DISPLAY_NAME]: name,
      [Field_Keys.SUPPORT_TICKET.MESSAGE_CREATING_USER_IMAGE]: image,
    } = user;
    return {
      image,
      name,
    };
  });
};
