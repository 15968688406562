import {
  createStatusBadgeOptions,
  prepareDataForDropdown,
  withContractedLabel,
} from '../../../../../components/organisms/Filters/utils';
import { Constants, Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { mergeDeep } from '../../../../../lib/js';
import { actor_data } from '../../parcels/api/getFilters';
import {
  DC,
  productBrandIsContracted,
  productBrandIsNotContracted,
} from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('office_products/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { categories, brands, sorters } = data;

  const newOps = {};

  newOps[DC.LOCATION] = prepareDataForDropdown(
    sorters,
    Field_Keys.SORTER.NAME,
    Field_Keys.SORTER.ID
  );

  newOps[DC.CUSTOMER] = prepareDataForDropdown(
    brands,
    Field_Keys.PRODUCT.BRAND_NAME,
    Field_Keys.PRODUCT.BRAND_ID,
    {
      sectionTitle: 'Brands',
      customLabel: item =>
        withContractedLabel(item, {
          contractedKey: Field_Keys.PRODUCT.IS_BRAND_CONTRACTED,
          labelKey: Field_Keys.PRODUCT.BRAND_NAME,
          size: '_S',
        }),
      bulkSelectableTags: [
        {
          column: Field_Keys.PRODUCT.IS_BRAND_CONTRACTED,
          columnValue: [1, true],
          selectableByValue: productBrandIsContracted,
        },
        {
          column: Field_Keys.PRODUCT.IS_BRAND_CONTRACTED,
          columnValue: [0, false, null, undefined],
          selectableByValue: productBrandIsNotContracted,
        },
      ],
    }
  );

  newOps[DC.PRODUCT_CATEGORY] = prepareDataForDropdown(
    categories,
    Field_Keys.CATEGORY.FULL,
    Field_Keys.CATEGORY.SUBCATEGORY_ID
  );

  newOps[DC.PRODUCT_STATUS] = [
    { section: DC.OTHERS, options: filterStatusOptions() },
  ];

  newOps[DC.ACTOR_DATA] = prepareDataForDropdown(actor_data, 'label', 'value');

  return newOps;
};

const filterStatusOptions = () => {
  const casesStatus = mergeDeep({}, Constants.DropdownStatus);
  delete casesStatus.Sideload;
  const singleStatus = Object.values(casesStatus).filter(
    status => status.split('_').length < 2
  );

  return createStatusBadgeOptions(singleStatus);
};
