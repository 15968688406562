import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({
  ignore,
  [DC.ONLY_ACTIVE]: active_references,
}) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('admin_references/filters', {
      formatter,
      ignore,
      params: { active_references },
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

const formatter = data => {
  const { brands, countries, adding_clients } = data;

  const newOps = {};

  newOps[DC.BRANDS] = prepareDataForDropdown(
    brands,
    Field_Keys.BRAND.NAME,
    Field_Keys.BRAND.ID
  );

  newOps[DC.COUNTRY] = prepareDataForDropdown(
    countries,
    Field_Keys.REFERENCE.COUNTRY,
    Field_Keys.REFERENCE.COUNTRY
  );

  newOps[DC.ADDED_BY] = prepareDataForDropdown(
    adding_clients,
    'usDisplayName',
    'bguaID',
    { sectionTitle: 'Others' }
  );

  return newOps;
};
