import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import ExportData from '../../../../../components/templates/ExportData/ExportData';
import { useDownloadTableData } from '../../../../../components/templates/ExportData/api/downloadTableData';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Constants, PropKeys, Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { GalleryProps } from '../../../constants';
import {
  formatParams,
  useGetProducts,
} from '../../../features_public/products/api/getProducts';
import { useGetProductsImage } from '../../../features_public/products/api/getProductsImage';
import ProductSummary from '../../../features_public/products/controllers/ProductSummary';
import { matchImagesToProduct } from '../../../features_public/products/controllers/utils';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetFilters } from '../api/getFilters';
import ProductsLayout from '../components/pages/ProductsLayout';
import { noResultsState } from '../variables';
import { useProductsReducer } from './Products.hook';
import {
  DC,
  allOptions,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  titles,
} from './variables';

const {
  SELF,
  VIEW_PRODUCTS_SUMMARY,
  VIEW_PRODUCTS_DETAILS,
  VIEW_PRODUCTS,
  VIEW_PRODUCTS_PHOTOS,
} = PermissionKeys.Brand.PRODUCTS;

const Products = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { dismiss, showModal } = useModal();
  const { showSnackbarError } = useSnackbar();
  const { store } = useStore();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_PRODUCTS);

  const searchParamsRef = useRef(null);
  const resetFormRef = useRef(null);
  const exportModalRef = useRef(null);

  const ownOptions = {};

  useEffect(() => {
    if (store[PropKeys.brandId])
      ownOptions[DC.BRANDS] = {
        sectionTitle: 'Your brand',
        options: [
          {
            value: store[PropKeys.brandId],
            label: store[PropKeys.brandName],
          },
        ],
      };
  }, [store[PropKeys.brandId]]);

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useProductsReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  // mark all options with as selected
  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { products, productCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetProducts({
    searchParams,
    ignore: ignoreDataFetch,
  });
  const {
    data: images,
    isLoading: imagesIsLoading,
    isError: imagesIsError,
    error: imagesError,
    getProductsImage,
  } = useGetProductsImage();

  const {
    isLoading: exportIsLoading,
    isError: exportIsError,
    error: exportError,
    downloadTableData,
  } = useDownloadTableData();

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.products];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    setData(products);
    if (
      products?.length &&
      hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
    )
      getProductsImage({ products });
  }, [products]);

  useDeepCompareEffect(() => {
    if (
      imagesIsError ||
      !hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
    ) {
      if (imagesIsError) showSnackbarError(imagesError);
      setData(matchImagesToProduct([]));
      return;
    }

    if (products?.length && images != null)
      setData(matchImagesToProduct(images));
  }, [images]);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  const showGalleryRowAction = item =>
    showModal(Gallery, {
      id: item[productKeys.productId],
      title: `Product ${item[productKeys.productAlias]}`,
      ...GalleryProps.products,
    });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const showProductSummary = item => {
    if (!(hasPermission(VIEW_PRODUCTS_SUMMARY) || hasViewPermission)) return;
    let {
      [productKeys.productId]: productId,
      [productKeys.productAlias]: productAlias,
    } = item;

    showModal(ProductSummary, {
      productId,
      productAlias,
      searchParams: searchParamsRef.current,
      updateUI: refreshView,
      navigate,
      from: location.pathname,
    });
  };

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.BRANDS]: dropdown(DC.BRANDS),
    [DC.LOCATION]: dropdown(DC.LOCATION),
    [DC.PRODUCT_CATEGORY]: dropdown(DC.PRODUCT_CATEGORY),
    [DC.PRODUCT_STATUS]: dropdown(DC.PRODUCT_STATUS),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.products]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          endSlot={
            <Button
              text="Export"
              size="_S"
              variant="Primary"
              leftIcon={Icons.Download}
              onClick={exportData({ isLoading })}
            />
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  }, [isLoading, searchParams]);

  useEffect(() => {
    if (exportIsLoading) return;
    if (exportIsError) {
      showSnackbarError(exportError);
      exportData({ isLoading: false })();
      return;
    }
    dismiss();
  }, [exportIsLoading, exportIsError]);

  const exportData =
    ({ isLoading, value }) =>
    () => {
      const options = [
        {
          id: 'current',
          label: 'Current page',
        },
        {
          id: 'all',
          label: 'All pages',
        },
      ];

      const onConfirm = () => {
        const { value } = exportModalRef.current;
        const params = formatParams(searchParamsRef.current);
        if (value === 'all') params.limit = Constants.noLimit;
        downloadTableData({ params, type: 'products', large: true });
        exportData({ isLoading: true, value })();
      };

      showModal(
        ExportData,
        {
          title: 'Export product list',
          values: exportModalRef,
          options,
          initialState: {
            subtitle: 'Please choose the pages you wish to export:',
            value: value ?? options[0].id,
          },
          confirm: 'Export',
          onCancel: dismiss,
          onConfirm,
          dismiss,
          isLoading,
        },
        { ignoreOverlayDismiss: true }
      );
    };

  const noDataAction = undefined;
  // if (!isLogisticsUser) delete headers[productKeys.brandName];
  return (
    <ProductsLayout
      headers={headers}
      data={data}
      grid={grid(true)}
      rowAction={
        (hasPermission(VIEW_PRODUCTS_SUMMARY) || hasViewPermission) &&
        showProductSummary
      }
      isError={isError}
      error={error}
      isLoading={isLoading}
      showGallery={
        hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS]) &&
        showGalleryRowAction
      }
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default Products;
