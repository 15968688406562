import { Field_Keys } from '../../../../../constants';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { processingKeys, stepKeys } from './variables';

export const formatParcelDetails = (data, body) => {
  const snapshotDetails = [
    {
      title: 'Sideload date: ',
      text: formatDate(data[processingKeys.sideloadDate], DateTypes.ddmmyyyy),
    },
    {
      title: 'Risk type: ',
      text: data[processingKeys.riskType],
    },
    {
      title: 'Barcode: ',
      text: data[processingKeys.barcode],
    },
  ];

  return {
    ...data,
    [processingKeys.snapshotDetails]: snapshotDetails,
    [processingKeys.alias]: data[processingKeys.alias] ?? 'Scanning error',
    [processingKeys.image]: data[processingKeys.image],
  };
};

export const prefillValues = item => {
  const values = {
    item,
    [stepKeys.selectBrand]: {
      [processingKeys.product.brand.id]: item[processingKeys.product.brand.id],
      [processingKeys.product.brand.name]:
        item[processingKeys.product.brand.name],
      [processingKeys.product.brand.isContracted]:
        item[processingKeys.product.brand.isContracted],
    },
    [stepKeys.category]: {
      value: item[processingKeys.product.categoryId],
      label: `${item[processingKeys.product.category]} - ${
        item[processingKeys.product.subcategory]
      }`,
    },
    [stepKeys.itemQuantity]: item[processingKeys.product.itemCount],
    [stepKeys.photos]: {
      dbImages: item[processingKeys.product.photos],
    },
    [stepKeys.summary]: {
      details: {
        brand: {
          title: 'BRAND',
          text: item[processingKeys.product.brand.name],
        },
        category: {
          title: 'CATEGORY',
          text: item[processingKeys.product.category],
          subText: item[processingKeys.product.subcategory],
        },
        quantity: {
          title: 'QUANTITY',
          text: item[processingKeys.product.itemCount],
        },
      },
      images: item[processingKeys.product.photos],
    },
  };

  values[stepKeys.photos] = {
    ...values[stepKeys.photos],
    isContracted:
      values[stepKeys.selectBrand][processingKeys.brand.isContracted],
    valid: hasEnoughImages(values),
  };

  return values;
};

// group together in an object keyed by step
export const updateSummaryCategory = x => ({
  ...x,
  [stepKeys.summary]: {
    ...x[stepKeys.summary],
    details: {
      ...x[stepKeys.summary].details,
      category: {
        ...x[stepKeys.summary].details.category,
        text: x[stepKeys.category].label.split(' - ')[0],
        subText: x[stepKeys.category].label.split(' - ')[1],
      },
    },
  },
});

export const updateSummarySelectedBrand = x => ({
  ...x,
  [stepKeys.summary]: {
    ...x[stepKeys.summary],
    details: {
      ...x[stepKeys.summary].details,
      brand: {
        ...x[stepKeys.summary].details.brand,
        text: x[stepKeys.selectBrand][processingKeys.brand.name],
      },
    },
  },
});

export const updateDbImages = fileIndex => x => ({
  ...x,
  [stepKeys.photos]: {
    ...x[stepKeys.photos],
    dbImages: [
      ...x[stepKeys.photos].dbImages.slice(0, fileIndex),
      ...x[stepKeys.photos].dbImages.slice(fileIndex + 1),
    ],
  },
});

export const updateSummaryQuantity = x => ({
  ...x,
  [stepKeys.summary]: {
    ...x[stepKeys.summary],
    details: {
      ...x[stepKeys.summary].details,
      quantity: {
        ...x[stepKeys.summary].details.quantity,
        text: x[stepKeys.itemQuantity],
      },
    },
  },
});

export const updateMemoryImages = fileState => x => ({
  ...x,
  [stepKeys.photos]: {
    ...x[stepKeys.photos],
    memoryImages: [...fileState.files],
  },
});

export const updateSummaryImages = x => ({
  ...x,
  [stepKeys.summary]: {
    ...x[stepKeys.summary],
    images: [
      ...(x[stepKeys.photos]?.dbImages?.map(
        dbImage => dbImage[Field_Keys.PHOTO.THUMBNAIL]
      ) ?? []),
      ...(x[stepKeys.photos]?.memoryImages ?? []).map(file =>
        URL.createObjectURL(file)
      ),
    ],
  },
});

export const hasEnoughImages = x => {
  const noOfImagesTotal =
    (x[stepKeys.photos]?.dbImages?.length ?? 0) +
    (x[stepKeys.photos]?.memoryImages?.length ?? 0);
  const isContracted =
    x[stepKeys.selectBrand]?.[processingKeys.brand.isContracted];

  const valid = noOfImagesTotal >= (isContracted ? 3 : 1);
  return valid;
};

export const updateEnoughImages = x => {
  const valid = hasEnoughImages(x);

  return {
    ...x,
    [stepKeys.photos]: {
      ...x[stepKeys.photos],
      valid,
    },
  };
};

export const updateIsContractedInPhotos = x => ({
  ...x,
  [stepKeys.photos]: {
    ...x[stepKeys.photos],
    isContracted: x[stepKeys.selectBrand]?.[processingKeys.brand.isContracted],
  },
});
