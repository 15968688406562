import { useEffect } from 'react';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry, parseColors } from '../../../../../utils/utils';
import {
  brandManagementKeys as keys,
  tabURIs,
  tabs,
} from '../controllers/variables';

export const useGetBrandSnapshot = ({ id, tab }) => {
  const [{ data, isLoading = true, isError, error }, fetchData, _, refresh] =
    useFetchData('', {
      formatter: formatter(mask[tab], tab),
    });

  useEffect(() => {
    const snapshot = tabURIs[tab];
    const url = `admin_brand/${id}/${snapshot}`;
    fetchData({ url });
  }, [id, tab]);

  return { data, isLoading, isError, error, refresh };
};

const formatter = (mask, tab) => data => {
  if (tab === tabs.services) {
    data.services.forEach(service => {
      maskEntry(service.roles, rolesMask);
    });
    maskEntry(data.default_roles, defaultRolesMask);
    maskEntry(data.services, mask);
  } else if (tab === tabs.users) {
    data.forEach(user => {
      maskEntry(user[Field_Keys.USER.CLIENT_ROLES], userRolesMask);
    });
    maskEntry(data, mask);
  } else maskEntry(data, mask);
  snapshotFormatter(tab)(data);
  return data;
};

const snapshotFormatter = tab => data => {
  if (tab === tabs.plans) data.forEach(formatPlans);
  if (tab === tabs.invoices) data.forEach(formatInvoice);

  return data;
};

const formatPlans = plan => {
  const {
    [keys.plan.status.response]: status,
    bsbStartDate: startDate,
    bsbEndDate: endDate,
  } = plan;

  const { borderColor, color, background } = status;
  const statusProps = {
    ...status,
    borderColor: parseColors(borderColor),
    color: parseColors(color),
    background: parseColors(background),
  };

  plan[keys.plan.start] = formatDate(startDate, DateTypes.yyyymmdd);
  plan[keys.plan.end] = formatDate(endDate, DateTypes.yyyymmdd);
  plan[keys.plan.status.self] = statusProps;
};

const formatInvoice = invoice => {
  const { [keys.invoice.status.response]: status } = invoice;
  const { borderColor, color, background } = status;
  const statusProps = {
    ...status,
    borderColor: parseColors(borderColor),
    background: parseColors(background),
    color: parseColors(color),
  };

  invoice[keys.invoice.status.value] = status.text;
  invoice[keys.invoice.status.self] = statusProps;
};

const defaultRolesMask = {
  name: 'title',
};

const rolesMask = {
  name: 'text',
};

const userRolesMask = {
  added_by_image: keys.service.addedBy.image,
  added_by_name: keys.service.addedBy.name,
};

export const mask = {
  [tabs.services]: {
    creator_image: keys.service.addedBy.image,
    creator_name: keys.service.addedBy.name,
    detail: keys.service.description,
    icon: keys.service.icon,
    id: keys.service.id,
    is_enabled: keys.service.isEnabled,
    name: keys.service.name,
    creation_date: keys.service.addedAt,
  },
  [tabs.contracts]: {
    bctID: keys.contract.id,
    //  bctBrand: keys.contract.b  ,
    bctDetail: keys.contract.description,
    bctContract: keys.contract.file,
    bctName: keys.contract.name,
    createdAt: keys.contract.addedAt,
    creator_image: keys.contract.addedBy.image,
    creator_name: keys.contract.addedBy.name,
  },
  [tabs.plans]: {
    spID: keys.plan.planTypeId,
    spName: keys.plan.name,
    spSetupFee: keys.plan.setupFee,
    //  spFreeUsersCount: keys.plan.,
    //  spCostPerUser: keys.plan.,
    spCostPerSeizure: keys.plan.priceCatch,
    spCostPerMonth: keys.plan.priceMonthly,
    bsbStatus: keys.plan.status.response,
    bsbID: keys.plan.rowId,
    //  spCostPerYear: keys.plan.,
    bsbNote: keys.plan.note,
    creation_date: keys.plan.addedAt,
    creator_image: keys.plan.addedBy.image,
    creator_name: keys.plan.addedBy.name,
  },
  [tabs.invoices]: {
    biID: keys.invoice.id,
    biAlias: keys.invoice.name,
    biInvoice: keys.invoice.file,
    // biName: keys.invoice.alias,
    // biName: keys.invoice.name,
    biDate: keys.invoice.date,
    biAmount: keys.invoice.amount,
    biStatus: keys.invoice.status.response,
    createdAt: keys.invoice.addedAt,
    creator_image: keys.invoice.addedBy.image,
    creator_name: keys.invoice.addedBy.name,
  },
  [tabs.users]: {
    [Field_Keys.USER.CLIENT_ID]: keys.brandGroup.id,
    [Field_Keys.USER.CREATED_AT]: keys.user.addedAt,
    added_date: keys.service.addedAt,
    id: keys.service.role_id,
    key: keys.service.roleKey,
    name: keys.service.roleName,
    service_id: keys.user.serviceId,
    [Field_Keys.USER.DISPLAY_NAME]: keys.user.name,
    [Field_Keys.USER.FIRST_NAME]: keys.user.firstName,
    [Field_Keys.USER.LAST_NAME]: keys.user.lastName,
    [Field_Keys.USER.EMAIL]: keys.user.email,
    [Field_Keys.USER.ID]: keys.user.id,
    [Field_Keys.USER.IMAGE]: keys.user.image,
    [Field_Keys.USER.IS_CLIENT_MAIN_BRAND_CONTACT]: keys.user.isMainContact,
    [Field_Keys.USER.SSO_ID]: keys.user.isSSOUser,
    [Field_Keys.USER.CLIENT_CREATING_USER_IMAGE]: keys.user.addedBy.image,
    [Field_Keys.USER.CLIENT_CREATING_USER_DISPLAY_NAME]: keys.user.addedBy.name,
  },
};
