import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { Colors, Size } from '../../../../../components/style';
import { Constants, Field_Keys, re } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry, parseStringModifiers } from '../../../../../utils/utils';
import { parcelKeys } from '../../../features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { formatTickets } from '../../support/api/getTickets';
import { getRiskTypes, statusActionsProps } from '../controllers/variables';
import { statusStepsHistory } from '../variables';

const { fallback } = Constants;

export const useGetProductDetails = ({ productId, hasPermission, ignore }) => {
  const [
    {
      data = { images: [] },
      isLoading = !!productId && !ignore,
      isError,
      error,
    },
    getProductDetails,
    refetchData,
  ] = useFetchData(`office_product/${productId}`, {
    formatter: formatter({
      hasPermission,
    }),
    ignore: ignore || !productId,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    getProductDetails: productId => {
      getProductDetails({ url: `office_product/${productId}` });
    },
    refetchData,
  };
};

const formatNotes = notes => {
  maskEntry(notes, {
    [Field_Keys.PRODUCT.NOTE]: productKeys.notes.note,
    [Field_Keys.PRODUCT.NOTE_CREATING_USER_DISPLAY_NAME]:
      productKeys.notes.userName,
    [Field_Keys.PRODUCT.NOTE_CREATING_USER_IMAGE]: productKeys.notes.userImage,
    [Field_Keys.PRODUCT.NOTE_CREATED_AT]: productKeys.notes.dateCreated,
  });
  return notes;
};

export const formatter =
  ({ hasPermission }) =>
  data => {
    const {
      [Field_Keys.PRODUCT.ACTIVITY]: activityResponse,
      [Field_Keys.PRODUCT.ACTORS]: actors,
      [Field_Keys.PRODUCT.ATTACHMENTS]: attachments,
      [Field_Keys.AUTHORITY.EMAIL]: authorityEmail,
      [Field_Keys.AUTHORITY.NAME]: authorityName,
      [Field_Keys.AUTHORITY.PHONE]: authorityPhone,
      [Field_Keys.PRODUCT.ALIAS]: productAlias,
      [Field_Keys.PRODUCT.STATUS]: status,
      [Field_Keys.PRODUCT.STATUS_NEXT_OPTIONS]: productActions,
      [Field_Keys.PRODUCT.NOTES]: productNotes,
      [Field_Keys.PARCEL.ID]: parcelId,
      [Field_Keys.PARCEL.ALIAS]: caseAlias,
      [Field_Keys.PARCEL.RISK_TYPE]: riskType_,
      [Field_Keys.PARCEL.ACTIVITY]: parcelActivityResponse,

      //   // pdMatchType: matchType,
      [Field_Keys.PARCEL.SHOULD_SHOW_ACTORS]: should_show_actors,
      // soID: sorterId,
      // summary response
      // activity: activityResponse,
      [Field_Keys.PRODUCT.ID]: productId,
      [Field_Keys.SORTER.CITY]: city,
      [Field_Keys.SORTER.COUNTRY]: country,
      [Field_Keys.SORTER.NAME]: sorter,
      [Field_Keys.CATEGORY.NAME]: category,
      [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: subcategory,
      [Field_Keys.PRODUCT.ITEM_QUANTITY]: quantity,
      [Field_Keys.PRODUCT.IS_PRODUCT_OWNER]: isProductOwner,
      // paRiskType: riskType_,
      // // pdMatchType: matchType,
      [Field_Keys.PARCEL.DETECTION_CONFIDENCE]: confidence,
      [Field_Keys.PRODUCT.PHOTOS]: photos,
      [Field_Keys.REFERENCE.BRAND_NAME]: referenceBrandName,
      // soID: sorterId,
      [Field_Keys.PRODUCT.TICKETS]: tickets_,
      [Field_Keys.PRODUCT.BRAND_NAME]: brandName,
      [Field_Keys.PRODUCT.BRAND_ID]: brandId,
      [Field_Keys.CATEGORY.FULL]: categoryName,
      [Field_Keys.CATEGORY.SUBCATEGORY_ID]: categoryId,
      [Field_Keys.PRODUCT.IS_BRAND_CONTRACTED]: isContracted,
    } = data;

    const shouldBlurActors = actors == null || !should_show_actors;

    const notes = formatNotes(productNotes);

    const activity = formatActivity(activityResponse);

    const productCategory =
      joinStrings(category, subcategory, Constants.fallback) ??
      Constants.fallback;

    const productDetails = formatProductDetails({
      productCategory,
      [productKeys.brand]: brandName,
      [productKeys.sorterName]: sorter,
      [productKeys.productQuantity]: quantity,
      [productKeys.isContracted]: isContracted,
    });

    const tickets = formatTickets(tickets_);

    return {
      [productKeys.activity]: activity,
      [productKeys.productId]: productId,
      [productKeys.productDetails]: productDetails,
      [productKeys.photos]: photos,
      [productKeys.isProductOwner]: isProductOwner,
      [productKeys.riskType]: getRiskTypes(riskType_),
      [productKeys.productStatus]: status,
      [productKeys.tickets]: tickets,
      [productKeys.brand]: brandName,
      [productKeys.brandId]: brandId,
      [productKeys.categoryName]: categoryName,
      [productKeys.categoryId]: categoryId,
      [productKeys.productQuantity]: quantity,
      [productKeys.isContracted]: isContracted,
      id: productId,
      [parcelKeys.alias]: caseAlias,
      [parcelKeys.id]: parcelId,
      [productKeys.attachments]: formatAttachments(attachments),
      [productKeys.productAlias]: productAlias,
      [productKeys.notes.self]: notes,
      [productKeys.shouldBlurActors]: shouldBlurActors,
      [productKeys.steps]: statusStepsHistory[status],
      [productKeys.activity]: formatActivity(activityResponse),
      [productKeys.actions]: getProductActions({
        hasPermission,
        productActions,
      }),
    };
  };

const getProductActions = ({ hasPermission, productActions }) => {
  const actions = (productActions ?? []).map(status => {
    const props = statusActionsProps[status];
    return {
      Component: Button,
      props: {
        status,
        ...props,
        size: '_S',
        variant: 'Status',
        key: status,
      },
      permission: props.permission,
    };
  });

  try {
    return actions.filter(({ permission }) => hasPermission(permission));
  } catch {
    return [];
  }
};

const activityEntryFormatter =
  ({ dateKey, contentKey }) =>
  entry => {
    const { [dateKey]: date, [contentKey]: text } = entry;

    return {
      date: `${formatDate(date, DateTypes.ddmmyy)}, ${formatDate(
        date,
        DateTypes.time12
      )}`,
      text: parseStringModifiers(text),
    };
  };

const formatActivity = activity => {
  const format = activityEntryFormatter({
    dateKey: [Field_Keys.PRODUCT.LOG_CREATED_AT],
    contentKey: [Field_Keys.PRODUCT.LOG_CONTENT],
  });

  return activity.map(format);
};

const formatAttachments = attachments => {
  maskEntry(attachments, attachmentsMask);

  return attachments.map(attachment => {
    const url = attachment[productKeys.attachmentURL];
    const it = {
      ...attachment,
      [productKeys.attachmentURL]: url,
      [productKeys.attachmentName]: extractNameFromStorageURL(url),
    };

    return it;
  });
};

const extractNameFromStorageURL = name => {
  try {
    const [uri, storageFileName] = name.split('case_files/');
    const [uuidName, storageSignature] =
      storageFileName.split('?X-Goog-Algorithm=');

    const fileName = uuidName.replaceAll(re.uuid, '').replace('_.', '.');

    return decodeURIComponent(fileName);
  } catch {
    return name;
  }
};

export const formatProductDetails = ({
  productCategory,
  [productKeys.productQuantity]: quantity,
  [productKeys.isContracted]: isContracted,
  [productKeys.brand]: brand,
}) => {
  const customerIcon =
    isContracted && isContracted != '0'
      ? {
          name: Icons.CC_CheckCircle,
          size: Size.Icon._2XS,
          color: Colors.Primary._600,
        }
      : null;

  return [
    {
      title: 'Customer: ',
      text: brand,
      icon: customerIcon,
    },
    {
      title: 'Product category: ',
      text: productCategory,
    },
    {
      title: 'Item quantity: ',
      text: quantity,
    },
  ];
};

const attachmentsMask = {
  // TODO: avoid collisions with cases table
  caID: productKeys.attachmentId,
  caName: productKeys.attachmentURL,
};
