import { Field_Keys } from '../../../../../constants';
import { authorityFields } from '../controllers/variables';

const { default: useFetchData } = require('../../../../../hooks/fetchHook');

export const useGetAuthority = () => {
  const [{ data = [], isLoading, isError, error }, getAuthority] = useFetchData(
    'authorities',
    {
      formatter,
    }
  );

  return { data, isLoading, isError, error, getAuthority };
};

const formatter = data => {
  return data.map(entry => ({
    label: entry[Field_Keys.AUTHORITY.NAME],
    value: entry[Field_Keys.AUTHORITY.ID],
    [authorityFields.name]: entry[Field_Keys.AUTHORITY.NAME],
    [authorityFields.email]: entry[Field_Keys.AUTHORITY.EMAIL],
    [authorityFields.phone]: entry[Field_Keys.AUTHORITY.PHONE],
  }));
};
