import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import { Filters } from '../../../../../components/organisms/Filters';
import { mergeFilterStateAndConsts } from '../../../../../components/organisms/Filters/utils';
import { Colors, Size } from '../../../../../components/style';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import {
  Constants,
  Field_Keys,
  PropKeys,
  Utils,
} from '../../../../../constants';
import { useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import {
  getTimeFrameUrlParams,
  titleFromCalendar,
} from '../../../../../utils/timeUtils';
import { openNewTab } from '../../../../../utils/utils';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetParcels } from '../api/getParcels';
import { useGetParcelsAccumulated } from '../api/getParcelsAccumulated';
import { useGetProducts } from '../api/getProducts';
import { useGetSupport } from '../api/getSupport';
import { ParcelList } from '../components';
import DashboardLayout from '../components/DashboardLayout';
import {
  DC,
  allOptions,
  filtersConstants,
  navToEvalCasesButton,
  parcelStatusesDestination,
  titles,
} from '../variables';
import { dispatchAction, useDashboardReducer } from './Dashboard.hook';

const { SELF: PRODUCTS, VIEW_PRODUCTS } = PermissionKeys.Admin.OFFICE_PRODUCTS;

const { SELF: PARCELS, VIEW_PARCELS } = PermissionKeys.Admin.OFFICE_PARCELS;
const {
  SELF: SUPPORT,
  VIEW_GENERAL_SUPPORT_TICKETS,
  VIEW_PARCEL_SUPPORT_TICKETS,
  VIEW_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Admin.OFFICE_SUPPORT;

const Dashboard = () => {
  const navigate = useNavigate();
  const { store } = useStore();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission } = usePermissions();
  const { dismiss, showModal } = useModal();

  const hasProductPermission = hasPermission(PRODUCTS, VIEW_PRODUCTS);
  const hasParcelPermission = hasPermission(PARCELS, VIEW_PARCELS);
  const hasSupportPermission = hasPermission(SUPPORT, [
    VIEW_GENERAL_SUPPORT_TICKETS,
    VIEW_PARCEL_SUPPORT_TICKETS,
    VIEW_PRODUCT_SUPPORT_TICKETS,
  ]);

  const resetFormRef = useRef(null);

  const inactiveBrand =
    store[PropKeys.brandStatus] === Constants.BrandStatus.Inactive;

  let ownOptions;
  let options;
  let filtersIsError;

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useDashboardReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const {
    data: {
      productOverview,
      itemOverview,
      authenticationStats,
      parcelStatuses,
      topProducts,
      topItems,
      actorsStats,
      brandOverview,
    },
    isLoading: productsIsLoading,
    isError: productsIsError,
    refresh: refreshProducts,
  } = useGetProducts({ searchParams, hasPermission: hasProductPermission });

  const {
    data: { overviewSupportStats },
    isLoading: supportIsLoading,
    isError: overviewSupporIsError,
    refresh: refreshSupport,
  } = useGetSupport({ searchParams, hasPermission: hasSupportPermission });

  const {
    data: { overviewParcelStats, parcelCounts },
    isLoading: parcelsIsLoading,
    isError: parcelsIsError,
    refresh: refreshParcels,
  } = useGetParcels({ searchParams, hasPermission: hasParcelPermission });

  const {
    data: accumulatedParcels,
    isLoading: accumulatedParcelsIsLoading,
    isError: accumulatedParcelsIsError,
    refresh: refreshAccumulatedParcels,
  } = useGetParcelsAccumulated({
    searchParams,
    hasPermission: hasParcelPermission,
  });

  const refreshTable = () => {
    refreshProducts();
    refreshParcels();
    refreshSupport();
    refreshAccumulatedParcels();
  };

  const refetchFilters = Utils.emptyFunction;

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
      fullWidth: true,
    },
    [DC.REFRESH]: { onClick: refreshView },
  };

  const onTotalClick = item => () => {
    const { navlink } = item ?? {};
    const isParcelLink = navlink.includes(Pages.parcels);
    const parcelStatus = isParcelLink
      ? `?riskType=${['Illicit Parcel', 'Counterfeit Parcel'].join('+')}`
      : '';
    const to =
      navlink +
      parcelStatus +
      getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]);

    navigate(to);
  };

  const onParcelStatusClick = item => () => {
    const { status } = item ?? {};
    const statusURLParam = parcelStatusesDestination[status];
    doProductNav(statusURLParam);
  };

  const onStatusNavigate = item => () => {
    const {
      data: { navigateParams },
    } = item;
    const to =
      navigateParams + getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]);

    navigate(to);
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  useEffect(() => {
    const FilterStyles = styled.div`
      max-width: 416px;
      min-width: 416px;
    `;
    const headerChildren = (
      <Flex justify="between" w align="center">
        <ViewTitle text={Labels[Pages.dashboard]} noNav />
        <Flex gap="_S">
          <FilterStyles children={<Filters filters={filters} />} />
        </Flex>
      </Flex>
    );

    setTimeout(() => setHeaderChildren(headerChildren), 0);
  }, [searchParams]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  const evalNavigateButton = {
    ...navToEvalCasesButton,
    onClick: () => {
      const statusURLParam = `?status=${Constants.Status.Authentication}`;

      doProductNav(statusURLParam);
    },
  };

  const doProductNav = (...urlParams) => {
    const brandsURLParam = `?ownBrandId=${
      store[PropKeys.brandId]
    }?=ownBrandName=${store[PropKeys.brandName]}`;

    const to =
      NavURIs[Pages.products] +
      getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]) +
      brandsURLParam +
      urlParams.join('');

    navigate(to);
  };

  const onBarClick = item => {
    const to =
      NavURIs[Pages.products] +
      getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]) +
      `?productId=${item.link}`;
    navigate(to);
  };

  const withNavigateParamsOnClick = data => {
    const details = data.details.map(item => {
      const { navigateParams } = item;
      const onClick = () => {
        const to =
          navigateParams + getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]);
        navigate(to);
      };
      return { ...item, onClick };
    });

    data.details = details;
    return data;
  };

  const statusBreakdownStats = [
    {
      data: withNavigateParamsOnClick(authenticationStats),
      isLoading: productsIsLoading,
      isError: productsIsError,
      disabled: !hasProductPermission,
    },
    {
      data: withNavigateParamsOnClick(actorsStats),
      isLoading: productsIsLoading,
      isError: productsIsError,
      disabled: !hasProductPermission,
    },
  ];

  const overviewIsLoading = [
    parcelsIsLoading,
    productsIsLoading,
    supportIsLoading,
  ].some(Boolean);

  const topProductsBarchartProps = {
    className: 'top-products-barchart',
    onBarClick,
    isLoading: productsIsLoading,
    isError: productsIsError,
    ...topProducts,
  };

  const topItemsBarchartProps = {
    className: 'top-items-barchart',
    onBarClick,
    isLoading: productsIsLoading,
    isError: productsIsError,
    ...topItems,
  };

  const onParcelStatClick =
    (item, { countType, parcels } = {}) =>
    () => {
      showModal(ParcelList, {
        title: item.title + ' parcels',
        countType,
        parcels,
        dismiss,
      });
    };

  const onParcelListAliasClick = alias => () => {
    const to =
      NavURIs[Pages.parcels] +
      // getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]) +
      `?search=${alias}`;
    openNewTab(to);
  };

  const formatOverviewParcelStats = data => {
    data.totals.forEach(item => {
      const countList = formatAcummulated(parcelCounts[item.key]);
      const accumulatedList = formatAcummulated(
        accumulatedParcels?.parcel_ids?.[item.key]
      );

      const cummulativeCount = accumulatedParcels?.parcel_counts?.[item.key];

      const cumulativeText =
        cummulativeCount != null
          ? `${cummulativeCount} cumulative`
          : Constants.fallback;

      item.count.countIcon = {
        name: Icons.Maximize2,
        color: Colors.Neutral._400,
        size: Size.Icon._M,
      };
      item.count.onClick = onParcelStatClick(item, {
        parcels: countList,
      });
      item.cumulative = {
        text: cumulativeText,
        onClick: cummulativeCount
          ? onParcelStatClick(item, {
              countType: 'Cumulative',
              parcels: accumulatedList,
            })
          : null,
      };
      if (cummulativeCount)
        item.cumulative.icon = {
          name: Icons.Maximize2,
          color: Colors.Neutral._400,
          size: Size.Icon._XS,
        };
    });

    return data;
  };

  const formatAcummulated = (data = []) => {
    return data.map(item => {
      return {
        alias: item[Field_Keys.PARCEL.ALIAS],
        onClick: onParcelListAliasClick(item[Field_Keys.PARCEL.ALIAS]),
      };
    });
  };

  const standardFormatOverview = data => {
    data.totals.forEach(item => {
      item.titleIcon = navigateIcon(item);
    });

    return data;
  };

  const formatProductOverview = standardFormatOverview;

  const formatItemOverview = standardFormatOverview;

  const formatOverviewSupportStats = standardFormatOverview;

  const formatBrandOverview = standardFormatOverview;

  const navigateIcon = item => ({
    name: Icons.ExternalLink,
    theme: 'dashboard_icon',
    size: Size.Icon._M,
    animated: true,
    onClick: onTotalClick(item),
  });

  return (
    <DashboardLayout
      overviewStats={[
        formatOverviewParcelStats(overviewParcelStats),
        formatProductOverview(productOverview),
        formatItemOverview(itemOverview),
      ]}
      statusBreakdownStats={statusBreakdownStats}
      overviewSupportStats={formatOverviewSupportStats(overviewSupportStats)}
      onStatusNavigate={onStatusNavigate}
      evalNavigateButton={evalNavigateButton}
      parcelStatuses={parcelStatuses}
      productsIsLoading={productsIsLoading}
      productsIsError={productsIsError}
      supportIsLoading={supportIsLoading}
      overviewIsLoading={overviewIsLoading}
      inactiveBrand={inactiveBrand}
      onParcelStatusClick={onParcelStatusClick}
      hasProductPermission={hasProductPermission}
      topProductsBarchartProps={topProductsBarchartProps}
      topItemsBarchartProps={topItemsBarchartProps}
      brandOverview={formatBrandOverview(brandOverview)}
    />
  );
};

export default Dashboard;
