import { Form, Formik } from 'formik';
import { useEffect, useLayoutEffect, useState } from 'react';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants, Field_Keys } from '../../../../../constants';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { parseStringModifiers } from '../../../../../utils/utils';
import { GalleryPages, GalleryProps } from '../../../constants';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { useAddPhotos } from '../api/addPhotos';
import { useGetBrands } from '../api/getBrands';
import { useGetCategories } from '../api/getCategories';
import { useRemovePhotos } from '../api/removePhotos';
import { useUpdateProductBrand } from '../api/updateProductBrand';
import { useUpdateProductCategory } from '../api/updateProductCategory';
import { EditProductLayout } from '../components/pages/EditProductLayout';
import {
  productFields,
  productInitialValues,
  productValidationSchema,
} from './variables';

const EditProduct = ({
  productDetails,
  productId,
  productAlias,
  product,
  isContracted,
  onSuccess,
  dismiss,
  photos: dbImages,
}) => {
  const [photos, setPhotos] = useState({ dbImages });
  const [filesStagedForDeletion, setFilesStagedForDeletion] = useState([]);

  const { showSnackbarError, showSnackbarSuccess, showSnackbarWarning } =
    useSnackbar();
  const { showModal } = useModal();

  const { handleDrop, handleImport, fileState, resetFileUpload, deleteFile } =
    useFileUpload();

  const loadFiles = handleDrop(Constants.FileUpload.uploadType.image);

  const {
    body: categoryBody,
    isLoading: categoryIsLoading,
    isError: categoryIsError,
    doUpdateProductCategory,
  } = useUpdateProductCategory({ id: productId });

  const {
    body: brandBody,
    isLoading: brandIsLoading,
    isError: brandIsError,
    doUpdateProductBrand,
  } = useUpdateProductBrand({ id: productId });

  const {
    data: { options: categories },
  } = useGetCategories({ ignore: false });

  const {
    data: { options: brands },
  } = useGetBrands({ ignore: false });

  const {
    body: photosBody,
    isLoading: photosIsLoading,
    isError: photosIsError,
    addPhotos,
  } = useAddPhotos({
    id: productId,
  });

  const {
    body: photosRemove,
    isLoading: photosRemoveIsLoading,
    isError: photosRemoveIsError,
    removePhotos,
  } = useRemovePhotos({
    id: productId,
  });

  const updates = [brandBody, categoryBody, photosBody, photosRemove];
  const updatesLoading = [
    brandIsLoading,
    categoryIsLoading,
    photosIsLoading,
    photosRemoveIsLoading,
  ];
  const updatesError = [
    { isError: brandIsError, error: 'Could not change brand' },
    {
      isError: categoryIsError,
      error: 'Could not change quantity and category',
    },
    { isError: photosIsError, error: 'Could not add photos' },
    { isError: photosRemoveIsError, error: 'Could not remove photos' },
  ];

  useEffect(() => {
    if (updates.every(u => u == null) || updatesLoading.some(l => l)) return;

    let stayOn = false;
    updatesError.forEach(({ isError, error }) => {
      if (!isError) return;
      showSnackbarError(error);
      stayOn = true;
    });

    if (stayOn) return;
    showSnackbarSuccess();
    dismiss();
    onSuccess();
  }, [...updates, ...updatesLoading, categoryIsError, photosIsError]);

  useLayoutEffect(() => {
    if (fileState.files == null) return;
    setPhotos(updateMemoryImages(fileState));
  }, [fileState]);

  useEffect(() => {
    return () => resetFileUpload();
  }, []);

  const onSubmit = values => updateProduct(values);

  const updateProduct = values => {
    if (fileState.files)
      handleImport({
        folder: 'case_photos/uploaded',
        // no url because I want to upload through another function
        parameterName: 'names',
        successCallback: ({ uploadNames }) =>
          onUploadSuccessEdit({ values, uploadNames }),
      });
    else onUploadSuccessEdit({ uploadNames: [], values });
  };

  const onUploadSuccessEdit = ({ values, uploadNames }) => {
    const photos = uploadNames.map(name => ({ name }));
    const photosToRemove = filesStagedForDeletion.map(
      ({ [Field_Keys.PHOTO.ID]: photo_id }) => photo_id
    );

    doUpdateProductDetails(values);
    if (photos?.length) addPhotos({ photos });
    if (photosToRemove?.length) removePhotos({ photos: photosToRemove });
  };

  const doUpdateProductDetails = values => {
    // destructure  category, quantity, brand from values
    // if category or quantity is different from productDetails, update
    // if brand is different from productDetails, update
    const {
      [productFields.cateogry]: category,
      [productFields.quantity]: quantityValue,
      [productFields.brand]: brand,
    } = values;
    // desructure value property from each dropdown type
    const { value: categoryValue } = category;
    const { value: brandValue } = brand;
    // destructure value property from productDetails for each dropdown type
    const {
      [productFields.cateogry]: productCategory,
      [productFields.quantity]: productQuantityValue,
      [productFields.brand]: productBrand,
    } = productDetails;
    const { value: productCategoryValue } = productCategory;
    const { value: productBrandValue } = productBrand;

    // compare values and update if different
    if (
      categoryValue !== productCategoryValue ||
      quantityValue !== productQuantityValue
    ) {
      doUpdateProductCategory({
        category_id: categoryValue,
        quantity: quantityValue,
      });
    }
    if (brandValue !== productBrandValue) {
      doUpdateProductBrand({ brand_id: brandValue });
    }
  };

  const handleAddImages = e =>
    loadFiles([...Array.from(e.target.files), ...(fileState.files ?? [])]);

  const deleteFileFromDb = file => () => {
    const isSorterPhoto = file[Field_Keys.PARCEL.ID];
    if (isSorterPhoto) {
      showSnackbarWarning('Cannot delete sorter photos ');
      return;
    }
    const description = parseStringModifiers(
      `Are you sure you want to delete this image? This image will be completely removed once you save.

        **This action cannot be undone.**`
    );
    const onConfirm = () => {
      stageDbFilesForDeletion(file);
      dismiss();
    };

    confirmBackToParcel({ onConfirm, description });
  };

  const deleteFileFromMemory = file => () => {
    const description = parseStringModifiers(
      `Are you sure you want to delete this image? This image is new, and you must re-upload it if you change your mind.

        **This action cannot be undone.**`
    );
    const onConfirm = () => {
      removeFileFromFilestate(file);
      dismiss();
    };

    confirmBackToParcel({ onConfirm, description });
  };

  const stageDbFilesForDeletion = file => {
    const fileIndex = photos.dbImages.findIndex(file_ => file_ === file);
    if (fileIndex === -1) return;

    setFilesStagedForDeletion(x => [...x, photos.dbImages[fileIndex]]);

    setPhotos(updateDbImages(fileIndex));
  };

  const confirmBackToParcel = ({ onConfirm, description }) => {
    const title = 'Delete image';
    const confirm = 'Delete';
    const onCancel = dismiss;

    showModal(
      ConfirmModal,
      { title, description, confirm, onCancel, onConfirm },
      { stack: true, keepPrev: true }
    );
  };

  const removeFileFromFilestate = file => {
    const fileIndex = fileState.files.findIndex(file_ => file_ === file);
    if (fileIndex === -1) return;
    loadFiles([
      ...fileState.files.slice(0, fileIndex),
      ...fileState.files.slice(fileIndex + 1),
    ]);
  };

  const updateDbImages = fileIndex => photos => ({
    ...photos,
    dbImages: [
      ...photos.dbImages.slice(0, fileIndex),
      ...photos.dbImages.slice(fileIndex + 1),
    ],
  });

  const updateMemoryImages = fileState => photos => ({
    ...photos,
    memoryImages: [...fileState.files],
  });

  const hasEnoughImages = x => {
    const noOfImagesTotal =
      (photos?.dbImages?.length ?? 0) + (photos?.memoryImages?.length ?? 0);

    const valid = noOfImagesTotal >= (isContracted ? 3 : 1);
    return valid;
  };

  const showGallery = initialSelected => () => {
    showModal(
      Gallery,
      {
        initialSelected,
        images: fileState.files?.map(file => URL.createObjectURL(file)) ?? [],
        id: productId,
        ...GalleryProps[GalleryPages.products],
        appendRequested: true,
      },
      { keepPrev: true }
    );
  };

  const isInspection =
    product?.[productKeys.productStatus] === Constants.Status.Inspection;
  const isReleased =
    product?.[productKeys.productStatus] === Constants.Status.Released;
  const brandChangeIsAllowed = isInspection || isReleased;

  const disabled = {
    [productFields.brand]: !brandChangeIsAllowed,
    [productFields.cateogry]: false,
    [productFields.quantity]: false,
  };

  return (
    <Formik
      initialValues={productDetails ?? productInitialValues}
      validationSchema={productValidationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, dirty, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ => {
            setFieldValue(name, { value, label });
          };

        return (
          <Form>
            <EditProductLayout
              title={
                <CopyableText
                  text={`Product ${productAlias ?? ''}`}
                  copyText={productAlias}
                />
              }
              options={{
                [productFields.brand]: brands,
                [productFields.cateogry]: categories,
              }}
              selectOption={selectOption}
              disabled={disabled}
              onCancel={dismiss}
              isValid={
                (dirty ||
                  filesStagedForDeletion.length ||
                  fileState?.files?.length) &&
                isValid &&
                hasEnoughImages(photos)
              }
              isLoading={updatesLoading.some(l => l)}
              photosProps={{
                values: {
                  ...photos,
                  valid: hasEnoughImages(photos),
                  isContracted,
                },
                handleAddImages,
                deleteFileFromMemory,
                deleteFileFromDb,
                showGallery,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default Modal(EditProduct);
