import { useEffect, useState } from 'react';
import { PropKeys, Utils } from '../../../../../constants';
import { useModal } from '../../../../../providers/ModalProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { useFetchTokens } from '../api/fetchTokens';
import { DevPortalLayout } from '../components/DevPortalLayout';
import DevPortal2FA from './DevPortal2FA';
import { devPortalGrid, devPortalHeaders, noResultsState } from './variables';

const DevPortal = () => {
  const [data, setData] = useState([]);
  const { dismiss, showModal } = useModal();
  const { store } = useStore();

  const brandId = store[PropKeys.brandId];

  const {
    data: tokens,
    isLoading,
    isError,
    error,
    doFetchTokens,
  } = useFetchTokens();

  useDeepCompareEffect(() => {
    if (tokens == null) return;
    setData(tokens);
  }, [tokens]);

  useEffect(() => {
    showModal(DevPortal2FA, {
      onSuccess: key => {
        doFetchTokens(key, brandId);
        dismiss();
      },
    });
  }, []);

  return (
    <DevPortalLayout
      headers={devPortalHeaders}
      data={data}
      grid={devPortalGrid}
      rowAction={Utils.emptyFunction}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={{
        noData: data.length === 0,
        noDataDisplay: noResultsState.devPortal.noData,
        noSearchResultsDisplay: noResultsState.devPortal.noData,
        // no filter setting, so no need to handle noSearchResults
      }}
    />
  );
};
export default DevPortal;
