import { Constants, Field_Keys } from '../../../../../constants';
import { maskEntry } from '../../../../../utils/utils';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';

export const formatReferenceDetails = data => {
  maskEntry(data, mask);

  const caseStatistics = data[referenceIndexKeys.caseStatistics];

  const metrics = [
    {
      value:
        caseStatistics[referenceIndexKeys.casesTotal] ?? Constants.fallback,
      label: 'Total products',
    },
    {
      value:
        caseStatistics[referenceIndexKeys.casesActive] ?? Constants.fallback,
      label: 'Active products',
    },
    {
      value:
        caseStatistics[referenceIndexKeys.casesSeized] ?? Constants.fallback,
      label: 'Seizures',
    },
    {
      value: caseStatistics[referenceIndexKeys.accuracy] ?? Constants.fallback,
      label: 'Accuracy',
    },
    {
      value:
        caseStatistics[referenceIndexKeys.popularLocation] ??
        Constants.fallback,
      label: 'Popular Location',
    },
  ];

  data.metrics = metrics;
  data[referenceIndexKeys.active] = caseStatistics[referenceIndexKeys.active];
  data[referenceIndexKeys.lastSideload] =
    caseStatistics[referenceIndexKeys.lastSideload];
  return data;
};

const mask = {
  [Field_Keys.REFERENCE.NOTES]: referenceIndexKeys.notes.self,
  [Field_Keys.REFERENCE.NOTE]: referenceIndexKeys.notes.note,
  [Field_Keys.REFERENCE.NOTE_CREATING_USER_DISPLAY_NAME]:
    referenceIndexKeys.notes.userName,
  [Field_Keys.REFERENCE.NOTE_CREATING_USER_IMAGE]:
    referenceIndexKeys.notes.userImage,
  [Field_Keys.REFERENCE.NOTE_CREATED_AT]: referenceIndexKeys.notes.dateCreated,

  [Field_Keys.REFERENCE.ID]: referenceIndexKeys.id,
  [Field_Keys.REFERENCE.ALIAS]: referenceIndexKeys.alias,
  [Field_Keys.REFERENCE.NAME]: referenceIndexKeys.name,
  [Field_Keys.REFERENCE.COMPANY]: referenceIndexKeys.company,
  [Field_Keys.REFERENCE.ADDRESS]: referenceIndexKeys.address,
  [Field_Keys.REFERENCE.PHONE]: referenceIndexKeys.phone,
  [Field_Keys.REFERENCE.POSTCODE]: referenceIndexKeys.zipcode,
  [Field_Keys.REFERENCE.CITY]: referenceIndexKeys.city,
  [Field_Keys.REFERENCE.STATE]: referenceIndexKeys.state,
  [Field_Keys.REFERENCE.COUNTRY]: referenceIndexKeys.country,
  [Field_Keys.REFERENCE.TYPE]: referenceIndexKeys.type,
  [Field_Keys.REFERENCE.PRODUCT_STATS]: referenceIndexKeys.caseStatistics,
  total_product_count: referenceIndexKeys.casesTotal,
  active_product_count: referenceIndexKeys.casesActive,
  seized_product_count: referenceIndexKeys.casesSeized,
  accuracy: referenceIndexKeys.accuracy,
  popular_location: referenceIndexKeys.popularLocation,
  has_recent_sideload: referenceIndexKeys.active,
  last_sideload_time: referenceIndexKeys.lastSideload,
  [Field_Keys.REFERENCE.BRAND_NAME]: referenceIndexKeys.brand.name,
  [Field_Keys.REFERENCE.BRAND_IMAGE]: referenceIndexKeys.brand.image,
  [Field_Keys.REFERENCE.BRAND_ID]: referenceIndexKeys.brand.id,
  [Field_Keys.REFERENCE.SIDELOAD_LIMIT]: referenceIndexKeys.weights.type,
  [Field_Keys.REFERENCE.NAME_WEIGHT]: referenceIndexKeys.weights.name,
  [Field_Keys.REFERENCE.COMPANY_WEIGHT]: referenceIndexKeys.weights.company,
  [Field_Keys.REFERENCE.ADDRESS_WEIGHT]: referenceIndexKeys.weights.address,
  [Field_Keys.REFERENCE.POSTCODE_WEIGHT]: referenceIndexKeys.weights.zipcode,
  [Field_Keys.REFERENCE.CITY_WEIGHT]: referenceIndexKeys.weights.city,
  [Field_Keys.REFERENCE.STATE_WEIGHT]: referenceIndexKeys.weights.state,
  [Field_Keys.REFERENCE.COUNTRY_WEIGHT]: referenceIndexKeys.weights.country,
  [Field_Keys.REFERENCE.PHONE_WEIGHT]: referenceIndexKeys.weights.phone,
};
