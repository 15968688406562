import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { mask as productMask } from '../../../features/parcels/api/getParcels';
import { parcelKeys } from '../controllers/variables';

export const useGetParcelDetails = ({ parcelId }) => {
  const [{ data, isLoading = true, isError, error }, _, getParcelDetails_] =
    useFetchData(`parcel/${parcelId}`, { formatter, ignore: parcelId == null });

  const getParcelDetails = parcelId => {
    const url = `parcel/${parcelId}`;
    getParcelDetails_({ url });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    getParcelDetails,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);

  return data;
};

const mask = {
  ...productMask,
  [Field_Keys.PARCEL.PRODUCT_COUNT]: parcelKeys.caseCount,
  [Field_Keys.PARCEL.ALIAS]: parcelKeys.alias,
  [Field_Keys.PARCEL.ID]: parcelKeys.id,
  [Field_Keys.PARCEL.RISK_TYPE]: parcelKeys.riskType,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: parcelKeys.sideloadDate,
  [Field_Keys.PARCEL.STATUS]: parcelKeys.status,
  [Field_Keys.PARCEL.VOLUME]: parcelKeys.parcelVolume,
  [Field_Keys.PARCEL.WEIGHT]: parcelKeys.parcelWeight,
  [Field_Keys.PARCEL.PRODUCTS]: parcelKeys.products,
  [Field_Keys.PARCEL.DETECTION_CONFIDENCE]: parcelKeys.confidence,
  [Field_Keys.PARCEL.TRACKING_NUMBER]: parcelKeys.trackingNumber,
  // should_show_actors,
  [Field_Keys.SORTER.COUNTRY]: parcelKeys.sorterCountry,
  [Field_Keys.SORTER.CITY]: parcelKeys.sorterCity,
  // product keys
  [Field_Keys.PARCEL.LAST_PRODUCT_ACTIVITY]: parcelKeys.lastActivity,
  [Field_Keys.SORTER.NAME]: parcelKeys.sorterName,
  [Field_Keys.PARCEL.PRODUCT_STATUS_DISTRIBUTION]: parcelKeys.statusShares,
  [Field_Keys.PARCEL.ID]: parcelKeys.id,
  [Field_Keys.REFERENCE.BRAND_NAME]: parcelKeys.reason,
  [Field_Keys.PARCEL.OWN_ITEM_QUANTITY]: parcelKeys.ownQuantity,
  [Field_Keys.PARCEL.TOTAL_ITEM_QUANTITY]: parcelKeys.totalQuantity,
};
