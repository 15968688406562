import { useDeepCompareEffect } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { mergeFilterStateAndConsts } from '../../../../../components/organisms/Filters/utils';
import { Constants, Utils } from '../../../../../constants';
import { dispatchAction } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { isEmpty } from '../../../../../lib/js';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { matchImagesToProduct } from '../../../../admin/features/parcels/controllers/utils';
import { useGetProductsImage } from '../../../../admin/features_public/products/api/getProductsImage';
import { useCheckInParcel } from '../api/checkInParcel';
import { useCompleteProcessing } from '../api/completeProcessing';
import { useGetParcel } from '../api/getParcel';
import ProcessingLayout from '../components/ProcessingLayout';
import CheckInInstruction from './CheckInInstruction';
import { useProcessingReducer } from './Processing.hook';
import ProcessingInstruction from './ProcessingInstruction';
import { formatParcelDetails } from './utils';
import {
  DC,
  allOptions,
  filtersConstants,
  processingKeys,
  scanningModeTitle,
  toggleOptions,
} from './variables';
const {
  OFFICE_PARCEL_PROCESSING: { SELF, VIEW_PARCELS },
} = PermissionKeys.Facilitator;

const Processing = () => {
  const [isProcessing, setIsProcessing] = useState(0);
  const [parcelDetails, setParcelDetails] = useState(null);
  const [parcel, setParcel] = useState({});
  // const [updatedConfirm, setUpdatedConfirm] = useState({});

  const [atInspection, setAtInspection] = useState(false);

  const { isModalActive, dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { hasPermission } = usePermissions();
  const { setShowHeader } = useHeader();
  const { setShowFooter } = useFooter();

  const hasViewParcelPermission = hasPermission(SELF, VIEW_PARCELS);

  let options;
  let ownOptions;
  let filtersIsError;

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useProcessingReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const { data, body, isLoading, isError, error, doCheckin } =
    useCheckInParcel();

  const {
    data: parcel_,
    isLoading: parcelIsLoading,
    isError: parcelIsError,
    error: parcelError,
    getParcel,
  } = useGetParcel({});

  const {
    data: productImages,
    isLoading: productImagesIsLoading,
    isError: productImagesIsError,
    error: productImagesError,
    getProductsImage,
  } = useGetProductsImage();

  useDeepCompareEffect(() => {
    if (isLoading || isError) return;
    setParcel(parcel_);

    const products = parcel_?.[processingKeys.products];
    if (products?.length) {
      const product_ids = products?.map(
        product => product[processingKeys.product.id]
      );

      getProductsImage({ product_ids, limit: Constants.noLimit });
    }
  }, [parcel_, parcelIsLoading, parcelIsError]);

  useDeepCompareEffect(() => {
    const matchImages = images => data => {
      const rows = data[processingKeys.products];
      const rowsWithImage = matchImagesToProduct(images)(rows);
      return {
        ...data,
        [processingKeys.products]: rowsWithImage,
      };
    };

    if (!parcel || isEmpty(parcel) || productImagesIsLoading) return;
    if (
      productImagesIsError ||
      // !hasPermission(VIEW_PARCEL_PHOTOS)
      false
    ) {
      if (productImagesIsError) showSnackbarError(productImagesError);
      setParcel(matchImages([]));
      return;
    }

    if (parcel[processingKeys.products]?.length && productImages != null)
      setParcel(matchImages(productImages));
  }, [productImages, parcel]);

  useEffect(() => {
    return () => dismiss();
  }, []);

  useEffect(() => {
    if (isLoading) showParcelModal();
    // if user dismissed modal before finishing load
    if (data == null || !isModalActive || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      dismiss();
      return;
    }
    setParcelDetails({
      ...data,
    });

    const url = `office_parcel/${data[processingKeys.id]}`;
    if (isProcessing && data[processingKeys.id]) getParcel({ url });
  }, [data, body, isLoading, isError]);

  const triggerSearch = (_, { resetForm } = {}) => {
    const type = 'Check_In';
    const body = { barcode: searchParams.searchValue, type };
    doCheckin(body);
    setParcelDetails(null);
    if (typeof resetForm === 'function') resetForm();
  };

  useEffect(() => {
    if (parcelDetails == null || !isModalActive) return;

    showParcelModal(atInspection);
  }, [parcelDetails, parcel]);

  useEffect(() => {
    setShowHeader(false);
    setShowFooter(false);
  }, []);

  const showParcelModal = atInspection => {
    if (!hasViewParcelPermission) return;
    showModal(
      isProcessing ? ProcessingInstruction : CheckInInstruction,
      {
        details: parcelDetails ? formatParcelDetails(parcelDetails, body) : {},
        isLoading,
        parcel,
        getParcel,
        parcelIsLoading,
        parcelIsError,
        parcelError,
        setAtInspection,
        atInspection,
        onCompleteInspectionSuccess: triggerSearch,
      },
      { xDismiss: true, ignoreOverlayDismiss: true, avoidScale: true }
    );
  };

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
      handleSearchEnter: Utils.emptyFunction,
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const selectScanningMode = (option, index) => () => {
    setIsProcessing(index);
  };

  const {
    data: completeData,
    body: completeBody,
    isLoading: completeIsLoading,
    isError: completeIsError,
    completeProcessing,
  } = useCompleteProcessing();

  useEffect(() => {
    if (completeData == null || completeIsLoading) return;
    if (completeIsError) {
      showSnackbarError();
      return;
    }
    showSnackbarSuccess('Confirmation successful');
    // setUpdatedConfirm(completeData);
  }, [completeBody, completeIsLoading, completeIsError]);

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(_, actions) => triggerSearch(_, actions)}
    >
      {() => {
        return (
          <Form>
            <ProcessingLayout
              isProcessing={isProcessing}
              selectScanningMode={selectScanningMode}
              toggleOptions={toggleOptions}
              scanningModeTitle={scanningModeTitle[!!isProcessing]}
              filters={filters}
              searchValue={searchParams[DC.SEARCH]}
              isModalActive={isModalActive}
              hasViewParcelPermission={hasViewParcelPermission}
              confirmationAction={() => completeProcessing()}
              // updatedConfirm={updatedConfirm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Processing;
