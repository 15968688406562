import NoSearchResults from '../../../../../components/templates/NoSearchResults/NoSearchResults';
import SubViews from '../../../../../components/templates/SubViews/SubViews';
import { defaultOnlySingleBrand } from '../../../../../components/templates/TableStatus/variables';
import { Labels, Pages } from '../../../routes/variables';
import { NoResultsCSS } from './style';

const BrandSettingsLayout = ({ components, options, styles, disabled }) => {
  return disabled ? (
    <NoResultsCSS>
      <NoSearchResults {...defaultOnlySingleBrand} />
    </NoResultsCSS>
  ) : (
    <SubViews
      options={options}
      styles={styles}
      components={components}
      title={Labels[Pages.brand_settings]}
    />
  );
};

export default BrandSettingsLayout;
