import { Colors, StatusColors } from '../../../../../components/style';
import { Constants, Field_Keys } from '../../../../../constants';
import { getBargraphKeys, maskEntry } from '../../../../../utils/utils';
import { dashboardKeys } from '../variables';

export const formatPopulars = data => {
  if (data == null || !data.length) return inactiveBarchart();
  try {
    maskEntry(data, mask);
    data = data
      .reduce((arr, location) => {
        const { Inspections, Stops, Seizures } = location;

        const displayTitle =
          location[dashboardKeys.sorter] ||
          location[dashboardKeys.referenceName] ||
          location[dashboardKeys.referenceCompany] ||
          location[dashboardKeys.referenceAddress] ||
          location[dashboardKeys.brandName];

        if (
          !displayTitle ||
          (!Inspections && isNaN(Inspections)) ||
          (!Stops && isNaN(Stops)) ||
          (!Seizures && isNaN(Seizures))
        )
          return arr;
        return [
          ...arr,
          {
            name: displayTitle,
            Inspections,
            Stops,
            Seizures,
          },
        ];
      }, [])
      .slice(0, 3);

    const { keys, bars } = getBargraphKeys(data[0], [
      StatusColors.Inspection,
      StatusColors.Stopped,
      StatusColors.Seized,
    ]);

    return { data, keys, bars };
  } catch {
    return inactiveBarchart();
  }
};

export const inactiveBarchart = () => {
  const data = [
    {
      name: Constants.fallback,
      Inspections: 100,
      Stops: 70,
      Seizures: 55,
    },
    {
      name: Constants.fallback,
      Inspections: 100,
      Stops: 70,
      Seizures: 55,
    },
    {
      name: Constants.fallback,
      Inspections: 100,
      Stops: 70,
      Seizures: 55,
    },
  ];

  const { keys, bars } = getBargraphKeys(data[0], [
    Colors.Neutral._200,
    Colors.Neutral._200,
    Colors.Neutral._200,
  ]);

  return { data, keys, bars };
};

const mask = {
  [Field_Keys.SORTER.NAME]: dashboardKeys.sorter,
  [Field_Keys.REFERENCE.NAME]: dashboardKeys.referenceName,
  [Field_Keys.REFERENCE.COMPANY]: dashboardKeys.referenceCompany,
  [Field_Keys.REFERENCE.ADDRESS]: dashboardKeys.referenceAddress,
  [Field_Keys.PRODUCT.BRAND_NAME]: dashboardKeys.brandName,
};
