import { Field_Keys } from '../../../../../constants';
import {} from '../../../../../utils/stringUtils';
import { howLongAgo } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { dashboardKeys, statusNoun } from '../variables';

export const formatActivity = data => {
  maskEntry(data, mask);

  data = data.slice(0, 6);

  data = data.map((entry, index) => {
    const {
      [dashboardKeys.sorterCity]: city,
      [dashboardKeys.sorterCountry]: country,
      [dashboardKeys.caseStatus]: status,
      [dashboardKeys.activityLogDate]: logDate,
    } = entry;

    const location = city ? city : country ? country : null;
    const isLast = index === data.length - 1;
    const isFirst = index === 0;

    const info = `${statusNoun[status]} in ${location}`;

    const date = ` • ${howLongAgo(logDate)}`;

    return {
      info,
      date,
      isFirst,
      isLast,
      [dashboardKeys.caseAlias]: entry[dashboardKeys.caseAlias],
    };
  });

  return data;
};

export const inactiveActivity = () => {
  const min = 1000 * 60;
  const hour = min * 60;
  const day = hour * 24;

  return [
    {
      inactive: true,
      info: 'Inspection in Netherlands • 30 minutes ago',
      date: ` • ${howLongAgo(new Date(new Date().getTime() - min * 30))}`,
      isFirst: true,
    },
    {
      inactive: true,
      info: 'Inspection in Paris • 4 hours ago',
      date: ` • ${howLongAgo(new Date(new Date().getTime() - hour * 4))}`,
    },
    {
      inactive: true,
      info: 'Authentication requested in Paris • 1 day ago',
      date: ` • ${howLongAgo(new Date(new Date().getTime() - day))}`,
    },
    {
      inactive: true,
      info: 'Authentication requested in Netherlands • 2 days ago',
      date: ` • ${howLongAgo(new Date(new Date().getTime() - day * 2))}`,
    },
    {
      inactive: true,
      info: 'Authentication requested in Paris • 3 days ago',
      date: ` • ${howLongAgo(new Date(new Date().getTime() - day * 3))}`,
    },
    {
      inactive: true,
      info: 'Stop in Netherlands • 1 week ago',
      date: ` • ${howLongAgo(new Date(new Date().getTime() - day * 7))}`,
      isLast: true,
    },
  ];
};

const mask = {
  [Field_Keys.PRODUCT.STATUS]: [dashboardKeys.caseStatus],
  [Field_Keys.SORTER.CITY]: [dashboardKeys.sorterCity],
  [Field_Keys.SORTER.COUNTRY]: [dashboardKeys.sorterCountry],
  [Field_Keys.PRODUCT.STATUS_UPDATED_AT]: [dashboardKeys.activityLogDate],
  [Field_Keys.PRODUCT.ID]: [dashboardKeys.caseId],
  [Field_Keys.PRODUCT.ALIAS]: [dashboardKeys.caseAlias],
};
