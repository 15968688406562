import { formatStatusShares } from '../../../../../components/molecules/StatusShares/utils';
import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const useGetCases = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { parcels = [], parcel_count: caseCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('parcels', {
    params,
    ignore,
    large: true,
  });

  return {
    data: { parcels: formatCases(parcels), caseCount },
    isLoading,
    isError,
    error,
    getProducts,
    refreshTable,
  };
};

const formatCases = data => {
  data = maskEntry(data, mask);
  const formattedData = data.map(row => ({
    ...row,
    [caseKeys.statusShares]: formatStatusShares(row[caseKeys.statusShares]),
  }));

  return formattedData;
};

export const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    PARCEL_STATUS,
    LOCATION,
    BRAND,
    PAGE,
    ITEMS_PER_PAGE,
    RISK_TYPE,
    include_sorter_products,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const status = extractSelectedValues(PARCEL_STATUS);
  const sorter_id = extractSelectedValues(LOCATION);
  const brand_id = extractSelectedValues(BRAND);
  const risk_type = extractSelectedValues(RISK_TYPE);

  const formattedParams = {
    search,
    status,
    sorter_id,
    brand_id,
    risk_type,
    include_sorter_products,
    from_date,
    to_date,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  if (formattedParams?.status?.includes('Stopped'))
    formattedParams?.status?.push('Customs');
  if (
    formattedParams?.status?.includes('Customs') &&
    !formattedParams?.status?.includes('Stopped')
  )
    formattedParams.status = formattedParams?.status?.filter(
      x => x !== 'Customs'
    );

  return formattedParams;
};

export const mask = {
  [Field_Keys.PARCEL.PRODUCT_COUNT]: caseKeys.productQuantity,
  [Field_Keys.PARCEL.LAST_PRODUCT_ACTIVITY]: caseKeys.lastActivity,
  [Field_Keys.PARCEL.OWN_ITEM_QUANTITY]: caseKeys.ownQuantity,
  [Field_Keys.PARCEL.ALIAS]: caseKeys.alias,
  [Field_Keys.PARCEL.ID]: caseKeys.id,
  [Field_Keys.PARCEL.STATUS]: caseKeys.status,
  [Field_Keys.PARCEL.SIDELOAD_DATE]: caseKeys.sideloadDate,
  [Field_Keys.PARCEL.TOTAL_ITEM_QUANTITY]: caseKeys.totalQuantity,
  [Field_Keys.SORTER.NAME]: caseKeys.sorter,
  [Field_Keys.SORTER.CITY]: caseKeys.city,
  [Field_Keys.PARCEL.PRODUCT_STATUS_DISTRIBUTION]: caseKeys.statusShares,
  [Field_Keys.PARCEL.PRODUCTS]: caseKeys.products,
  [Field_Keys.SORTER.ID]: caseKeys.sorterId,
  [Field_Keys.PARCEL.RISK_TYPE]: caseKeys.riskType,
  [Field_Keys.PARCEL.TRACKING_NUMBER]: caseKeys.trackingNumber,
  // products
  [Field_Keys.PRODUCT.BRAND_ID]: productKeys.brandId,
  [Field_Keys.PRODUCT.BRAND_NAME]: productKeys.brand,
  [Field_Keys.PRODUCT.BRAND_IMAGE]: productKeys.brandImage,
  [Field_Keys.PRODUCT.ALIAS]: productKeys.productAlias,
  [Field_Keys.PRODUCT.ID]: productKeys.productId,
  [Field_Keys.PRODUCT.STATUS_UPDATED_AT]: productKeys.lastActivity,
  [Field_Keys.CATEGORY.NAME]: productKeys.category,
  [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: productKeys.subCategory,
  [Field_Keys.PRODUCT.ITEM_QUANTITY]: productKeys.itemQuantity,
  [Field_Keys.PRODUCT.IS_PRODUCT_OWNER]: productKeys.isProductOwner,
  [Field_Keys.PRODUCT.IS_SORTER_PRODUCT]: productKeys.isSorterCase,
  [Field_Keys.PRODUCT.STATUS]: productKeys.productStatus,
};
