import useDeleteData from '../../../../../hooks/deleteHook';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';

export const useDeleteReference = ({
  [referenceIndexKeys.id]: referenceId,
  isAdmin,
}) => {
  const [{ data, params, isLoading, isError, error }, doDelete] = useDeleteData(
    `${isAdmin ? 'admin_' : ''}reference/${referenceId}`
  );

  return {
    data,
    params,
    isLoading,
    isError,
    error,
    doDelete,
  };
};
