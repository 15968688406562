import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { processingKeys } from '../controllers/variables';

export const useGetParcel = ({ id }) => {
  const [{ data, isLoading, isError, error }, _, getParcel] = useFetchData('', {
    formatter,
    ignore: id == null,
  });

  return { data, isLoading, isError, error, getParcel };
};

const formatter = data => {
  maskEntry(data, mask);
  data[processingKeys.productCount] = data[processingKeys.products].length;
  return data;
};

const mask = {
  // actors:
  // barcodes:
  // caSenderType:
  [Field_Keys.PARCEL.PRODUCTS]: processingKeys.products,
  [Field_Keys.PARCEL.PRODUCT_COUNT]: processingKeys.productCount,
  // createdAt:
  [Field_Keys.PARCEL.ALIAS]: processingKeys.alias,
  // paHeight:
  [Field_Keys.PARCEL.ID]: processingKeys.id,
  // paLength:
  // paRiskType:
  [Field_Keys.PARCEL.SIDELOAD_DATE]: processingKeys.sideloadDate,
  [Field_Keys.PARCEL.STATUS]: processingKeys.status,
  // paTracking:
  // paVolume:
  // paWeight:
  // paWidth:
  // pbBarcode: ,
  // pdConfidence:
  // pdMatchText:
  // pdMatchType:
  // pdOCR:
  // pdSenderFull:
  [Field_Keys.PARCEL.PHOTOS]: 'photos',
  // seCompany:
  // seCountry:
  // seName:
  // soCity:
  // soCountry:
  // soID:
  // soName:
  [Field_Keys.PARCEL.TOTAL_ITEM_QUANTITY]: processingKeys.product.itemCount,

  // products
  [Field_Keys.PRODUCT.BRAND_ID]: processingKeys.product.brand.id,
  [Field_Keys.PRODUCT.BRAND_NAME]: processingKeys.product.brand.name,
  [Field_Keys.PRODUCT.ALIAS]: processingKeys.product.alias,
  [Field_Keys.PRODUCT.ID]: processingKeys.product.id,
  [Field_Keys.CATEGORY.SUBCATEGORY_ID]: processingKeys.product.categoryId,
  [Field_Keys.PRODUCT.IS_BRAND_CONTRACTED]:
    processingKeys.product.brand.isContracted,
  [Field_Keys.PRODUCT.ITEM_QUANTITY]: processingKeys.product.itemCount,
  [Field_Keys.CATEGORY.NAME]: processingKeys.product.category,
  [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: processingKeys.product.subcategory,
  [Field_Keys.PRODUCT.STATUS]: processingKeys.product.status,
};
