import { PropKeys } from '../../../../../constants';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import BrandSettingsLayout from '../components/BrandSettingsLayout';
import { styles } from '../components/style';
import Billing from './Billing';
import Contracts from './Contracts';
import DevPortal from './DevPortal';
import EditBrandLogo from './EditBrandLogo';
import Users from './Users';
import { options as allOptions, optionViews } from './variables';

const { SELF } = PermissionKeys.Brand.BRAND_MANAGEMENT;
const { SELF: DEV_PORTAL_SELF } = PermissionKeys.Brand.DEVELOPER_PORTAL;

const BrandSettings = () => {
  const { hasPermission } = usePermissions();
  const { store } = useStore();

  const isAllBrands = !store[PropKeys.brandId];

  const components = {
    [optionViews.brandLogo]: <EditBrandLogo />,
    [optionViews.billing]: <Billing />,
    [optionViews.users]: <Users />,
    [optionViews.contracts]: <Contracts />,
    [optionViews.devPortal]: <DevPortal />,
  };

  const withPermissions = options =>
    options.filter(
      ({ permission }) =>
        hasPermission(SELF, permission) ||
        hasPermission(DEV_PORTAL_SELF, permission)
    );

  const options = withPermissions(allOptions);

  return (
    <BrandSettingsLayout
      options={options}
      components={components}
      styles={styles}
      disabled={isAllBrands}
    />
  );
};

export default BrandSettings;
