import { matchImagesToRows } from '../../../../../components/organisms/Table/utils';
import { Constants } from '../../../../../constants';
import { intersection } from '../../../../../lib/js';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { statusActions } from './variables';

export const matchImagesToParcels = matchImagesToRows(
  [parcelKeys.id],
  'parcel_id'
);

export const matchImagesToProduct = matchImagesToRows(
  [parcelKeys.product.id],
  'product_id'
);

const { Sideload, Inspection, Authentication, Stopped, Seized, Released } =
  Constants.Status;

export const getParcelStatusActions = statusList => {
  const allowedStatus = statusList.map(status => statusActions[status]);

  return intersection(...allowedStatus);
};

export const sendsEmail = {
  [Authentication]: false,
  [Stopped]: false,
  [Seized]: false,
  [Released]: false,
};
