import { Constants, Field_Keys, PropKeys } from '../../constants';
import { API } from '../../lib/api';
import { formatClient } from './getClients';

const { Logistics, Brand } = Constants;

export const getUserInfo = async ({ token, saveUserInfo }) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const { data, status, error } = await API.GET('user_profile', config);

  if (status !== 200 || error) return;
  saveUserInfo(format(data));
};

const format = data => {
  if (data == null) return {};
  // pulls from ./getClients to ensure naming consistency of properties
  const formattedClientData = formatClient(data);
  const formattedUserData = formatUser(data);
  const formattedData = { ...formattedClientData, ...formattedUserData };

  return formattedData;
};

const formatUser = data => {
  if (data == null) return {};
  return {
    [PropKeys.email]: data[Field_Keys.USER.EMAIL],
    [PropKeys.phone]: data[Field_Keys.USER.PHONE],
    [PropKeys.userImage]: data[Field_Keys.USER.IMAGE],
    [PropKeys.username]: data[Field_Keys.USER.DISPLAY_NAME],
    [PropKeys.firstName]: data[Field_Keys.USER.FIRST_NAME],
    [PropKeys.lastName]: data[Field_Keys.USER.LAST_NAME],
  };
};
