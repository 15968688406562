import usePostData from '../../../../../hooks/postHook';

export const useUpdateProductStatus = ({ id }) => {
  const [{ data, body, isLoading, isError, error }, doProductStatusUpdate] =
    usePostData('feed url from options');

  const statusUpdate = async ({ status, ...rest }) => {
    const body = { status };
    const url = `product/${id}/status`;
    return await doProductStatusUpdate({ ...body, ...rest }, url);
  };

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doProductStatusUpdate: statusUpdate,
  };
};
