import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';

export const referenceIndexKeys = {
  accuracy: 'accuracy',
  active: 'active',
  addedBy: 'addedBy',
  addedByImage: 'addedByImage',
  address: 'address',
  additionalDetails: 'details',
  alias: 'alias',
  caseStatistics: 'caseStatistics',
  casesActive: 'casesActive',
  casesTotal: 'casesTotal',
  casesSeized: 'casesSeized',
  city: 'city',
  company: 'company',
  country: 'country',
  dateCreated: 'dateCreated',
  id: 'reference_id',
  lastSideload: 'lastSideload',
  name: 'name',
  notes: 'notes',
  leftPadding: 'leftPadding',
  phone: 'phone',
  popularLocation: 'popularLocation',
  state: 'state',
  zipcode: 'postcode',
  type: 'type',
  brand: {
    self: 'brand',
    name: 'brand.name',
    image: 'brand.image',
  },
};

const keys = referenceIndexKeys;

export const headers = {
  [keys.leftPadding]: '',
  [keys.name]: 'Name',
  [keys.alias]: 'Reference ID',
  [keys.brand.self]: 'Brand',
  [keys.company]: 'Company',
  [keys.country]: 'Country',
  [keys.casesSeized]: 'Seizures',
  [keys.dateCreated]: 'Date created',
  [keys.addedBy]: 'Added By',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  COUNTRY: 'COUNTRY',
  ADDED_BY: 'ADDED_BY',
  ONLY_ACTIVE: 'ONLY_ACTIVE',
  BRANDS: 'BRANDS',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
  },
  [DC.COUNTRY]: {
    type: FilterTypes.dropdown,
    icon: Icons.MapPin,
    param: 'country',
  },
  [DC.BRANDS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Building,
    param: 'ownBrandId',
  },
  [DC.ADDED_BY]: {
    type: FilterTypes.dropdown,
    icon: Icons.User,
  },
  [DC.ONLY_ACTIVE]: {
    type: FilterTypes.toggle,
    title: 'Only active',
    icon: Icons.Timer,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const initialFilterValues = {
  [DC.BRANDS]: '',
  [DC.COUNTRY]: '',
  [DC.ADDED_BY]: '',
  [DC.ONLY_ACTIVE]: '',
};

export const titles = {
  [DC.BRANDS]: 'Customer',
  [DC.COUNTRY]: 'Country',
  [DC.ADDED_BY]: 'Added by',
  [DC.ONLY_ACTIVE]: 'Show only active',
};

export const allOptions = {
  [DC.BRANDS]: { label: 'All customers', value: Constants.all },
  [DC.COUNTRY]: { label: 'All', value: Constants.all },
  [DC.ADDED_BY]: { label: 'All', value: Constants.all },
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
      // paperweight //
      16px
      // name //
      minmax(102px, 1fr)
      // alias //
      minmax(166px, 0.75fr)
      // brand //
      minmax(102px, 1fr)
      // company //
      minmax(102px, 1fr)
      // country //
      minmax(102px, 1fr)
      //  seizures  //
      minmax(72px, 0fr)
      //  date  //
      96px
      // addedBy  //
      minmax(102px, 1fr)`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.ReferenceIndex,
    title: 'No references',
    description: 'To start catching parcels, add some reference data',
    button: {
      text: 'Add reference data',
      variant: 'Primary',
      size: '_M',
      onClick,
    },
  }),
  noSearchResults: {
    icon: NoResults.ReferenceIndex,
    title: 'No results found',
    description: 'Please try another search or view all references',
  },
};

export const referenceCasesNoResultsState = {
  noData: {
    icon: NoData.ReferenceCases,
    subDescription: `The products related to this reference 
    will be shown here.`,
  },
};
