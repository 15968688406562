import {
  Icons,
  Illustrations,
  NoData,
  NoResults,
} from '../../../../components/atoms/Icon/Icon.options';
import { createStatusBadgeOptions } from '../../../../components/organisms/Filters/utils';
import { Colors, Layout } from '../../../../components/style';
import { Constants, Field_Keys } from '../../../../constants';
import { cleanGridStyle } from '../../../../utils/styleUtils';
import { parseStringModifiers } from '../../../../utils/utils';
import { productKeys } from '../../features_public/products/controllers/variables';
import { DC as _DC } from '../products/controllers/variables';

export const DC = _DC;

export const authenticationKeys = {
  space: 'space',
  alias: productKeys.productAlias,
  category: 'category',
  subCategory: 'subCategory',
  itemQuantity: productKeys.productQuantity,
  id: productKeys.productId,
  brand: {
    self: 'brand',
    name: productKeys.brandName,
    logo: productKeys.brandImage,
    id: 'id',
    // isContracted: 'isContracted',
  },
  parcel: {
    self: 'parcel',
    id: productKeys.parcelId,
  },
  photos: 'photos',
  remainingAuthTime: 'remainingAuthTime',
  status: productKeys.productStatus,
  feedback: {
    self: 'feedback',
    answer: {
      self: 'answer',
      id: 'a.id',
      title: 'a.title',
      description: 'a.description',
      hasNote: 'a.hasNote',
    },
    question: {
      self: 'question',
      id: 'q.id',
      title: 'q.title',
      description: 'q.description',
    },
  },
  hasSupportTickets: productKeys.hasSupportTickets,
};

const keys = authenticationKeys;

export const headers = {
  [keys.space]: '',
  [keys.photos]: 'Product ID',
  [keys.alias]: '',
  [keys.category]: 'Product category',
  [keys.itemQuantity]: 'Items',
  [keys.brand.self]: 'Brand',
  [keys.remainingAuthTime]: 'Remaining time',
  [keys.status]: 'Results',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
    //  space //
    16px
    //  photo //
    56px
    //  alias //
    minmax(60px, 160px)
    // category //
    minmax(136px, 1fr)
    // items //
    60px
    // brand //
    minmax(140px, 0.6fr)
    // remaining time / auth results //
    minmax(148px, 168px)
    `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const relatedProductsHeaders = {
  [productKeys.leftPadding]: '',
  [productKeys.productImage]: 'Product ID',
  [productKeys.productAlias]: '',
  [productKeys.productName]: 'Product category',
  [productKeys.productQuantity]: 'Items',
  [productKeys.productStatus]: 'Status',
};

export const relatedProductsGrid = {
  gridTemplateColumns: cleanGridStyle(`
  //  space //
  8px
  //  photo //
  56px
  //  alias //
  minmax(60px, 144px)
  // category //
  minmax(106px, 1fr)
  // items //
  60px
  // status //
  minmax(148px, 168px)
  `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const mockData = [
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77COUNTER',
    [keys.subCategory]: 'Boots',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '29',
    [keys.status]: Constants.Status.Counterfeit,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77145442',
    [keys.subCategory]: 'Footwear',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '40',
    [keys.status]: Constants.Status['Non-counterfeit'],
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC771AUTH',
    [keys.subCategory]: 'Sandals',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '140',
    [keys.status]: Constants.Status.Authentication,
    [keys.parcel.id]: 'feec5238-a616-4a75-a966-ecc050ab5690',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77145442',
    [keys.subCategory]: 'Footwear',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '360',
    [keys.status]: Constants.Status['Non-counterfeit'],
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77COUNTER',
    [keys.subCategory]: 'Boots',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '800',
    [keys.status]: Constants.Status.Counterfeit,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC771AUTH',
    [keys.subCategory]: 'Sandals',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '29',
    [keys.status]: Constants.Status.Authentication,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77COUNTER',
    [keys.subCategory]: 'Boots',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '40',
    [keys.status]: Constants.Status.Counterfeit,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77145442',
    [keys.subCategory]: 'Footwear',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '140',
    [keys.status]: Constants.Status['Non-counterfeit'],
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC771AUTH',
    [keys.subCategory]: 'Sandals',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '360',
    [keys.status]: Constants.Status.Authentication,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC771AUTH',
    [keys.subCategory]: 'Sandals',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '800',
    [keys.status]: Constants.Status.Authentication,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77COUNTER',
    [keys.subCategory]: 'Boots',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '29',
    [keys.status]: Constants.Status.Counterfeit,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC771AUTH',
    [keys.subCategory]: 'Sandals',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '40',
    [keys.status]: Constants.Status.Authentication,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77COUNTER',
    [keys.subCategory]: 'Boots',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '140',
    [keys.status]: Constants.Status.Counterfeit,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC77145442',
    [keys.subCategory]: 'Footwear',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '360',
    [keys.status]: Constants.Status['Non-counterfeit'],
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
  {
    [keys.photos]: [],
    [keys.alias]: 'FRE-CC771AUTH',
    [keys.subCategory]: 'Sandals',
    [keys.category]: 'Footwear',
    [keys.itemQuantity]: 7,
    [keys.brand.logo]: 'https://picsum.photos/200',
    [keys.brand.name]: 'Nike',
    [keys.remainingAuthTime]: '800',
    [keys.status]: Constants.Status.Authentication,
    [keys.parcel.id]: '545c41c1-98dc-47d3-a626-9c12e312b778',
  },
];
export const achievementTypes = {
  gold: 'gold',
  silver: 'silver',
  bronze: 'bronze',
  failed: 'failed',
};

export const achievementBadge = {
  [achievementTypes.gold]: Illustrations.AchievementGold,
  [achievementTypes.silver]: Illustrations.AchievementSilver,
  [achievementTypes.bronze]: Illustrations.AchievementBronze,
  [achievementTypes.failed]: Icons.AlarmClock,
};

export const achievementDescriptionFallback = {
  title: 'Oops!',
  description: "We couldn't find any data for this achievement",
};

export const achievementDescription = {
  [achievementTypes.gold]: {
    title: 'Champion-level authentication!',
    description:
      'Congratulations! You completed the required authentications within 4 hours.',
  },
  [achievementTypes.silver]: {
    title: "You're as fast as the wind!",
    description:
      'Excellent work! You completed the required authentications between 4 and 12 hours.',
  },
  [achievementTypes.bronze]: {
    title: 'Timely authentication!',
    description:
      'Good job! You completed the required authentications between 12 and 24 hours.',
  },
  [achievementTypes.failed]: {
    title: "Oops! You've exceeded the time limit.",
    description:
      "Don't miss the opportunity to authenticate your products within 24 hours.",
  },
  undefined: achievementDescriptionFallback,
};

export const achivementStyles = {
  [achievementTypes.gold]: {},
  [achievementTypes.silver]: {},
  [achievementTypes.bronze]: {},
  [achievementTypes.failed]: { top: Layout.Spacing._XL },
};

export const achievementColors = {
  [achievementTypes.gold]: Colors.Primary._600,
  [achievementTypes.silver]: Colors.Primary._600,
  [achievementTypes.bronze]: Colors.Primary._600,
  [achievementTypes.failed]: Colors.Error._700,
};

export const initialSliderState = {
  activeSlideIndex: 0,
  atStart: true,
  atEnd: false,
  productsBeingAuthenticated: [],
  parcel_results: {},
};

export const parcelMask = {
  [Field_Keys.CATEGORY.NAME]: productKeys.subCategory,
  [Field_Keys.BRAND.ID]: keys.brand.id,
  [Field_Keys.PRODUCT.ALIAS]: productKeys.productAlias,
  [Field_Keys.PRODUCT.ID]: keys.id,
  // cpID: ,
  [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: productKeys.category,
  [Field_Keys.PRODUCT.ITEM_QUANTITY]: productKeys.itemQuantity,
  // reason: ,
  [Field_Keys.PRODUCT.STATUS]: productKeys.productStatus,
};

export const searchParams = ownProducts => ({
  [DC.PRODUCT_STATUS]: [
    {
      section: DC.OTHERS,
      options: createStatusBadgeOptions(
        [
          Constants.Status.Authentication,
          Constants.Status.Counterfeit,
          Constants.Status['Non-counterfeit'],
        ],
        true
      ),
    },
  ],
  [DC.ITEMS_PER_PAGE]: Constants.noLimit,
  ...ownProducts,
});

export const feedbackModalTitle = {
  [Constants.Status.Counterfeit]: 'Counterfeit feedback',
  [Constants.Status['Non-counterfeit']]: 'Non-counterfeit feedback',
};

export const confirmModalTitle = {
  [Constants.Status.Counterfeit]: 'Confirm counterfeit',
  [Constants.Status['Non-counterfeit']]: 'Confirm non-counterfeit',
};

export const confirmModalDescription = {
  [Constants.Status.Counterfeit]:
    parseStringModifiers(`**Are you sure you want to mark this product as counterfeit?**

  If you mark this product as counterfeit, it will be stopped and delivered by the legal authorities for seizure and destruction.`),
  [Constants.Status['Non-counterfeit']]:
    parseStringModifiers(`**Are you sure you want to mark this product as non-counterfeit?**

  If you mark this product as non-counterfeit, it will be released to the original receiver.`),
};

const feedbackModalValues = {
  [Constants.Status.Counterfeit]: {
    lowQuality: 'low_quality_counterfeit',
    highQuality: 'high_quality_counterfeit',
  },
  [Constants.Status['Non-counterfeit']]: {
    genuineProduct: 'genuine_product',
    grayMarketProduct: 'gray_market_product',
    noIPR: 'no_ipr',
    testPurchase: 'test_purchase',
    canNotTell: 'can_not_tell',
  },
};

export const feedbackModalOptions = {
  [Constants.Status.Counterfeit]: [
    {
      label: 'Low quality counterfeit',
      id: feedbackModalValues[Constants.Status.Counterfeit].lowQuality,
    },
    {
      label: 'High quality counterfeit',
      id: feedbackModalValues[Constants.Status.Counterfeit].highQuality,
    },
  ],
  [Constants.Status['Non-counterfeit']]: [
    {
      label: 'Genuine product',
      id: feedbackModalValues[Constants.Status['Non-counterfeit']]
        .genuineProduct,
    },
    {
      label: 'Gray market product',
      id: feedbackModalValues[Constants.Status['Non-counterfeit']]
        .grayMarketProduct,
    },
    {
      label: 'No IPR',
      id: feedbackModalValues[Constants.Status['Non-counterfeit']].noIPR,
    },
    {
      label: 'Test purchase',
      id: feedbackModalValues[Constants.Status['Non-counterfeit']].testPurchase,
    },
    {
      label: 'Can not tell for sure',
      id: feedbackModalValues[Constants.Status['Non-counterfeit']].canNotTell,
    },
  ],
};

export const feeedbackModalInitialState = {
  [Constants.Status.Counterfeit]: {
    value: feedbackModalValues[Constants.Status.Counterfeit].lowQuality,
    subtitle:
      'How can you describe the general quality of this counterfeit product?',
  },
  [Constants.Status['Non-counterfeit']]: {
    value:
      feedbackModalValues[Constants.Status['Non-counterfeit']].genuineProduct,
    subtitle:
      'Could you explain the reason why you marked this product as non-counterfeit?',
  },
};

export const statusIcons = {
  [Constants.Status.Counterfeit]: Icons.PackageX,
  [Constants.Status.Authentication]: Icons.HelpCircle,
  [Constants.Status['Non-counterfeit']]: Icons.PackageDestination,
};

export const productActionTakenTooltipContent = {
  [Constants.Status.Counterfeit]:
    parseStringModifiers(`This product has been confirmed as counterfeit
     and will be handed over to the relevant authority.`),
  [Constants.Status.Authentication]:
    'Support has been requested for this authentication.',
  [Constants.Status['Non-counterfeit']]: parseStringModifiers(
    `This product has been confirmed as non-counterfeit
     and will be released soon.`
  ),
};

export const noResultsState = {
  noData: {
    icon: NoData.Authentication,
    title: 'No authentications',
    description:
      'As soon as there are new products to authenticate, you will find them here.',
  },
  noSearchResults: {
    icon: NoResults.Authentication,
    title: 'Well done!',
    description: 'You have reviewed all the required authentications!',
  },
};
