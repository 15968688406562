import styled from '@emotion/styled';
import { SubtableCSS } from '../../../../../components/organisms/Table/Table.exports';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';

export const styles = `
    .users,
    .devportal,
    .contracts {
      height: 100%;
      #tableBody {
        cursor: unset !important;
      }
    }
`;

export const EditBrandLogoContent = styled.div`
  grid-column: 1 / 13;
  width: 100%;
  height: 545px;
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;

export const Actions = styled.div`
  display: flex;
  ${props => (props.left ? 'margin-right: auto' : 'margin-left: auto')};
  gap: ${Layout.Spacing._S};
`;

export const BillingContainer = styled(UtilityComponents.Grid)`
  grid-template-rows: 352px 342px;
  .card {
    height: 100%;
  }
  .plan {
    grid-column: 1 / 5;
    grid-row: 1;
    .label {
      margin-right: auto;
    }
  }
  .payment {
    grid-column: 5 / 9;
    grid-row: 1;
  }
  .invoices {
    grid-column: 1 / 9;
    grid-row: 2;
  }
`;

export const PlanDetailCSS = styled.div`
  padding: ${Layout.Spacing._2XS} 0;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flex};
`;

export const InvoicesTableContainer = styled.div`
  flex-grow: 1;
  ${Styles.flexColumn};
  .invoices-table {
    margin-bottom: 0;
    > div {
      gap: ${Layout.Spacing._S};
    }
  }
`;

export const UserServicesSubtableCSS = styled(SubtableCSS)`
  .table-header._3 {
    span {
      overflow: unset !important;
    }
  }
  .table-cell._1 .label {
    width: 0;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

export const AddUserActionsCSS = styled.div`
  margin-top: auto;
  align-self: flex-end;
`;

export const BillingNoResultsCSS = styled.div`
  flex-grow: 1;
  ${Styles.flexColumn};
  .no-search-results {
    margin: auto;
  }
`;

const ServiePermissionLayout = styled.div`
  display: flex;
  position: relative;
  align-items: start;
  justify-content: space-between;
`;

export const ServicePermissionContainer = styled(ServiePermissionLayout)`
  gap: ${Layout.Spacing._2XL};
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._S};
  .toggle-text {
    .label-container {
      max-width: 80px;
      min-width: 80px !important;
    }
    .label {
      margin: 0 auto;
      padding: 0;
      cursor: ${({ enabled }) => !enabled && 'auto !important'};
    }
  }
`;

export const AllServicesContainer = styled(ServicePermissionContainer)`
  border: unset;
  background-color: ${Colors.Neutral._100};
`;

export const AllServicesGrid = styled.div`
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;

export const ServiceOverviewGrid = styled.div`
  display: grid;
  gap: ${Layout.Spacing._2XS};
  grid-template-columns: min-content 1fr;
  grid-template-rows: ${Layout.Spacing._S} 1fr;
`;

export const ServiceActiveCSS = styled.div`
  position: absolute;
  cursor: pointer;
  top: ${({ enabled }) => (enabled ? '-1px' : 0)};
  left: ${({ enabled }) => (enabled ? '-1px' : 0)};
  padding: ${Layout.Spacing._XS};
`;

export const UsersListContainer = styled.div`
  height: 100%;
  .card {
    > div {
      margin-bottom: unset;
    }
  }
`;

export const DevPortal2FAContainer = styled.div`
  .popup {
    height: 350px;
    min-height: 350px;
    width: 544px;
  }
`;

export const NoResultsCSS = styled.div`
  height: 100%;
  grid-row-start: 2;
  grid-column: 1 / 13;
  ${Styles.flexCenter};
`;
