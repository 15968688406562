import { Colors } from '../../../../../components/style';
import { Constants, Field_Keys } from '../../../../../constants';
import { magnitudeFormatter } from '../../../../../utils/stringUtils';
import { maskEntry } from '../../../../../utils/utils';
import { dashboardKeys } from '../variables';

export const formatOrigins = data => {
  if (data == null || !data.length) return inactiveOrigins();
  maskEntry(data, mask);
  let error;
  try {
    data = data.slice(0, 3);
    data = data.map(country => {
      const { [dashboardKeys.referenceCountry]: name, value } = country;
      return {
        ...country,
        key: `${name}: `,
        value,
      };
    });
    if (data.every(datum => !datum.value)) return inactiveOrigins();
  } catch {
    return inactiveOrigins();
  }

  const total = data.reduce((acc, curr) => acc + parseInt(curr.value ?? 0), 0);

  const main = {
    value: magnitudeFormatter(total),
    label: 'Total Origins',
  };

  return { data, total, main, error };
};

export const inactiveOrigins = () => {
  const data = [
    {
      key: Constants.fallback,
      value: 10,
      valueLabel: '',
    },
    {
      key: Constants.fallback,
      value: 30,
      valueLabel: '',
    },
    {
      key: Constants.fallback,
      value: 60,
      valueLabel: '',
    },
  ];

  const total = 100;

  const main = {
    value: Constants.fallback,
    label: 'Total Origins',
  };

  const colors = [
    Colors.Neutral._100,
    Colors.Neutral._200,
    Colors.Neutral._300,
  ];
  return { data, total, main, colors };
};

const mask = {
  [Field_Keys.REFERENCE.COUNTRY]: dashboardKeys.referenceCountry,
};
