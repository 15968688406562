import { useRef } from 'react';
import { someDropdownIsEmpty } from '../../../../../components/organisms/Table/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { isEqual } from '../../../../../lib/js';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { referenceIndexKeys } from '../controllers/variables';

export const useGetReferences = ({ searchParams, ignore }) => {
  const prevParams = useRef({});
  const params = formatParams(searchParams);
  const ignoreIf = [
    someDropdownIsEmpty(params),
    isEqual(searchParams, prevParams.current),
  ];

  if (ignoreIf.some(condition => condition)) ignore = true;
  if (!ignore) prevParams.current = searchParams;

  const [
    {
      data: { references = [], reference_count: referenceCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    doSearch,
  ] = useFetchData('office_references/all', {
    params,
    ignore,
  });

  return {
    data: { references: maskEntry(references, mask), referenceCount },
    isLoading,
    isError,
    error,
    doSearch,
  };
};

export const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    ONLY_ACTIVE: active_references,
    PAGE,
    ITEMS_PER_PAGE,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    search,
    from_date,
    to_date,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };
  if (active_references) formattedParams.active_references = true;
  return formattedParams;
};

const mask = {
  [Field_Keys.REFERENCE.NOTES]: referenceIndexKeys.notes,
  [Field_Keys.REFERENCE.ID]: referenceIndexKeys.id,
  [Field_Keys.REFERENCE.ALIAS]: referenceIndexKeys.alias,
  [Field_Keys.REFERENCE.NAME]: referenceIndexKeys.name,
  [Field_Keys.REFERENCE.COMPANY]: referenceIndexKeys.company,
  [Field_Keys.REFERENCE.ADDRESS]: referenceIndexKeys.address,
  [Field_Keys.REFERENCE.PHONE]: referenceIndexKeys.phone,
  [Field_Keys.REFERENCE.POSTCODE]: referenceIndexKeys.zipcode,
  [Field_Keys.REFERENCE.CITY]: referenceIndexKeys.city,
  [Field_Keys.REFERENCE.STATE]: referenceIndexKeys.state,
  [Field_Keys.REFERENCE.COUNTRY]: referenceIndexKeys.country,
  [Field_Keys.REFERENCE.PRODUCT_STATS]: referenceIndexKeys.caseStatistics,
};
