import { matchImagesToRows } from '../../../../../components/organisms/Table/utils';
import { parcelKeys } from '../../parcels/controllers/variables';
import { ticketTypes } from '../../support/controllers/variables';
import { productKeys } from './variables';

export const matchImagesToProduct = matchImagesToRows(
  productKeys.productId,
  'product_id'
);

export const matchImagesTo = type => {
  const rowKey =
    type === ticketTypes.Product
      ? productKeys.productId
      : ticketTypes.Parcel
      ? parcelKeys.id
      : null;
  const imageKey =
    type === ticketTypes.Product
      ? 'product_id'
      : ticketTypes.Parcel
      ? 'parcel_id'
      : null;

  return matchImagesToRows(rowKey, imageKey);
};
