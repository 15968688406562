import { Field_Keys } from '../../../../../constants';

const { default: useFetchData } = require('../../../../../hooks/fetchHook');

export const useGetBrandAliases = ({ ignore }) => {
  const [{ data = [], isLoading, isError, error }] = useFetchData(
    'admin_brands/aliases',
    {
      formatter,
      ignore,
    }
  );

  return { data, isLoading, isError, error };
};

const formatter = data => {
  return data.map(d => d[Field_Keys.BRAND.ALIAS]);
};
