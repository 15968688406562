import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({
  ignore,
  [DC.ONLY_ACTIVE]: active_references,
  isAdmin,
}) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData(`${isAdmin ? 'admin_' : ''}references/filters`, {
      formatter,
      ignore,
      params: { active_references },
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

const formatter = data => {
  const { brands, countries, adding_clients, types } = data;

  const newOps = {};

  newOps[DC.BRANDS] = prepareDataForDropdown(
    brands,
    Field_Keys.REFERENCE.BRAND_NAME,
    Field_Keys.REFERENCE.BRAND_ID
  );

  newOps[DC.COUNTRY] = prepareDataForDropdown(
    countries,
    Field_Keys.REFERENCE.COUNTRY,
    Field_Keys.REFERENCE.COUNTRY
  );

  newOps[DC.ADDED_BY] = prepareDataForDropdown(
    adding_clients,
    Field_Keys.REFERENCE.CREATING_USER_DISPLAY_NAME,
    Field_Keys.REFERENCE.CREATING_USER_CLIENT_ID,
    { sectionTitle: 'Others' }
  );

  const types_ = types.map(type => ({
    label: type,
    value: type,
  }));
  newOps[DC.TYPE] = prepareDataForDropdown(types_, 'label', 'value');

  return newOps;
};
