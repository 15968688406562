import * as Yup from 'yup';
import { Icons } from '../../../components/atoms/Icon/Icon.options';
import { PropKeys } from '../../../constants';

export const userSettingsKeys = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmPassword: 'confirmPassword',
};

export const optionViews = {
  userInfo: 'userInfo',
  security: 'security',
  twoFA: 'twoFA',
};

export const options = [
  {
    icon: Icons.UserCog,
    title: 'Profile settings',
    description: 'Edit basic user information',
    view: optionViews.userInfo,
  },
  {
    icon: Icons.Shield,
    title: 'Security',
    description: 'Edit password settings',
    view: optionViews.security,
  },
  {
    icon: Icons.Lock,
    title: 'Two factor authentication',
    description: 'Edit 2FA settings',
    view: optionViews.twoFA,
    comingSoon: true,
  },
];

export const getUserDetailsInitialValues = store => ({
  [userSettingsKeys.firstName]: store[PropKeys.firstName] ?? '',
  [userSettingsKeys.lastName]: store[PropKeys.lastName] ?? '',
  [userSettingsKeys.email]: store[PropKeys.email] ?? '',
  [userSettingsKeys.phone]: store[PropKeys.phone] ?? '',
});

export const userDetailFields = [
  {
    placeholder: 'Name',
    name: userSettingsKeys.firstName,
  },
  {
    placeholder: 'Last name',
    name: userSettingsKeys.lastName,
  },
  {
    placeholder: 'Email',
    name: userSettingsKeys.email,
  },
  {
    placeholder: 'Phone',
    name: userSettingsKeys.phone,
  },
];

export const userDetailsValidationSchema = Yup.object().shape({
  [userSettingsKeys.firstName]: Yup.string()
    .min(3, '3 chars minimum')
    .max(75, '75 chars maximum')
    .required('Please enter your first name'),
  [userSettingsKeys.lastName]: Yup.string()
    .min(3, '3 chars minimum')
    .max(75, '75 chars maximum')
    .required('Please enter your last name'),
  [userSettingsKeys.email]: Yup.string()
    .email('Not a valid email address')
    .required('Please enter your email address'),
  [userSettingsKeys.phone]: Yup.string()
    .matches(
      /^[0-9-\s\+\/\(\)\#]+$/,
      'Use digits, spaces, or one of the following: - / + () #'
    )
    .min(5, 'Must be minimum 5 digits')
    .max(20, 'Must be maximum 20 digits')
    .notRequired("Please enter the reference's phone number"),
});

export const securityFields = onNewPasswordChange => [
  {
    placeholder: 'Current password',
    name: userSettingsKeys.currentPassword,
  },
  {
    placeholder: 'New password',
    name: userSettingsKeys.newPassword,
    onChange: onNewPasswordChange,
  },
  {
    placeholder: 'Confirm new password',
    name: userSettingsKeys.confirmPassword,
  },
];

export const securityInitialValues = {
  [userSettingsKeys.currentPassword]: '',
  [userSettingsKeys.newPassword]: '',
  [userSettingsKeys.confirmPassword]: '',
};

export const securityValidationSchema = Yup.object().shape({
  [userSettingsKeys.currentPassword]: Yup.string().required(
    'Please enter your current password'
  ),
  [userSettingsKeys.newPassword]: Yup.string().required(''),
  [userSettingsKeys.confirmPassword]: Yup.string()
    .oneOf(
      [Yup.ref(userSettingsKeys.newPassword), null],
      'Passwords must match'
    )
    .required(''),
});
