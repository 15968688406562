import { useState } from 'react';
import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import {
  Badge,
  BadgeType,
} from '../../../../../../components/molecules/Badge/Badge';
import Button from '../../../../../../components/molecules/Button/Button';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { NavigateIcon } from '../../../../../../components/molecules/NavigateIcon/NavigateIcon';
import ToggleText from '../../../../../../components/molecules/ToggleText/ToggleText';
import { Table } from '../../../../../../components/organisms/Table/Table';
import { Layout, Size } from '../../../../../../components/style';
import PreviewGallery from '../../../../../../components/templates/Gallery/controllers/PreviewGallery';
import { Field_Keys } from '../../../../../../constants';
import {
  getStatusColor,
  parseStringModifiers,
} from '../../../../../../utils/utils';
import { ParcelProductsListSmall } from '../../../../../brand/features_public/products/components/style';
import {
  smallProductsListGrid,
  smallProductsListHeaders,
} from '../../../../../brand/features_public/products/controllers/variables';
import { GalleryPages, GalleryProps } from '../../../../constants';
import { NavURIs, Pages } from '../../../../routes/variables';
import {
  activityToggleOptions,
  componentTitle,
  components,
  infoDescription,
  productKeys,
} from '../../controllers/variables';
import {
  RiskAnalysisCircleProgressBar,
  RiskContent,
  RiskType,
  StatusActions,
  StatusStepsContainer,
} from '../atoms';
import {
  Detail,
  ReasonDescription,
  RecentActivityContainer,
  StatusDescription,
  StepIcon,
  TicketCountContainer,
  casesTableComponentsSmall,
} from '../molecules';
import { Details, DisableContainer } from '../style';

const disabledText2Rows = parseStringModifiers(
  'To visualize this data, you need to\nhave access to this service'
);

export const ProductDetailsSummary = ({
  productDetails,
  status,
  photos,
  productId,
  productAlias,
  noShadow,
  showEditProduct,
}) => {
  return (
    <Card
      title={
        <Flex gap="_2XS" align="center">
          {componentTitle[components.productDetails]}
          <AnimatedIcon
            name={Icons.Edit3}
            theme={'light'}
            onClick={showEditProduct}
            size={Size.Icon._M}
          />
        </Flex>
      }
      noShadow={noShadow}
      className="product-details-summary"
    >
      <Flex justify="between">
        <Details>
          {(productDetails ?? []).map(detail => {
            const { title, text, icon } = detail;
            return (
              <Detail
                title={title}
                text={text}
                icon={icon}
                key={detail.title}
              />
            );
          })}
          <Badge type={BadgeType.status} text={status} size="_M" />
        </Details>
        <PreviewGallery
          galleryProps={GalleryProps[GalleryPages.products]}
          images={photos?.map(photo => photo[Field_Keys.PHOTO.THUMBNAIL])}
          id={productId}
          galleryTitle={`Product ${productAlias}`}
        />
      </Flex>
    </Card>
  );
};

export const StatusSteps = ({
  statusInstructions,
  status,
  actions,
  actionClick,
  size,
}) => {
  const leftbarColor = getStatusColor(status);
  return (
    <StatusStepsContainer leftbarColor={leftbarColor} className="status-steps">
      <StatusDescription
        status={status}
        statusInstructions={statusInstructions}
        actions={actions}
        actionClick={actionClick}
      />
      <StepIcon status={status} size={size} />
      <StatusActions actions={actions} actionClick={actionClick} />
    </StatusStepsContainer>
  );
};

export const ParcelProducts = ({ isLoading, data, rowAction, disabled }) => {
  const [hoveredRow, setHoveredRow] = useState(-1);
  const alias = data?.[0]?.[productKeys.parcel.alias];
  const parcelPage = `${NavURIs[Pages.parcels]}/?search=${alias}`;
  const rowHoverIn = (_, index) => setHoveredRow(index);
  const rowHoverOut = _ => setHoveredRow(-1);
  return (
    <DisableContainer disabled={disabled} className="parcel-products">
      <Card
        title={componentTitle[components.parcelProducts]}
        noShadow={false}
        icon={<NavigateIcon to={parcelPage} size="_L" newTab />}
      >
        {isLoading ? (
          <CardLoader />
        ) : (
          <ParcelProductsListSmall className="scroll-list">
            <Table
              headers={smallProductsListHeaders}
              data={data ?? []}
              components={casesTableComponentsSmall({ hoveredRow })}
              grid={smallProductsListGrid}
              rowAction={rowAction}
              rowHoverIn={rowHoverIn}
              rowHoverOut={rowHoverOut}
            />
          </ParcelProductsListSmall>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </DisableContainer>
  );
};

export const RiskAnalysis = ({
  riskType,
  reason,
  noShadow,
  size,
  icon,
  isLoading,
  disabled,
}) => {
  return (
    <DisableContainer disabled={disabled} className="risk-analysis">
      <Card
        title={componentTitle[components.riskAnalysis]}
        noShadow={noShadow}
        icon={
          icon && (
            <InfoIcon description={infoDescription[components.riskAnalysis]} />
          )
        }
      >
        {isLoading ? (
          <CardLoader />
        ) : (
          <>
            <RiskContent size={size}>
              <RiskType text={riskType} size={size} />
              <ReasonDescription
                reasonTitle="REASON"
                title={reason?.title}
                description={reason?.description}
              />
            </RiskContent>
            <RiskAnalysisCircleProgressBar
              data={reason?.riskPotential}
              size={size}
            />
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </DisableContainer>
  );
};

export const TicketCount = ({ text, goToTickets, display }) => {
  return (
    <TicketCountContainer text={text} onClick={goToTickets} display={display} />
  );
};

export const RecentActivity = ({
  activity = {},
  showThisMany = undefined,
  noShadow,
  icon,
  toggle,
}) => {
  const [type, setType] = useState(activityToggleOptions[1].value);

  const selectType =
    ({ value }) =>
    () =>
      setType(value);

  return (
    <Card
      title={componentTitle[components.recentActivity]}
      className="recent-activity"
      noShadow={noShadow}
      icon={
        icon && (
          <InfoIcon description={infoDescription[components.recentActivity]} />
        )
      }
    >
      <Flex column w h gap="_M">
        {toggle && (
          <ToggleText
            options={activityToggleOptions}
            select={selectType}
            size="_S"
            activeIndex={activityToggleOptions.findIndex(
              ({ value }) => value === type
            )}
            padding={Layout.Spacing._3XS}
          />
        )}
        <RecentActivityContainer
          activity={toggle ? activity[type] : activity}
          showThisMany={showThisMany}
        />
      </Flex>
    </Card>
  );
};

export const CaseDetailsButton = ({ text, onClick }) => {
  return (
    <div style={{ marginTop: 'auto' }}>
      <Button
        text={text}
        size="_M"
        variant={'Secondary'}
        fullWidth
        rightIcon={Icons.ExternalLink}
        onClick={onClick}
      />
    </div>
  );
};
