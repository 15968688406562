import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Field_Keys } from '../../../../../constants';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useCreateMessage } from '../api/createMessage';
import { MessageInputLayout } from '../components/MessageInputLayout';

const charLimit = 999;

const MessageInput = ({
  facilitatorName,
  onSendMessageSuccess,
  ticketId,
  messagesIsLoading,
  disabled: inputDisabled,
}) => {
  const [value, setValue] = useState('');

  const { showSnackbarError, showSnackbarWarning } = useSnackbar();

  const resetFormRef = useRef(null);

  const { data, body, isLoading, isError, error, doSendMessage } =
    useCreateMessage({
      ticketId,
    });

  useEffect(() => {
    if (data == null || isLoading) return;
    if (isError) {
      const { [Field_Keys.SUPPORT_TICKET.MESSAGE]: message } = body;
      showSnackbarError(error);
      setValue(message);
      return;
    }

    resetFormRef?.current();
    onSendMessageSuccess({ data, body });
  }, [data, isLoading, isError]);

  const handleChange = e => {
    const { value } = e.target;
    setValue(value.substring(0, charLimit));
    if (value.length >= charLimit)
      showSnackbarWarning(`Maximum allowed characters: ${charLimit}`);
  };

  const handleSend = e => {
    const thereIsAMessage = value?.trim().length;
    const notAllNewlines = value.replaceAll('\n', '').length;
    if (thereIsAMessage && notAllNewlines) {
      const body = {
        message: value.trim(),
      };
      doSendMessage(body);
      setValue('');
    }
  };

  const handleEnterKey = e => {
    const { shiftKey, key } = e;
    let lineBrokenMsg = value + '\n';
    if (shiftKey && key === 'Enter') setValue(lineBrokenMsg);
    if (!shiftKey && key === 'Enter') {
      e?.preventDefault();
      handleSend();
    }
  };

  const placeholder = `Write your response to ${facilitatorName}`;

  return (
    <Formik initialValues={{ message: '' }}>
      {({ values, resetForm }) => {
        if (!resetFormRef.current) resetFormRef.current = resetForm;
        return (
          <MessageInputLayout
            handleEnterKey={handleEnterKey}
            handleChange={handleChange}
            placeholder={placeholder}
            handleSend={handleSend}
            isLoading={isLoading}
            messagesIsLoading={messagesIsLoading}
            disabled={!values.message.length || isLoading || messagesIsLoading}
            inputDisabled={inputDisabled}
          />
        );
      }}
    </Formik>
  );
};

export default MessageInput;
