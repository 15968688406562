import { useToggle } from '@react-hookz/web';
import { useEffect, useRef, useState } from 'react';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Field_Keys } from '../../../../../constants';
import { isOnlyEmptyValues } from '../../../../../lib/js';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { parseStringModifiers } from '../../../../../utils/utils';
import { referenceFields } from '../../../../brand/features_public/reference_index/controllers/variables';
import { actorTypes, useAddActor } from '../api/addActor';
import { useGetParcelDetails } from '../api/getParcelDetails';
import { useRemoveActor } from '../api/removeActor';
import { useUpdateTracking } from '../api/updateTracking';
import { useAddBarcodes } from '../api/useAddBarcodes';
import { useRemoveBarcodes } from '../api/useRemoveBarcodes';
import { ParcelSummaryLayout } from '../components/ParcelSummaryLayout';
import Destination from './Destination';
import Details from './Details';
import Files from './Files';
import Origin from './Origin';
import Overview from './Overview';
import { parcelKeys, referenceIndexKeys, tabs } from './variables';

const ParcelSummary = ({ parcel, initialTab = tabs.overview }) => {
  const [openTab, setOpenTab] = useState(initialTab);
  const [parcelDetails, setParcelDetails] = useState(parcel);

  const [barcodeDetails, setBarcodeDetails] = useState({});
  const [parcelOrigin, setParcelOrigin] = useState({});
  const [parcelDestination, setParcelDestination] = useState({});

  const [initBarcodeDetails, setInitBarcodeDetails] = useState();
  const [initParcelOrigin, setInitParcelOrigin] = useState({});
  const [initParcelDestination, setInitParcelDestination] = useState({});

  const [dbOrigin, setDbOrigin] = useState({});
  const [dbDestination, setDbDestination] = useState({});
  const [dbBarcodeDetails, setDbBarcodeDetails] = useState({});

  const [canSubmitInCurrentTab, toggleCanSubmitInCurrentTab] = useToggle(false);

  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { showModal, dismiss, updateIgnoreOverlayDismiss } = useModal();

  const id = parcel[parcelKeys.id];

  const referenceValuesRef = useRef();

  const { data, isLoading, isError, error, refreshParcelDetails } =
    useGetParcelDetails({
      id,
    });

  const {
    body: addBarcodeBody,
    isLoading: addBarcodeIsLoading,
    isError: addBarcodeIsError,
    error: addBarcodeError,
    doAddBarcodes,
  } = useAddBarcodes({ id });

  const {
    body: removeBarcodeBody,
    isLoading: removeBarcodeIsLoading,
    isError: removeBarcodeIsError,
    error: removeBarcodeError,
    doRemoveBarcodes,
  } = useRemoveBarcodes({ id });

  const {
    body: addActorBody,
    isLoading: addActorIsLoading,
    isError: addActorIsError,
    error: addActorError,
    addOrigin,
    addDestination,
  } = useAddActor({ id });

  const {
    params: removeActorData,
    isLoading: removeActorIsLoading,
    isError: removeActorIsError,
    error: removeActorError,
    removeOrigin,
    removeDestination,
  } = useRemoveActor({ id });

  const {
    body: updateTrackingBody,
    isLoading: updateTrackingIsLoading,
    isError: updateTrackingIsError,
    error: updateTrackingError,
    doUpdateTracking,
  } = useUpdateTracking({ id });

  useEffect(() => {
    if (data == null || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      dismiss();
    }

    setBarcodeInitialValues(data);
    setReferenceInitialValues(data);
    setParcelDetails(data);
  }, [data, isLoading, isError]);

  useEffect(() => {
    setInitBarcodeDetails(barcodeDetails);
    setInitParcelOrigin(parcelOrigin);
    setInitParcelDestination(parcelDestination);
    if (openTab !== tabs.overview) updateIgnoreOverlayDismiss(true);
    if (openTab === tabs.overview) updateIgnoreOverlayDismiss();
  }, [openTab]);

  const barcodeBodies = [addBarcodeBody, removeBarcodeBody, updateTrackingBody];
  const barcodesLoading = [
    addBarcodeIsLoading,
    removeBarcodeIsLoading,
    updateTrackingIsLoading,
  ];
  const barcodesError = [
    { isError: addBarcodeIsError, error: addBarcodeError },
    { isError: removeBarcodeIsError, error: removeBarcodeError },
    {
      isError: updateTrackingIsError,
      error: updateTrackingError,
    },
  ];

  useEffect(() => {
    if (barcodeBodies.every(b => b == null) || barcodesLoading.some(l => l))
      return;

    let stayOn = false;
    barcodesError.forEach(({ isError, error }) => {
      if (isError) {
        showSnackbarError(error);
        stayOn = true;
      }
    });

    if (stayOn) return;
    onSaveSuccess();
  }, [...barcodeBodies, ...barcodesLoading]);

  useEffect(() => {
    if (addActorBody == null || addActorIsLoading) return;
    if (addActorIsError) {
      showSnackbarError(addActorError);
      return;
    }
    onSaveSuccess();
  }, [addActorBody, addActorIsLoading, addActorIsError]);

  useEffect(() => {
    if (removeActorData == null || removeActorIsLoading) return;
    if (removeActorIsError) {
      showSnackbarError(removeActorError);
      return;
    }

    resetReferenceForm(openTab);
    resetReferenceDB(openTab);
    showSnackbarSuccess();
  }, [removeActorData, removeActorIsError, removeActorIsLoading]);

  const setBarcodeInitialValues = data => {
    const {
      [parcelKeys.barcodes]: barcodes = [],
      [parcelKeys.trackingNumber]: trackingNumber,
    } = data ?? {};
    const formattedBarocdes = barcodes.map(barcode => ({
      [parcelKeys.barcode]: barcode,
    }));
    const barcodeDetails = {
      [parcelKeys.trackingNumber]: trackingNumber,
      [parcelKeys.barcodes]: formattedBarocdes,
    };
    // shouldn't need both, but one does tracking and the other does barcodes
    setBarcodeDetails(barcodeDetails);
    setInitBarcodeDetails(barcodeDetails);
    setDbBarcodeDetails(barcodeDetails);
  };

  const setReferenceInitialValues = data => {
    const { [Field_Keys.PARCEL.ACTORS]: actors = [] } = data ?? {};
    const origin = actors.find(
      ({ [referenceIndexKeys.type]: type }) => type === actorTypes.sender
    );
    const destination = actors.find(
      ({ [referenceIndexKeys.type]: type }) => type === actorTypes.receiver
    );

    if (origin) {
      setDbOrigin(origin);
      setInitParcelOrigin(origin);
      setParcelOrigin(origin);
    }
    if (destination) {
      setDbDestination(destination);
      setInitParcelDestination(destination);
      setParcelDestination(destination);
    }
  };

  const onSaveSuccess = () => {
    refreshParcelDetails();
    setOpenTab(tabs.overview);
    showSnackbarSuccess('Parcel updated successfully');
  };

  const somethingIsLoading = [
    isLoading,
    removeActorIsLoading,
    addActorIsLoading,
    ...barcodesLoading,
  ].some(x => x);

  const components = {
    [tabs.overview]: (
      <Overview
        parcel={parcel}
        parcelDetails={parcelDetails}
        isLoading={isLoading}
      />
    ),
    [tabs.details]: (
      <Details
        initialData={initBarcodeDetails}
        data={barcodeDetails}
        dbData={dbBarcodeDetails}
        updateData={setBarcodeDetails}
        toggleCanSubmitInCurrentTab={toggleCanSubmitInCurrentTab}
        isLoading={somethingIsLoading}
      />
    ),
    [tabs.origin]: (
      <Origin
        initialData={initParcelOrigin}
        dbData={dbOrigin}
        toggleCanSubmitInCurrentTab={toggleCanSubmitInCurrentTab}
        referenceValuesRef={referenceValuesRef}
      />
    ),
    [tabs.destination]: (
      <Destination
        initialData={initParcelDestination}
        dbData={dbDestination}
        toggleCanSubmitInCurrentTab={toggleCanSubmitInCurrentTab}
        referenceValuesRef={referenceValuesRef}
      />
    ),
    [tabs.files]: <Files data={data?.[parcelKeys.file.self] ?? []} />,
  };

  const handleChangeTab = tab => {
    if (openTab === tabs.origin) setParcelOrigin(referenceValuesRef.current);
    if (openTab === tabs.destination)
      setParcelDestination(referenceValuesRef.current);

    setOpenTab(tab);
  };

  const onBarcodesSave = () => {
    if (barcodeDetails[parcelKeys.barcodesAdding]?.length) {
      const barcodes = barcodeDetails[parcelKeys.barcodesAdding].map(
        ({ barcode }) => barcode
      );
      const body = { barcodes };
      doAddBarcodes(body);
    }
    if (barcodeDetails[parcelKeys.barcodesRemoving]?.length) {
      const barcodes = barcodeDetails[parcelKeys.barcodesRemoving].map(
        ({ barcode }) => barcode
      );
      const body = { barcodes };
      doRemoveBarcodes(body);
    }
    if (barcodeDetails[parcelKeys.trackingNumber]) {
      doUpdateTracking(barcodeDetails);
    }
  };

  const onOriginSave = async () => {
    if (!isOnlyEmptyValues(referenceValuesRef.current))
      addOrigin(referenceValuesRef.current);
  };

  const onDestinationSave = async () => {
    if (!isOnlyEmptyValues(referenceValuesRef.current))
      addDestination(referenceValuesRef.current);
  };

  const onSubmit = {
    [tabs.details]: onBarcodesSave,
    [tabs.origin]: onOriginSave,
    [tabs.destination]: onDestinationSave,
  };

  const showRemoveReferenceConfirmModal = () => {
    const onConfirm = () => {
      removeReference(openTab);
      dismiss();
    };

    showModal(
      ConfirmModal,
      {
        title: 'Remove actor',
        description:
          parseStringModifiers(`Are you sure you want to remove this reference?
          This will remove all the information about this actor from the parcel.`),
        confirm: 'Delete',
        onConfirm,
        onCancel: dismiss,
      },
      { stack: true }
    );
  };

  const removeReference = openTab => {
    if (openTab === tabs.origin) removeOrigin();
    if (openTab === tabs.destination) removeDestination();
  };

  const resetReferenceForm = openTab => {
    const newFields = { ...referenceFields, reset: Math.random() };
    if (openTab === tabs.origin) setInitParcelOrigin(newFields);
    if (openTab === tabs.destination) setInitParcelDestination(newFields);
  };

  const resetReferenceDB = openTab => {
    if (openTab === tabs.origin) setDbOrigin({});
    if (openTab === tabs.destination) setDbDestination({});
  };

  const onAlternate = {
    [tabs.overview]: undefined,
    [tabs.details]: undefined,
    [tabs.origin]: () => resetReferenceForm(openTab),
    [tabs.destination]: () => resetReferenceForm(openTab),
  };

  const alternateProps = {
    [tabs.overview]: undefined,
    [tabs.details]: undefined,
    [tabs.origin]: {
      text: 'Clear fields',
      leftIcon: Icons.Trash,
      disabled: !canSubmitInCurrentTab,
      className: 'clear-fields',
    },
    [tabs.destination]: {
      text: 'Clear fields',
      leftIcon: Icons.Trash,
      disabled: !canSubmitInCurrentTab,
      className: 'clear-fields',
    },
  };

  const onSecondary = {
    [tabs.overview]: undefined,
    [tabs.details]: undefined,
    [tabs.origin]: showRemoveReferenceConfirmModal,
    [tabs.destination]: showRemoveReferenceConfirmModal,
  };

  const onSecondaryText = {
    [tabs.overview]: undefined,
    [tabs.details]: undefined,
    [tabs.origin]: 'Remove',
    [tabs.destination]: 'Remove',
  };

  const onSecondaryDisabled = {
    [tabs.overview]: undefined,
    [tabs.details]: undefined,
    [tabs.origin]: dbOrigin[referenceIndexKeys.id] ? false : true,
    [tabs.destination]: dbDestination[referenceIndexKeys.id] ? false : true,
  };

  const title = `Parcel ${parcel[parcelKeys.alias]}`;

  return (
    <ParcelSummaryLayout
      title={title}
      alias={parcel[parcelKeys.alias]}
      component={components[openTab]}
      setOpenTab={handleChangeTab}
      openTab={openTab}
      tabs={Object.values(tabs)}
      canSubmitInCurrentTab={canSubmitInCurrentTab}
      onSubmit={onSubmit[openTab]}
      onAlternate={onAlternate[openTab]}
      alternateProps={alternateProps[openTab]}
      onSecondary={onSecondary[openTab]}
      onSecondaryText={onSecondaryText[openTab]}
      onSecondaryDisabled={onSecondaryDisabled[openTab]}
      isLoading={somethingIsLoading}
      dismiss={dismiss}
    />
  );
};

export default Modal(ParcelSummary);
