import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';

export const useGetTicket = ({ ticketId }) => {
  const [{ data, isLoading, isError, error }] = useFetchData(
    `support_ticket/${ticketId}`,
    { formatter: formatTicket }
  );

  return { data, isLoading, isError, error };
};

export const formatTicket = data => {
  const {
    [Field_Keys.SUPPORT_TICKET.CREATED_AT]: dateCreated,
    [Field_Keys.SUPPORT_TICKET.FIRST_MESSAGE]: description,
    [Field_Keys.SUPPORT_TICKET.LAST_MESSAGE_DATE]: lastMessage,
    [Field_Keys.SUPPORT_TICKET.MESSAGE_COUNT]: messageCount,
    [Field_Keys.SUPPORT_TICKET.IS_SUPPORT_TICKET_OWNER]: own_ticket,
    [Field_Keys.SUPPORT_TICKET.REP_USER_IMAGE]: facilitatorImage,
    [Field_Keys.SUPPORT_TICKET.REP_USER_DISPLAY_NAME]: facilitatorName,
    [Field_Keys.SUPPORT_TICKET.IS_READ]: read,
    [Field_Keys.SUPPORT_TICKET.ALIAS]: alias,
    [Field_Keys.SUPPORT_TICKET.PRIORITY]: priority,
    [Field_Keys.SUPPORT_TICKET.REFERENCE_ID]: reference,
    [Field_Keys.SUPPORT_TICKET.STATUS]: status,
    [Field_Keys.SUPPORT_TICKET.TITLE]: mainTopic,
    [Field_Keys.SUPPORT_TICKET.TYPE]: type,
    [Field_Keys.SUPPORT_TICKET.ID]: ticketId,
    [Field_Keys.SUPPORT_TICKET.CREATING_USER_IMAGE]: image,
    [Field_Keys.SUPPORT_TICKET.CREATING_USER_DISPLAY_NAME]: name,
    [Field_Keys.SUPPORT_TICKET.BRAND_IMAGE]: brandImage,
    [Field_Keys.SUPPORT_TICKET.BRAND_NAME]: brandName,
  } = data;

  return {
    alias,
    dateCreated,
    description,
    facilitatorName,
    facilitatorImage,
    lastMessage,
    mainTopic,
    messageCount,
    own_ticket,
    ticketCreator: {
      name,
      image,
      brandName,
      brandImage,
    },
    priority,
    reference,
    read,
    status,
    ticketId,
    type,
  };
};
