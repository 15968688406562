import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';

const initialActivityLimit_ = 10;
const params_ = {
  limit: initialActivityLimit_,
  offset: 0,
};

export const useGetAuthenticationActivity = params => {
  const [{ data, isLoading = true, isError, error }, _, getActivity] =
    useFetchData('products/authentication_activity', {
      formatter: format,
      params: params ?? params_,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    getActivity,
  };
};

export const format = data => {
  return data.map(activity => formatActivity(activity));
};

const formatActivity = activity => {
  const {
    [Field_Keys.PRODUCT.LOG_CREATING_USER_BRAND_IMAGE]: brandLink,
    [Field_Keys.PRODUCT.LOG_CONTENT]: message,
    [Field_Keys.PRODUCT.LOG_CREATED_AT]: time,
    [Field_Keys.PRODUCT.LOG_TYPE]: type,
    [Field_Keys.PRODUCT.LOG_CREATING_USER_IMAGE]: userLink,
    [Field_Keys.PRODUCT.LOG_CREATING_USER_DISPLAY_NAME]: name,
  } = activity;

  const brandImage = brandLink?.includes('null') ? null : brandLink;
  const userImage = userLink?.includes('null') ? null : userLink;

  return { image: userImage ?? brandImage, message, name, time, type };
};
