import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { adminCategoriesKeys as keys } from '../controllers/variables';

export const useGetAdminCategories = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { categories = [], category_count: categoryCount = 0 } = {},
      isLoading = true,
      isError,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('admin_categories', {
    params,
    ignore,
    large: true,
  });

  return {
    data: {
      categories: formatter(categories, mask),
      categoryCount,
    },
    isLoading,
    isError,
    getProducts,
    refreshTable,
  };
};

export const formatParams = params => {
  const { SEARCH: search, CATEGORY, PAGE, ITEMS_PER_PAGE } = params;

  const category_id = extractSelectedValues(CATEGORY);

  const formattedParams = {
    search,
    category_id,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

export const formatter = data => {
  maskEntry(data, mask);
  return data;
};

export const mask = {
  [Field_Keys.CATEGORY.CREATING_USER_NAME]: keys.addedBy.name,
  [Field_Keys.CATEGORY.CREATING_USER_IMAGE]: keys.addedBy.image,
  [Field_Keys.CATEGORY.SUBCATEGORY_CREATED_AT]: keys.addedAt,
  [Field_Keys.CATEGORY.SUBCATEGORY_NAME]: keys.subCategory.name,
  [Field_Keys.CATEGORY.SUBCATEGORY_ID]: keys.subCategory.id,
  [Field_Keys.CATEGORY.NAME]: keys.category.name,
  [Field_Keys.CATEGORY.ID]: keys.category.id,
};
