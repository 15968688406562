import { useRef } from 'react';
import usePostData from '../../../../../hooks/postHook';
import { trimmed } from '../../../../../lib/js';
import { referenceIndexKeys } from '../controllers/variables';

export const useCreateReference = ({
  isAdmin = false,
  isEditing = false,
} = {}) => {
  const actionsRef = useRef({});

  const [{ data, body, isLoading, isError, error }, doCreate_] = usePostData(
    ``,
    {
      type: isEditing ? 'put' : 'post',
    }
  );

  const doCreate = (values, actions) => {
    let url = '';
    if (isAdmin) {
      if (isEditing) url = `admin_reference/${values[referenceIndexKeys.id]}`;
      else url = 'admin_references/create';
    } else {
      if (isEditing) url = `reference/${values[referenceIndexKeys.id]}`;
      else url = `references/create`;
    }

    const body = referencePayloadFormat(values);
    actionsRef.current = actions;
    doCreate_(body, url);
  };

  return {
    data,
    body,
    actions: actionsRef.current,
    isLoading,
    isError,
    error,
    doCreate,
  };
};

export const referencePayloadFormat = values => {
  const body = {
    ...values,
    name: trimmed(values[referenceIndexKeys.name]),
    company: trimmed(values[referenceIndexKeys.company]),
    address: trimmed(values[referenceIndexKeys.address]),
    country: trimmed(values[referenceIndexKeys.country]),
    postcode: trimmed(values[referenceIndexKeys.zipcode]),
    city: trimmed(values[referenceIndexKeys.city]),
    phone: trimmed(values[referenceIndexKeys.phone]),
    state: trimmed(values[referenceIndexKeys.state]),
    details: trimmed(values[referenceIndexKeys.additionalDetails]),
    type: values[referenceIndexKeys.type],
    brand_id: values.brand_id,
    active: 1,
    values,
  };
  return body;
};
