import { cloneDeep } from 'lodash';
import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { sortByChar } from '../../../../../utils/arrayUtils';
import { alphabetChars } from '../../../../../utils/stringUtils';
import { maskEntry } from '../../../../../utils/utils';
import { processingKeys } from '../controllers/variables';

export const useGetBrands = () => {
  const [{ data = [], isLoading, isError, error }, _] = useFetchData('brands', {
    formatter,
    failureFormatter: _ => [],
    params: { with_images: true },
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data = organizeBrandsAlphabetically(data);
  return data;
};

const organizeBrandsAlphabetically = brands => {
  brands = cloneDeep(brands);
  const alphabetObject = {};
  alphabetChars.forEach(char => (alphabetObject[char] = []));

  brands.forEach(brand => {
    const char = brand[processingKeys.brand.name]?.slice(0, 1).toUpperCase();
    if (char == null) return;
    if (alphabetObject[char] == null) alphabetObject[char] = [];
    alphabetObject[char].push(brand);
  });

  const sortedAlphabetArray = Object.entries(alphabetObject)
    .sort(sortByChar)
    .map(([char, brand]) => ({ [char]: brand }));

  return sortedAlphabetArray;
};

const mask = {
  [Field_Keys.BRAND.NAME]: processingKeys.brand.name,
  [Field_Keys.BRAND.ID]: processingKeys.brand.id,
  [Field_Keys.BRAND.IMAGE]: processingKeys.brand.image,
  [Field_Keys.BRAND.IS_CONTRACTED]: processingKeys.brand.isContracted,
  brands: 'unformattedBrands',
};
