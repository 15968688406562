import usePostData from '../../../../../hooks/postHook';
import { productKeys } from '../controllers/variables';

export const useGetProductsImage = () => {
  const [{ data, body, isLoading, isError, error }, getProductsImage] =
    usePostData('products/images');

  const doGetProductsImage = ({ products, params }) => {
    const limit = params?.limit === null ? null : params?.limit ?? 1;
    const product_ids = products.map(product => product[productKeys.productId]);

    const body = {
      product_ids,
      only_thumbs: params?.only_thumbs ?? true,
      limit,
      params,
    };
    getProductsImage(body);
  };

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    getProductsImage: doGetProductsImage,
  };
};
