import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import Modal from '../../../../../components/templates/Modal/Modal';
import { cloneDeep } from '../../../../../lib/js';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { useAddUser } from '../api/addUser';
import { useUpdateIsSSOUser } from '../api/updateIsSSOUser';
import { useUpdateMainContact } from '../api/updateMainContact';
import { useUpdateUserEmail } from '../api/updateUserEmail';
import { useUpdateUserName } from '../api/updateUserName';
import { useUpdateUserRoles } from '../api/updateUserRoles';
import { AddUserLayout } from '../components/AddUserLayout';
import { brandManagementKeys as keys } from './variables';

const fieldNames = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
};

const fields = [
  {
    key: uniqueId(),
    name: fieldNames.firstName,
    label: 'First name',
    placeholder: 'John',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.lastName,
    label: 'Last name',
    placeholder: 'Smith',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.email,
    label: 'Email',
    placeholder: 'johnsmith@gmail.com',
    size: '_M',
  },
];

const addUserInitialValues = {};
fields.forEach(field => (addUserInitialValues[field.name] = ''));

const addUserValidationSchema = Yup.object().shape({
  [fieldNames.firstName]: Yup.string().required('Required'),
  [fieldNames.lastName]: Yup.string().required('Required'),
  [fieldNames.email]: Yup.string().email('Invalid email').required('Required'),
});

const mapFieldsToResponse = {
  [fieldNames.firstName]: keys.user.firstName,
  [fieldNames.lastName]: keys.user.lastName,
  [fieldNames.email]: keys.user.email,
};

const AddUser = ({
  roles,
  onSuccess,
  enabledServices,
  mode = 'add',
  user,
  brand,
}) => {
  const isEditing = mode === 'edit';
  const [isMainContact, toggleIsMainContact] = useToggle(
    user?.[keys.user.isMainContact] ?? false
  );
  const [shouldNotifyUser, toggleNotifyUser] = useToggle(true);
  const [isSSOUser, toggleIsSSOUser] = useToggle(
    user?.[keys.user.isSSOUser] ?? false
  );
  // all enabled is superflous now given that that no service is deactivated, just set to no access instead
  const [allEnabled, setAllEnabled] = useState(false);
  const [servicesCache, setServicesCache] = useState();
  const [allServicesRole, setAllServicesRole] = useState();

  const { brandId } = useParams();

  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { data, isLoading, isError, error, doAddUser } = useAddUser({
    id: brandId,
  });

  const {
    body: isSSOUserBody,
    isLoading: isSSOUserIsLoading,
    isError: isSSOUserIsError,
    error: isSSOUserError,
    doUpdateIsSSOUser,
  } = useUpdateIsSSOUser();

  const {
    body: mainContactBody,
    isLoading: mainContactIsLoading,
    isError: mainContactIsError,
    error: mainContactError,
    doUpdateMainContact,
  } = useUpdateMainContact();

  const {
    body: updateRolesBody,
    isLoading: updateRolesIsLoading,
    isError: updateRolesIsError,
    error: updateRolesError,
    doUpdateUserRoles,
  } = useUpdateUserRoles({ clientId: user?.[keys.brandGroup.id], brandId });

  const {
    body: usernameBody,
    isLoading: usernameIsLoading,
    isError: usernameIsError,
    error: usernameError,
    doUpdateUserName,
  } = useUpdateUserName({ clientId: user?.[keys.brandGroup.id], brandId });

  const {
    body: emailBody,
    isLoading: emailIsLoading,
    isError: emailIsError,
    error: emailError,
    doUpdateUserEmail,
  } = useUpdateUserEmail({ clientId: user?.[keys.brandGroup.id], brandId });

  const selectRoleAll =
    ({ services, setFieldValue }) =>
    role => {
      setAllServicesRole(role);
      const newServices = cloneDeep(services);
      newServices.forEach(s => {
        if (role) s.selectedRole = s.roles.find(({ key }) => role.key === key);
      });

      setFieldValue('services', newServices);
    };

  const somethingIsLoading = [
    isLoading,
    updateRolesIsLoading,
    usernameIsLoading,
    emailIsLoading,
  ].some(Boolean);

  const somethingIsError = [
    isError,
    updateRolesIsError,
    usernameIsError,
    emailIsError,
  ].some(Boolean);

  const somethingIsData = [data, updateRolesBody, usernameBody, emailBody].some(
    x => x != null
  );

  useEffect(() => {
    if (somethingIsLoading || !somethingIsData) return;
    if (somethingIsError) {
      const error_msg = isError
        ? error
        : updateRolesIsError
        ? updateRolesError
        : usernameIsError
        ? usernameError
        : emailIsError
        ? emailError
        : null;
      showSnackbarError(error_msg);
      return;
    }
    onFinished();
  }, [somethingIsLoading, somethingIsError, somethingIsData]);

  useEffect(() => {
    if (isSSOUserIsLoading || isSSOUserBody == null) return;
    if (isSSOUserIsError) {
      showSnackbarError(isSSOUserError);
    }
    onFinished();
  }, [isSSOUserIsLoading, isSSOUserBody, isSSOUserIsError]);

  useEffect(() => {
    if (mainContactIsLoading || mainContactBody == null) return;
    if (mainContactIsError) {
      showSnackbarError(mainContactError);
    }
    onFinished();
  }, [mainContactIsLoading, mainContactBody, mainContactIsError]);

  useEffect(() => {
    fields.find(field => field.name === fieldNames.email).readOnly = isEditing;
  }, []);

  const onFinished = () => {
    showSnackbarSuccess();
    onSuccess();
  };

  const onSubmit = values => {
    if (isEditing) handleUserEdit(values);
    if (!isEditing) handleUserAdd(values);
  };

  const handleUserAdd = values => {
    doAddUser({
      ...values,
      roles: formatRoles(values.services),
      is_main_contact: isMainContact,
      should_notify_user: shouldNotifyUser,
      is_sso_user: isSSOUser,
    });
  };

  const handleUserEdit = values => {
    if (
      user[keys.user.firstName] !== values[fieldNames.firstName] ||
      user[keys.user.lastName] !== values[fieldNames.lastName]
    )
      doUpdateUserName(values);

    if (user[keys.user.email] !== values[fieldNames.email])
      doUpdateUserEmail(values);

    if (
      someRoleHasChanged(values.services, user.services) ||
      newServiceAdded(values.services, user.services)
    )
      doUpdateUserRoles({ roles: formatRoles(values.services) });

    if (user?.[keys.user.isMainContact] !== isMainContact)
      updateMainContact(data);

    if (user?.[keys.user.isSSOUser] !== isSSOUser) updateIsSSOUser(data);
  };

  const someRoleHasChanged = (newServices, oldServices) => {
    const maskOldServices = data => {
      return data.map(service => ({
        ...service,
        id: service[keys.user.serviceId],
        selectedRole: {
          id: service[keys.service.role_id],
        },
      }));
    };

    const newRoles = formatRoles(newServices);
    const oldRoles = formatRoles(maskOldServices(oldServices));
    const newRolesMap = {};
    newRoles.forEach(role => {
      newRolesMap[role.service_id] = role;
    });
    const hasChanged = oldRoles.some(role => {
      const newRole = newRolesMap[role.service_id];
      return newRole?.id !== role.id;
    });

    return hasChanged;
  };

  const newServiceAdded = (newServices, oldServices) => {
    if (
      newServices?.filter(x => x.hasOwnProperty('selectedRole'))?.length >
      oldServices?.length
    )
      return true;
  };

  const formatRoles = servicesSelected => {
    return servicesSelected.map(({ id: service_id, selectedRole: { id } }) => ({
      id,
      service_id,
    }));
  };

  const updateIsSSOUser = data => {
    const clientId = user?.[keys.brandGroup.id] ?? data?.id;
    if (!clientId) {
      showSnackbarError('Error updating SSO state');
      return;
    }

    const url = `admin_brand/${brandId}/client/${clientId}/sso_state`;
    const body = {
      status: isSSOUser,
    };

    doUpdateIsSSOUser(body, url);
  };

  const updateMainContact = data => {
    const clientId = user?.[keys.brandGroup.id] ?? data?.id;
    if (!clientId) {
      showSnackbarError('Error updating main contact');
      return;
    }

    const url = `/admin_brand/${brandId}/client/${clientId}/main_contact`;
    const body = {
      status: isMainContact,
    };

    doUpdateMainContact(body, url);
  };

  const confirmIsMainContact = () => {
    if (!isMainContact) {
      toggleIsMainContact();
      const onConfirm = () => {
        dismiss();
      };
      const onCancel = () => {
        toggleIsMainContact(false);
        dismiss();
      };
      showModal(
        ConfirmModal,
        {
          title: 'Assign main contact',
          description:
            'Are you sure you want to assign this user as the main contact? If there is a previous main contact, they will be overwritten.',
          confirmText: 'Confirm',
          dismiss,
          onCancel,
          onConfirm,
        },
        {
          stack: true,
        }
      );
    } else toggleIsMainContact();
  };

  const confirmIsSSOUser = () => {
    if (!isSSOUser && brand[keys.hasSSOIdP] === 0) {
      showSnackbarError('SSO is not enabled for this brand');
      return;
    }
    const currentSSOState = isSSOUser;
    toggleIsSSOUser();
    const onConfirm = () => {
      dismiss();
    };
    const onCancel = () => {
      toggleIsSSOUser(currentSSOState);
      dismiss();
    };
    showModal(
      ConfirmModal,
      {
        title: currentSSOState ? 'Disable SSO' : 'Enable SSO',
        description: currentSSOState
          ? "Are you sure you want to disable SSO for this user? When SSO is disabled, user can set their own password to use to sign in and they will no longer be forced to use the brand's SSO identity provider to sign in."
          : "Are you sure you want to enable SSO for this user? When SSO is enabled, user can no longer use their password to sign in and they will need to use the brand's SSO identity provider to sign in.",
        confirmText: 'Confirm',
        dismiss,
        onCancel,
        onConfirm,
      },
      {
        stack: true,
      }
    );
  };

  const toggleNotifyUserClick = () => {
    toggleNotifyUser();
  };

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData: user,
        mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };

  const withPrevRoles = services => {
    if (isEditing) {
      services = cloneDeep(services);
      services = services.map(service => {
        const prevService = user?.services?.find(
          ({ [keys.user.serviceId]: id }) => id === service.id
        );

        if (prevService && prevService[keys.service.role_id])
          service.selectedRole = service.roles.find(
            ({ id }) => id === prevService[keys.service.role_id]
          );
        return service;
      });
    }

    return services;
  };

  const enableAll =
    ({ setFieldValue }) =>
    () => {
      setAllEnabled(lastEnabled => {
        const nowEnabled = !lastEnabled;
        if (nowEnabled) {
          selectRoleAll({
            services: servicesCache ?? enabledServices,
            setFieldValue,
          })(allServicesRole);
        }
        if (!nowEnabled)
          setFieldValue('services', servicesCache ?? enabledServices);

        return !lastEnabled;
      });
    };

  const updateServices =
    (values, setFieldValue) => (index, property, value) => () => {
      const services = cloneDeep(values['services']);
      services[index][property] = value;
      setFieldValue('services', services);
      setServicesCache(services);
      updateAllServicesRole(services);
    };

  const allServices = ({ services, setFieldValue }) => {
    return {
      enabled: true,
      enableAll: enableAll({ setFieldValue }),
      roles,
      selectedRole: allServicesRole,
      selectRoleAll: role => () =>
        selectRoleAll({ services, setFieldValue })(role),
    };
  };

  const updateAllServicesRole = services => {
    const allEnabled = services.every(s => s[keys.service.isEnabled]);
    const hasOnlyOneRole = services.every(
      s =>
        s.selectedRole && s.selectedRole?.key === services[0]?.selectedRole?.key
    );

    if (hasOnlyOneRole && allEnabled) {
      setAllServicesRole(services[0].selectedRole);
      setAllEnabled(allEnabled);
    } else {
      setAllServicesRole(null);
    }
  };

  const someRoleSelected = services => {
    return services.some(s => s.hasOwnProperty('selectedRole'));
  };

  const nonFormikDirty = () => {
    if (isEditing) {
      if (!!isMainContact !== !!user[keys.user.isMainContact]) return true;
      if (!!isSSOUser !== !!user[keys.user.isSSOUser]) return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={{
        ...withPrevData(addUserInitialValues),
        services: withPrevRoles(enabledServices),
      }}
      validationSchema={addUserValidationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, values, setFieldValue, dirty }) => {
        return (
          <Form>
            <AddUserLayout
              isEditing={isEditing}
              personalDetailsProps={{
                fields,
                selected: isMainContact,
                toggleSelected: confirmIsMainContact,
                ssoSelected: isSSOUser,
                toggleSSOSelected: confirmIsSSOUser,
              }}
              onNotifyUserToggle={toggleNotifyUserClick}
              shouldNotifyUser={shouldNotifyUser}
              userServicePermissionsProps={{
                allServices: allServices({
                  services: values?.services,
                  setFieldValue,
                }),
                services: values?.services,
                update: updateServices(values, setFieldValue),
                isLoading,
              }}
              actionsProps={{
                disabled:
                  !isValid ||
                  mainContactIsLoading ||
                  isSSOUserIsLoading ||
                  somethingIsLoading ||
                  !someRoleSelected(values?.services) ||
                  !(dirty || nonFormikDirty()),
                primaryText: isEditing ? 'Save' : 'Add',
                isLoading:
                  mainContactIsLoading ||
                  isSSOUserIsLoading ||
                  somethingIsLoading,
                cancelText: 'Cancel',
                onCancel: dismiss,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default Modal(AddUser);
