import { Field_Keys } from '../../../../../constants';

export const format = data => {
  return data.map(activity => formatActivity(activity));
};

const formatActivity = activity => {
  const {
    [Field_Keys.SUPPORT_TICKET.ID]: itemId,
    [Field_Keys.SUPPORT_TICKET.LOG_CONTENT]: message,
    [Field_Keys.SUPPORT_TICKET.BRAND_IMAGE]: image,
    [Field_Keys.SUPPORT_TICKET.BRAND_NAME]: name,
    [Field_Keys.SUPPORT_TICKET.LOG_CREATED_AT]: time,
  } = activity;

  return { image, message, name, itemId, time };
};
