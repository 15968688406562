import { howLongAgo } from '../../../utils/timeUtils';
import { Text } from '../../atoms';
import { AddIcon } from '../../atoms/AddIcon/AddIcon';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import AnimatedIcon from '../../molecules/AnimatedIcon/AnimatedIcon';
import Avatar from '../../molecules/Avatar/Avatar';
import { Input } from '../../molecules/Input/Input';
import { CardLoader } from '../../molecules/LoadingAnimation/LoadingAnimation.exports';
import { NoResults } from '../../molecules/NoResults/NoResults';
import { Colors, FontKeys } from '../../style';
import { AddNoteCSS } from './Notes.style';

export const NotesContainer = ({
  notes,
  addingNote,
  toggleAddingNote,
  notesIsLoading,
  noResults,
  hasEditPermission,
}) => {
  return (
    <Flex column scroll gap="_S" className={'notes-scroll'}>
      {notesIsLoading ? (
        <CardLoader />
      ) : (notes && notes.length) || addingNote ? (
        notes.map(note => {
          return <Note note={note} key={note.content} />;
        })
      ) : (
        <NoResults description={noResults.description} icon={noResults.icon} />
      )}
      {hasEditPermission && (
        <AddIcon
          active={addingNote}
          onClick={toggleAddingNote}
          disabled={notesIsLoading}
        />
      )}
    </Flex>
  );
};

export const Note = ({ note }) => {
  const {
    'notes.userName': userName,
    'notes.userImage': userImage,
    'notes.dateCreated': dateCreated,
    'notes.note': content,
  } = note;
  return (
    <Flex gap="_XS" className={'note'}>
      <Avatar size="_XS" image={userImage} username={userName} />
      <Flex column gap="_2XS" grow w0>
        <NoteHeader userName={userName} date={dateCreated} />
        <Text
          text={content}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Regular}
        />
      </Flex>
    </Flex>
  );
};

const NoteHeader = ({ userName, date }) => {
  return (
    <Flex className="note-header" column>
      <Text
        text={userName}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
      />
      <Text
        text={howLongAgo(date)}
        variant={FontKeys.Variant.Caption}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Regular}
        color={Colors.Neutral._600}
        whiteSpace={'nowrap'}
      />
    </Flex>
  );
};

export const AddNote = ({
  placeholder,
  onChange,
  handleEnterKey,
  handleSend,
  isLoading,
}) => {
  return (
    <AddNoteCSS onKeyDown={handleEnterKey}>
      <Input
        name="note"
        type="textarea"
        variant="textarea"
        placeholder={placeholder}
        onChange={onChange}
        size={FontKeys.Size._M}
        disabled={isLoading}
      />
      <SendNoteIcon disabled={isLoading} onClick={handleSend} />
    </AddNoteCSS>
  );
};

export const SendNoteIcon = ({ disabled, onClick }) => {
  return (
    <AnimatedIcon
      theme="neutral"
      name={Icons.ArrowUpCircle}
      disabled={disabled}
      onClick={onClick}
    />
  );
};
