import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { brandManagementKeys } from '../controllers/variables';

export const useGetBrand = ({ id, ignore }) => {
  const [
    { data = [], isLoading = true, isError, error },
    _,
    getInstruction,
    refresh,
  ] = useFetchData(`admin_brand/${id}`, {
    formatter,
    ignore,
  });

  return { data, isLoading, isError, error, getInstruction, refresh };
};

export const formatter = data => {
  maskEntry(data, mask);

  return data;
};

const mask = {
  [Field_Keys.BRAND.GROUP_NAME]: brandManagementKeys.brandGroup.name,
  [Field_Keys.BRAND.ALIAS]: brandManagementKeys.brand.alias,
  [Field_Keys.BRAND.ID]: brandManagementKeys.brand.id,
  [Field_Keys.BRAND.IMAGE]: brandManagementKeys.brand.image,
  [Field_Keys.BRAND.INDUSTRY]: brandManagementKeys.industry,
  [Field_Keys.BRAND.IS_CONTRACTED]: brandManagementKeys.brand.isContracted,
  [Field_Keys.BRAND.NAME]: brandManagementKeys.brand.name,
  [Field_Keys.BRAND.HAS_SORTERS]: brandManagementKeys.hasSorters,
  [Field_Keys.BRAND.PLAN_END_DATE]: brandManagementKeys.plan.endDate,
  [Field_Keys.BRAND.PLAN_NAME]: brandManagementKeys.plan.name,
  [Field_Keys.BRAND.REP_EMAIL]: brandManagementKeys.representative.email,
  [Field_Keys.BRAND.REP_IMAGE]: brandManagementKeys.representative.image,
  [Field_Keys.BRAND.REP_NAME]: brandManagementKeys.representative.name,
  [Field_Keys.BRAND.HAS_SSO_IDP]: brandManagementKeys.hasSSOIdP,

  // counts
  [Field_Keys.BRAND.CLIENT_COUNT]: brandManagementKeys.user.count,
  [Field_Keys.BRAND.CONTRACT_COUNT]: brandManagementKeys.contract.count,
  [Field_Keys.BRAND.INVOICE_COUNT]: brandManagementKeys.invoice.count,
  [Field_Keys.BRAND.PLAN_COUNT]: brandManagementKeys.plan.count,
  [Field_Keys.BRAND.SERVICE_COUNT]: brandManagementKeys.service.count,
};
