import usePostData from '../../../../../hooks/postHook';

export const useCreateInstruction = () => {
  const [{ data, body, isLoading, isError, error }, doCreateInstruction] =
    usePostData('knowledge_base/create');

  //        const { category_id, title, content } = req.body;

  return { data, body, isLoading, isError, error, doCreateInstruction };
};
