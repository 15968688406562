import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';

export const useGetMessages = ({ ticketId }) => {
  const [{ data = [], isLoading, isError, error }] = useFetchData(
    `office_support_ticket/${ticketId}/messages`,
    {
      formatter: formatMessages,
    }
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const formatMessages = data => {
  if (data == null) return [];
  return data.map(message => formatMessage(message));
};

export const formatMessage = message_ => {
  const {
    [Field_Keys.SUPPORT_TICKET.MESSAGE_CREATING_CLIENT_TYPE]: type,
    [Field_Keys.SUPPORT_TICKET.MESSAGE_CREATING_USER_IMAGE]: image,
    [Field_Keys.SUPPORT_TICKET.MESSAGE_CREATING_USER_DISPLAY_NAME]: name,
    [Field_Keys.SUPPORT_TICKET.MESSAGE]: message,
    [Field_Keys.SUPPORT_TICKET.MESSAGE_CREATED_AT]: date,
    [Field_Keys.SUPPORT_TICKET.MESSAGE_CREATING_USER_IS_ADMIN]: is_office,
  } = message_;
  return {
    ...message_,
    type,
    image,
    message,
    name,
    date,
    is_office,
  };
};
