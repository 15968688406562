import { Field_Keys } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import {
  DateTypes,
  formatDate,
  humanReadableDate,
} from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { reportsKeys } from '../controllers/variables';

export const useGetCustomReports = () => {
  const [{ data = [], isLoading = true, isError, error }, _, refresh] =
    useFetchData('reports', {
      formatter,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data = data.map(report => ({
    ...report,
    [reportsKeys.dateRange]: `${formatDate(
      report[Field_Keys.REPORT.START_DATE],
      DateTypes.ddmmyy
    )} - ${formatDate(report[Field_Keys.REPORT.END_DATE], DateTypes.ddmmyy)}`,
    [reportsKeys.dateRequested]: humanReadableDate(
      report[Field_Keys.REPORT.CREATED_AT]
    ),
  }));
  return data;
};

const mask = {
  [Field_Keys.REPORT.ID]: reportsKeys.id,
  [Field_Keys.REPORT.TITLE]: reportsKeys.name,
  [Field_Keys.REPORT.CREATING_USER_DISPLAY_NAME]: reportsKeys.username,
  [Field_Keys.REPORT.CREATING_USER_IMAGE]: reportsKeys.userimage,
};
