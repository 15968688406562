const { default: usePostData } = require('../../../../../hooks/postHook');

export const useUpdateProductBrand = ({ id }) => {
  const [{ body, isLoading, isError, error }, doUpdateProductBrand] =
    usePostData(`office_product/${id}/brand`, {
      type: 'put',
    });

  return {
    body,
    isLoading,
    isError,
    error,
    doUpdateProductBrand,
  };
};
