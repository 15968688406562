import { Navigate } from 'react-router-dom';
import { Icons } from '../../../components/atoms/Icon/Icon.options';
import { UserSettingsRoutes } from '../../../features/user_settings';
import {
  PermissionConsts,
  PermissionKeys,
} from '../../../providers/PermissionProvider/PermissionsProvider';
import { userSettingsPath } from '../../../routes/variables';
import { children as adminChildren } from '../../admin/routes/protected';
import { children as facilitatorChildren } from '../../facilitator/routes/protected';
import { AnalyticsRoutes } from '../features/analytics';
import { AuthenticationRoutes } from '../features/authentication';
import { BrandSettingsRoutes } from '../features/brand_settings/controllers';
import { DashboardRoutes } from '../features/dashboard';
import {
  KnowledgeBaseDetailsRoutes,
  KnowledgeBaseRoutes,
} from '../features/knowledge_base/controllers';
import { NoServicesRoutes } from '../features/no_services';
import { CasesRoutes } from '../features/parcels/controllers';
import { ProductDetailsRoutes, ProductsRoutes } from '../features/products';
import {
  ReferenceIndexDetailsRoutes,
  ReferenceIndexRoutes,
} from '../features/reference_index';
import { ReportsRoutes } from '../features/reports';
import { SupportDetailRoutes, SupportRoutes } from '../features/support';
import { WhiteLabelRoutes } from '../features/white_label';
import { ProtectedLayout } from './ProtectedLayout';
import { Labels, Pages, URIs } from './variables';

const {
  ADR,
  BRAND_MANAGEMENT,
  DASHBOARD,
  KNOWLEDGE_BASE,
  LQ,
  PRODUCTS,
  PARCELS,
  OFFICE_SORTER_DATA,
  SORTER_INSIGHTS,
  SUPPORT,
  REFERENCES,
  REPORTS,
  WHITE_LABEL,
} = PermissionKeys.Brand;

const children = [
  {
    path: `${URIs[Pages.analytics]}/*`,
    label: Labels[Pages.analytics],
    sidebarIcon: Icons.Analytics,
    service: [SORTER_INSIGHTS.SELF, ADR.SELF, LQ.SELF],
    element: <AnalyticsRoutes />,
  },
  {
    path: `${URIs[Pages.dashboard]}/*`,
    label: Labels[Pages.dashboard],
    sidebarIcon: Icons.Dashboard,
    service: DASHBOARD.SELF,
    element: <DashboardRoutes />,
  },
  {
    path: `${URIs[Pages.authentication]}/*`,
    label: Labels[Pages.authentication],
    sidebarIcon: Icons.ShieldCheck,
    service: PRODUCTS.SELF,
    permission: PRODUCTS.EDIT_PRODUCT_STATUS,
    element: <AuthenticationRoutes />,
    skipScale: true,
  },
  {
    path: `${URIs[Pages.parcels]}/*`,
    label: Labels[Pages.parcels],
    sidebarIcon: Icons.Parcels,
    service: PARCELS.SELF,
    element: <CasesRoutes />,
  },
  {
    path: `${URIs[Pages.products]}/*`,
    label: Labels[Pages.products],
    sidebarIcon: Icons.Products,
    service: PRODUCTS.SELF,
    element: <ProductsRoutes />,
  },
  {
    path: `${URIs[Pages.products]}/:productId/*`,
    sidebarIcon: null,
    service: PRODUCTS.SELF,
    permission: PRODUCTS.VIEW_PRODUCTS,
    element: <ProductDetailsRoutes />,
  },
  {
    path: `${URIs[Pages.knowledge_base]}/*`,
    label: Labels[Pages.knowledge_base],
    sidebarIcon: Icons.KnowledgeBase,
    service: KNOWLEDGE_BASE.SELF,
    element: <KnowledgeBaseRoutes />,
  },
  {
    path: `${URIs[Pages.knowledge_base]}/:instructionId/*`,
    sidebarIcon: null,
    service: KNOWLEDGE_BASE.SELF,
    permission: KNOWLEDGE_BASE.VIEW_KNOWLEDGE_BASE,
    element: <KnowledgeBaseDetailsRoutes />,
  },
  {
    path: `${URIs[Pages.support]}/*`,
    label: Labels[Pages.support],
    sidebarIcon: Icons.Support,
    service: SUPPORT.SELF,
    element: <SupportRoutes />,
  },
  {
    path: `${URIs[Pages.support]}/:ticketId/*`,
    sidebarIcon: null,
    service: SUPPORT.SELF,
    permission: [
      SUPPORT.VIEW_GENERAL_SUPPORT_TICKETS,
      SUPPORT.VIEW_OWN_GENERAL_SUPPORT_TICKETS,
      SUPPORT.VIEW_PARCEL_SUPPORT_TICKETS,
      SUPPORT.VIEW_OWN_PARCEL_SUPPORT_TICKETS,
      SUPPORT.VIEW_PRODUCT_SUPPORT_TICKETS,
      SUPPORT.VIEW_OWN_PRODUCT_SUPPORT_TICKETS,
    ],
    element: <SupportDetailRoutes />,
  },
  {
    path: `${URIs[Pages.reference_index]}/*`,
    label: Labels[Pages.reference_index],
    sidebarIcon: Icons.ReferenceIndex,
    service: REFERENCES.SELF,
    element: <ReferenceIndexRoutes />,
  },
  {
    path: `${URIs[Pages.reference_index]}/:referenceId/*`,
    sidebarIcon: null,
    service: REFERENCES.SELF,
    permission: REFERENCES.VIEW_REFERENCES,
    element: <ReferenceIndexDetailsRoutes />,
  },
  {
    path: `${URIs[Pages.white_label]}/*`,
    label: Labels[Pages.white_label],
    sidebarIcon: Icons.WhiteLabel,
    service: WHITE_LABEL.SELF,
    element: <WhiteLabelRoutes />,
  },
  {
    path: `${URIs[Pages.reports]}/*`,
    label: Labels[Pages.reports],
    sidebarIcon: Icons.Reports,
    service: REPORTS.SELF,
    element: <ReportsRoutes />,
  },
  {
    path: `${URIs[Pages.brand_settings]}/*`,
    sidebarIcon: null,
    service: BRAND_MANAGEMENT.SELF,
    element: <BrandSettingsRoutes />,
  },
  {
    path: userSettingsPath,
    sidebarIcon: null,
    service: PermissionConsts.allow,
    element: <UserSettingsRoutes />,
  },
];

const removeFinalAsterisk = str => {
  if (str.endsWith('/*')) return str.slice(0, -2);
  return str;
};

const getFallbacks = children => {
  const fallbacks = children
    .filter(({ sidebarIcon }) => sidebarIcon != null) // exclude detail routes and non-sidebar routes
    .map(({ path, sidebarIcon, service, permission, element }) => ({
      path: '*',
      sidebarIcon: null,
      service,
      permission,
      element: <Navigate to={removeFinalAsterisk('/' + path)} replace />,
    }));

  return fallbacks;
};

const withFallbackRoutes = children => {
  const fallbacks = getFallbacks(children);

  return [...children, ...fallbacks];
};

const getPossiblePermissions = children => {
  const services = children
    .map(({ service }) => service)
    .filter(service => service !== PermissionConsts.allow);
  return services;
};

const allChildren = [...children, ...facilitatorChildren, ...adminChildren];

export const protectedRoutes = {
  layout: {
    path: '/*',
    element: <ProtectedLayout />,
  },
  children: withFallbackRoutes([
    ...allChildren,
    {
      path: `${URIs[Pages.no_services]}/*`,
      sidebarIcon: '', // exclude from sidebar but include on fallback
      service: PermissionConsts.allow,
      element: <NoServicesRoutes />,
    },
  ]),
};

export const possiblePermissions = getPossiblePermissions(
  getFallbacks(allChildren)
);
