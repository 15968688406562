import { ReactComponent as EmptyNotesIcon } from '../../../../../assets/svg/no_results/brand/notes.svg';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { smallProductsListHeaders } from '../../../features_public/products/controllers/variables';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';

export const headers = {
  [referenceIndexKeys.leftPadding]: '',
  [referenceIndexKeys.name]: 'Name',
  [referenceIndexKeys.alias]: 'Reference ID',
  [referenceIndexKeys.brand.self]: 'Brand',
  [referenceIndexKeys.company]: 'Company',
  [referenceIndexKeys.country]: 'Country',
  [referenceIndexKeys.casesSeized]: 'Seizures',
  [referenceIndexKeys.dateCreated]: 'Date created',
  [referenceIndexKeys.addedBy]: 'Added By',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  COUNTRY: 'COUNTRY',
  ADDED_BY: 'ADDED_BY',
  ONLY_ACTIVE: 'ONLY_ACTIVE',
  BRANDS: 'BRANDS',
  TYPE: 'TYPE',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
  },
  [DC.COUNTRY]: {
    type: FilterTypes.dropdown,
    icon: Icons.MapPin,
    param: 'country',
  },
  [DC.BRANDS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Archive,
    param: 'ownBrandId',
  },
  [DC.ADDED_BY]: {
    type: FilterTypes.dropdown,
    icon: Icons.User,
  },
  [DC.ONLY_ACTIVE]: {
    type: FilterTypes.toggle,
    title: 'Only active',
    icon: Icons.Timer,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const initialFilterValues = {
  [DC.BRANDS]: '',
  [DC.COUNTRY]: '',
  [DC.ADDED_BY]: '',
  [DC.ONLY_ACTIVE]: '',
};

export const titles = {
  [DC.BRANDS]: 'Brand',
  [DC.COUNTRY]: 'Country',
  [DC.ADDED_BY]: 'Added by',
  [DC.ONLY_ACTIVE]: 'Show only active',
};

export const allOptions = {
  [DC.BRANDS]: { label: 'All brands', value: Constants.all },
  [DC.COUNTRY]: { label: 'All', value: Constants.all },
  [DC.ADDED_BY]: { label: 'All', value: Constants.all },
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
    // paperweight //
    16px
    // name //
    minmax(102px, 1fr)
    // alias //
    minmax(166px, 0.75fr)
    // brand //
    minmax(102px, 1fr)
    // company //
    minmax(102px, 1fr)
    // country //
    minmax(102px, 1fr)
    //  seizures  //
    minmax(72px, 0fr)
    //  date  //
    96px
    // addedBy  //
    minmax(102px, 1fr)`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const getAddReferenceButton = onClick => {
  return {
    onClick,
    text: 'Add reference data',
    variant: 'Primary',
    size: '_S',
  };
};

export const components = {
  referenceSummary: 'referenceSummary',
  products: 'products',
};

export const componentTitle = {
  [components.referenceSummary]: 'Reference Overview',
  [components.products]: 'Products',
};

export const infoDescription = {
  [components.referenceSummary]:
    'This section shows all the most important data related to this reference.',
  [components.products]:
    'This section shows all the products related to this reference.',
};

export const referenceInfoInitValuesKeys = [
  referenceIndexKeys.name,
  referenceIndexKeys.company,
  referenceIndexKeys.address,
  referenceIndexKeys.phone,
  referenceIndexKeys.zipcode,
  referenceIndexKeys.city,
  referenceIndexKeys.state,
  referenceIndexKeys.country,
];

export const referenceCasesHeaders = {
  ...smallProductsListHeaders,
};

export const referenceCasesGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // product image //
  32px
  // product alias //
  auto
  // product status //
  124px
  // hover icon //
  minmax(0px, min-content)
  `),
  gridAutoRows: '48px 0px',
  gridTemplateRows: 'unset',
};

export const referenceNotesConstants = {
  charLimit: 450,
  placeholder: 'Write a note for this reference',
  noResults: {
    description: 'You can use this space to add\nnotes about the reference',
    icon: <EmptyNotesIcon />,
  },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.ReferenceIndex,
    title: 'No references',
    description: 'To start catching parcels, add some reference data',
    button: {
      text: 'Add reference data',
      variant: 'Primary',
      size: '_M',
      onClick,
    },
  }),
  noSearchResults: {
    icon: NoResults.ReferenceIndex,
    title: 'No results found',
    description: 'Please try another search or view all references',
  },
};

export const referenceCasesNoResultsState = {
  noData: {
    icon: NoData.ReferenceCases,
    subDescription: `The products related to this reference 
    will be shown here.`,
  },
};

export const referenceScrollLimit = 25;
