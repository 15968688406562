import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { formatOverview } from '../formatters/formatOverview';
import { parcelStatuses } from '../variables';

export const useGetOverview = ({
  searchParams,
  hasReferencePermission,
  hasProductPermission,
}) => {
  const [
    {
      data = {
        parcelStatuses: parcelStatuses.map(status => ({ status })),
        overviewStats: [],
        authenticationStats: [],
      },
      isLoading,
      isError,
      error,
    },
    _,
    __,
    refresh,
  ] = useFetchData('dashboard/product_count', {
    formatter: formatOverview({ hasReferencePermission, hasProductPermission }),
    failureFormatter,
    params: formatParams(searchParams),
  });

  return { data, isLoading, isError, error, refresh };
};

const failureFormatter = () => ({
  parcelStatuses: parcelStatuses.map(status => ({ status })),
  overviewStats: [],
  authenticationStats: [],
});

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
